import React from 'react'
import Img1 from '../../../../Assets/CardImages/7iBuzz/Discovery.png'
import Img2 from '../../../../Assets/CardImages/7iBuzz/POC.png'
import Img3 from '../../../../Assets/CardImages/7iBuzz/Implementation.png'
import Img4 from '../../../../Assets/CardImages/7iBuzz/Design.png'
import { BsArrowRight } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
const cardData = [
    {
        img: Img1,
        title: "Discovery",
        description: ""
    },
    {
        img: Img4,
        title: "Design",
        description: ""
    }, {
        img: Img2,
        title: "POC",
        description: ""
    }, {
        img: Img3,
        title: "Implementation",
        description: ""
    },
]
export const Outlet = () => {
    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate('/contact')
        window.scroll(0, 0)
    }
    return (
        <div className='mb-2 shadow-md '>
            <div className='w-10/12 sm:w-11/12 m-auto py-10 flex flex-col gap-4'>
                <div className='flex justify-between sm:flex-wrap border-b-2 border-solid border-gray-300 pb-4 sm:gap-y-3'>
                    {
                        cardData?.map((el, i) => (
                            <div key={i} className=' flex flex-col gap-4 items-center sm:w-1/2  sm:items-center '>
                                <img src={el.img} alt={el.title} className='w-12' loading='lazy' />
                                <p className='text-xl font-semibold '>{el.title}</p>
                            </div>
                        ))
                    }
                </div>

                <p className='text-center w-[66%] tab:w-10/12 m-auto sm:w-full sm:text-left'>
                    We delve deeply into your data, obstacles, and IT strengths to identify fresh business prospects, pinpoint specific applications, and chart the course for AI software development
                </p>

                <button className='flex w-40 justify-center items-center gap-2 py-2 font-semibold rounded-3xl bg-[#FFC900] mt-7' onClick={handleNavigate}> Contact Us <span> <BsArrowRight /></span></button>
            </div>
        </div>

    )
}
