import React, { useRef, useState } from 'react'

import styles from './styles.module.css'

export const BurgerIcon = ({ toggle, setToggle, checked, setChecked }) => {

    const check = useRef()

    const handleChange = () => {
        setToggle((prevState) => !prevState)
        setChecked(!toggle)
    }
    useState(() => {
        // setChecked(toggle)
    }, [toggle])

    let style = {
        width: "26px",
        margin: "5px 0",
        padding: "2px 0"
    }
    return (
        <div className='hidden lg:block tab:block sm:block'>
            <div className='bg-black sm:bg-black' style={style}></div>
            <div className='bg-black sm:bg-black' style={style}></div>
            <div className='bg-black sm:bg-black' style={style}></div>
        </div>
    )
}
