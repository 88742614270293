import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";

import Card1 from "../../../../Assets/CardImages/7iBuzz/Create-Immersive-User-Experiences.svg.svg";
import Card2 from "../../../../Assets/CardImages/7iBuzz/Web-Mobile-App-Development.svg.svg";
import Card3 from "../../../../Assets/CardImages/7iBuzz/Design-Smart-Applications.svg.svg";
import Card4 from "../../../../Assets/CardImages/7iBuzz/Application-Modernization.svg.svg";
import Card5 from "../../../../Assets/CardImages/7iBuzz/Augment-Cross-functional-Teams.svg.svg";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";

const array = [
  {
    img: Card1,
    title: "Create Immersive User Experiences",
  },
  {
    img: Card2,
    title: "Web & Mobile App Development",
  },
  {
    img: Card3,
    title: "Design Smart Applications",
  },
  {
    img: Card4,
    title: "Application Modernization",
  },
  {
    img: Card5,
    title: "Augment Cross-functional Teams",
  },
];

const ProductDev = () => {
  return (
    <div
      className="mb-2 shadow-md"
      style={{
        background:
          "radial-gradient(at 90% 50%, rgba(255,246,214,0.3), rgba(255,255,255) 80%, rgba(255,255,255) 40%)",
      }}>
      <div className="w-10/12 m-auto tab:w-11/12 sm:w-11/12 py-6">
        <div className="w-64 m-auto my-6 border-[3px] border-yellow-400 border-solid sm:w-40 sm:border-2 sm:my-2"></div>
        <div className="w-full m-auto flex flex-col gap-8 text-center tab:w-10/12 sm:w-full sm:gap-2 sm:mt-6 sm:text-left">
          <div className="w-7/12 m-auto flex flex-col gap-8 mt-4 text-center tab:w-10/12 sm:w-full  sm:mt-2 sm:text-left">
            <h3 className="text-4xl text-center font-bold flex flex-col gap-3 tab:text-3xl sm:text-xl">
              Product Development Services
            </h3>
            <p className="sm:text-sm text-gray-800">
              Fuelled by design, driven by data – We deliver addictive
              applications that provide a phenomenal customer experience with
              our industry- leading software product development solutions
            </p>
            <div className="flex justify-center items-center sm:mt-2">
              <Link
                to="/contact"
                className=" text-base sm:text-sm py-2 px-3 sm:py-1 sm:px-2 flex gap-2 items-center justify-center border-[#FFC900] border rounded-full">
                Get in Touch <MdKeyboardArrowRight />
              </Link>
            </div>
          </div>

          <h3 className="text-2xl font-bold flex flex-col gap-3 tab:text-lg sm:text-base sm:mt-5">
            We Help you Deliver Successful Software Products By Combining
            Experiential Design, Data, and Cutting-edge Technologies with our
            Product Development Services
          </h3>
        </div>
        <div className="flex flex-wrap justify-between gap-6 mt-10 sm:gap-0 sm:justify-evenly sm:mt-5">
          {array?.map((ele, i) => {
            return <Card key={i} {...ele} />;
          })}
          <div className="w-3/12 text-lg sm:text-base flex justify-center items-center flex-col gap-2 sm:w-5/12 sm:text-center">
            <p>Let's build Your Product</p>
            <a
              href="https://calendly.com/gbjbuzz"
              className=" text-base sm:text-sm py-2 px-3 sm:py-1 sm:px-2 flex gap-2 items-center justify-center border-[#FFC900] border rounded-full">
              Talk To us <MdKeyboardArrowRight />
            </a>
          </div>
        </div>
        <div className="w-11/12 m-auto text-center tab:w-8/12 sm:w-full mt-6 sm:mt-4">
          <p className="text-base text-gray-800 sm:text-sm">
            Engaging in product development presents the chance to create novel
            solutions and leave a lasting imprint on the market. Our exceptional
            engineering squad adheres to a rigorous product design protocol,
            crafting captivating software product experiences. Infused within
            our very DNA is an agile development approach, which underpins all
            our development endeavours. At the heart of our development
            initiatives, we prioritise the customer's requirements, adhering to
            a product development process that places them front and centre.
          </p>
        </div>
        <div className="flex justify-center mt-6 sm:mt-4">
          <Link
            to={"/contact"}
            className="flex items-center gap-1 rounded-3xl px-4 py-3 sm:px-3 sm:py-2 text-lg sm:text-sm font-semibold bg-[#FFC900]">
            Collaborate with us <AiOutlineArrowRight />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductDev;

const Card = ({ img, title }) => {
  return (
    <div className="w-3/12 h-40 flex justify-center items-center flex-col bg-[#E9F3F3] py-6 px-10 sm:my-2 sm:w-5/12 ">
      <img src={img} alt={title} loading="lazy" />
      <p className="text-[15px] text-center font-semibold text-[#0A203E] sm:text-sm">
        {title}
      </p>
    </div>
  );
};
