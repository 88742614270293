import React, { useRef } from "react";
import { BsArrowUpRight } from "react-icons/bs";
import linebg2 from "../../../Assets/linedbg2.png";
import { Link } from "react-router-dom";

export const LatestInsights = () => {
  const element = useRef();
  return (
    <div style={{
      background:
        "radial-gradient(at 90% 26%, rgba(255,246,214) 30%, rgba(251,251,251) 56%, rgba(251,251,251) 60%)",
    }}>
      <div className="w-9/12 m-auto tab:w-10/12 sm:w-11/12 pt-12 pb-8 sm:pt-8 sm:pb-4"
        data-aos='fade-up'
        data-aos-duration='500'
        data-aos-offset='100'
      >
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-3 sm:gap-1">
            <p className="text-6xl font-bold tab:text-5xl sm:text-4xl">
              <span className="text-gray-500">Latest </span>
              Insights of Tech
            </p>
            <p className="text-6xl font-bold tab:text-5xl sm:text-4xl">
              Smart <span className="text-gray-500">Blogs</span>
            </p>
            <p className="">
              Learn about business innovation from our renowned bloggers. We're
              here to keep you informed and give you the most recent information
              about app and game development. Visit our blogs to learn about the
              newest innovations and fashions before anybody else.
            </p>
            {/* button component */}

            <div
              className=" flex shrink-0 justify-start  relative mt-2 h-[50px] w-[390px] sm:w-52 sm:h-9 bg-[#FFC900] rounded-lg  shadow-2xl overflow-hidden"
              onMouseEnter={() => {
                element.current.classList.add("-translate-x-0");
                element.current.classList.remove("-translate-x-full");
              }}
              onMouseLeave={() => {
                element.current.classList.remove("-translate-x-0");
                element.current.classList.add("-translate-x-full");
              }}>
              <span
                ref={element}
                className={` absolute w-[27rem] h-[7rem] -top-2 -translate-x-full  transition-all duration-300 ease-in`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#fff",
                  // height: "70px"
                }}>
                <img src={linebg2} alt="backgroundLine" />
              </span>

              <Link
                to={"/contact"}
                className={` flex justify-around items-center  sm:text-sm rounded-xl font-semibold w-full h-full  text-2xl  sm:px-4 sm:py-1  py-3  px-5 shadow-custom2 bg-transparent z-10 `}>
                Contact our Consultant <BsArrowUpRight />
              </Link>
            </div>
            {/* button component ended */}
          </div>
          <div className="hidden w-full justify-between items-center tab:flex-col tab:justify-start tab:items-start tab:gap-6 sm:flex-col sm:gap-6">
            <form method="GET" className="sm:w-full">
              <div className="w-full flex items-center justify-between pl-2 text-gray-500 bg-gray-200 rounded-3xl focus-within:text-gray-500 border border-gray-700 border-solid">

                <input
                  type="search"
                  name="q"
                  className="w-96 sm:w-56 py-2 pl-2 sm:py-1 text-base text-black bg-gray-200 rounded-3xl focus:outline-none focus:bg-gray-200 focus:text-gray-900 "
                  placeholder="Search..."
                  autoComplete="off"
                />
                <button className="w-24 h-10 flex justify-around items-center px-2 text-black text-base font-medium rounded-3xl bg-[#FFC900] relative sm:px-4">
                  <div className="flex absolute gap-2 items-center">
                    <p>Search</p>
                  </div>
                </button>
              </div>
            </form>

            <div className="flex items-center gap-4 ">
              <p className="font-semibold">Categories </p>
              <select
                name=""
                id=""
                className="w-72 h-10 sm:h-8 font-medium px-4 rounded-3xl border border-gray-700 border-solid">
                <option value="">Select Categories</option>
                <option value="figma">Figma Developer</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
