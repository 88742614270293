import React, { useRef } from 'react'
import LeadImg from '../../../Assets/SVG_Images/LandingPage/lead 1.svg';
import AmplifyImg from '../../../Assets/SVG_Images/LandingPage/amplify 1.svg';
import RevolutionImg from '../../../Assets/SVG_Images/LandingPage/revolutionize.svg';
import InnovationImg from '../../../Assets/SVG_Images/LandingPage/innovation.svg'
import RevolutionMobileImg from '../../../Assets/SVG_Images/LandingPage/revolutionizeMobile.svg'
import InnovationMobileImg from '../../../Assets/SVG_Images/LandingPage/innovationMobile.svg'
import { PiCheckBold } from "react-icons/pi";
import { GoDotFill } from "react-icons/go";

const missionCards = [
    {
        id: 1,
        title: 'Lead',
        // title: "Nuture local talent",
        desc: `We lead the market, driving your client's business forward.
        Craft and deliver innovative digital experiences.
        Redefine business models for sustained growth.`,
        image: LeadImg,
        smallDImg: LeadImg
    }
    , {
        id: 2,
        title: 'Amplify',
        // title: "Develop state-of-the-art technology and infrastructure",
        desc: `Optimize for maximum efficiency in business operations.
        Improve marketing results with strategic insights.
        Elevate your business impact with actionable insights.`,
        image: AmplifyImg,
        smallDImg: AmplifyImg

    }
    , {
        id: 3,
        title: 'Revolutionize',
        // title: "Support ease of business",
        desc: `Leverage AI for enhanced performance.
        Harness the power of automation for streamlined operations.
        Transform applications for greater agility.`,
        image: RevolutionImg,
        smallDImg: RevolutionMobileImg

    }
    , {
        id: 4,
        title: 'Innovate',
        // title: "Focus on content creation",
        desc: `Adapt to the ever-evolving technology landscape.
        Innovate software engineering practices.
        Drive efficiency to new heights through continuous changes.`,
        image: InnovationImg,
        smallDImg: InnovationMobileImg

    }
]

const Mission = () => {

    return (
        <div className='bg-[#f7f7f7]'>
            <div className='max-w-[1600px] m-auto py-8'>
                <div className='w-11/12 m-auto '>
                    <h2 data-aos-duration="1000" className='text-[45px] sm:text-[22px] font-semibold text-center'>Grow Your Business At Junction</h2>
                    <div className=' grid grid-cols-4 tab:grid-cols-2 sm:grid-cols-2 gap-8 lg:gap-6 sm:gap-4 pt-10 sm:pt-8 '>
                        {
                            missionCards?.map((el, i) => <Card key={i} el={el} i={i} />
                            )
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Mission


const Card = ({ el, i }) => {
    const upperDivEle = useRef();
    const lowerDivEle = useRef();
    const imageDiv = useRef()
    const handleEnterEvent = () => {
        if (window.innerWidth <= 600) {
            upperDivEle.current.classList.add("-translate-y-[3.3rem]")
            lowerDivEle.current.classList.add("!-translate-y-12")
            imageDiv.current.classList.add('hidden')

        } else if (window.innerWidth > 600) {
            upperDivEle.current.classList.add("-translate-y-[4.7rem]")
            lowerDivEle.current.classList.add("!-translate-y-16")
            imageDiv.current.classList.add('hidden')
        }

    }

    const handleLeaveEvent = () => {
        if (window.innerWidth <= 600) {
            upperDivEle.current.classList.remove("-translate-y-[3.3rem]")
            lowerDivEle.current.classList.remove("!-translate-y-12")
            imageDiv.current.classList.remove('hidden')

        } else if (window.innerWidth > 600) {
            upperDivEle.current.classList.remove("-translate-y-[4.7rem]")
            lowerDivEle.current.classList.remove("!-translate-y-16")
            imageDiv.current.classList.remove('hidden')

        }
    }
    return (

        <div
            onMouseEnter={handleEnterEvent}
            onMouseLeave={handleLeaveEvent}
            key={i}
            data-aos="zoom-in-down" data-aos-duration="1000" data-aos-delay={i * 300}
            className={`${el.id % 2 === 1 ? "bg-[#FFC900]" : "bg-white"}
            transition-all duration-1000 ease-linear overflow-hidden h-[22rem] xl:h-[19rem] lg:h-80 tab:h-[21rem] sm:h-48 p-8 lg:p-8 tab:p-6 sm:p-4 shadow-md  rounded-2xl
             sm:${el.id === 1 || el.id === 4 ? "bg-[#FFC900]" : "bg-white"}
             ${el.id === 1 || el.id === 4 ? "tab:bg-[#FFC900]" : "tab:bg-white"}`}>
            <div ref={upperDivEle} className='relative transition-all duration-300 ease-linear '>
                <p className='text-5xl sm:text-4xl font-bold'>0{i + 1}</p>
                <div ref={imageDiv} className='h-40 xl:h-36 lg:h-32 tab:h-36 sm:h-20 w-10/12 m-auto'>
                    <img src={el.image} alt={el.title} className='sm:hidden tab:hidden h-full m-auto' />
                    <img src={el.smallDImg} alt={el.title} className='hidden sm:block tab:block h-full m-auto' />
                </div>
                <h2 className='text-2xl text-center font-semibold sm:text-lg sm:leading-5 mt-8 lg:mt-4 tab:mt-5 sm:mt-3'>{el.title}</h2>

            </div>
            <div ref={lowerDivEle} className='absoulte transition-all duration-300 ease-linear translate-y-20  text-[#3E3E3E] text-[15px] lg:text-sm tab:text-sm sm:text-xxs sm:!leading-[12px] top-0'>

                <div>
                    {
                        el.desc.toString().split('\n').map((ele, i) => (
                            <div key={i} className='flex items-center gap-x-2 mt-2'>
                                <div className='w-6 sm:w-4 text-xl sm:text-[14px]'>
                                    {
                                        el.id % 2 === 1 ? <PiCheckBold /> : <GoDotFill />
                                    }
                                </div>
                                <p className='w-[93%]'>{ele}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}