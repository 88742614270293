import React from "react";
import ClockImg from "../../../Assets/SVG_Images/Career/flexible.svg";
import InclusiveImg from "../../../Assets/SVG_Images/Career/inclusive.svg";
import ConnectedImg from "../../../Assets/SVG_Images/Career/Connected.svg";
import style from '../../LandingPage/animationStyles.module.css'
const futureWorkData = [
  {
    img: ConnectedImg,
    imgAnimation:'animate-[spin_5s_linear_infinite]',
    title: "Connected",
    description:
      "We come together wherever we are – across time zones, regions, offices andscreens.",
  },
  {
    img: InclusiveImg,
    title: "Inclusive",
    imgAnimation: style.animationUp ,
    description:
      "Our teams reflect the rich diversity of our world, with equitable access to opportunity for everyone.",
  },
  {
    img: ClockImg,
    title: "Flexible",
    imgAnimation:'animate-[spin_5s_linear_infinite]',
    description:
      "Our teams reflect the rich diversity of our world, with equitable access to opportunity for everyone.",
  },
];
export const FutureWork = () => {
  return (
    <div className="w-9/12 m-auto py-14 flex flex-col gap-4 text-center tab:w-10/12 sm:w-11/12 sm:py-10 sm:text-left">
      <p className="text-4xl font-bold sm:text-2xl">
        GBJ BUZZ is where the future works
      </p>
      <p className="w-11/12 m-auto sm:w-full sm:text-justify">
        Every day, we refine, iterate and explore how to make work better for
        everyone. Join us in creating a better future of work that’s more
        connected, inclusive and flexible:
      </p>
      <div className="flex justify-between gap-12 text-left sm:flex-col sm:gap-6">
        {futureWorkData?.map((el, i) => (
          <div key={i}>
            <img
              className={`${el.imgAnimation} relative w-28 h-28 sm:w-24 sm:h-24`}
              src={el.img}
              alt={el.title}
            />
            <p className="text-lg font-bold">{el.title}</p>
            <p>{el.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
