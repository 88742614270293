import React from "react";
import HowToTalk from "../../../Assets/Images/BlogPage/HowToTalkYourCustomers.png";
import { Link } from "react-router-dom";

export const ExploreSupport = () => {
  return (
    <div className="w-9/12 tab:w-10/12 my-10 flex justify-between m-auto bg-purple-50 sm:flex-col sm:w-11/12 sm:my-6">
      <div className=" p-12 tab:p-9 flex flex-col gap-4 sm:p-8">
        <p className="text-2xl font-bold tab:text-[22px]">Explore the Support Toolkit</p>
        <p>
          Grow your skills and teams with our collection of free resources,
          guides, and courses.
        </p>
        <Link to="/services/libex" className="w-fit py-2 bg-[#FFC900] px-6">
          Check it out
        </Link>
      </div>
      <div>
        <img className="" src={HowToTalk} alt="bookimage" />
      </div>
    </div>
  );
};
