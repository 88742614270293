import React from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import Aos from "aos";
import 'aos/dist/aos.css';

import gbjBuzzSupportImg from "../../../Assets/SVG_Images/ContactUsPage/gbj-buzz-support.svg";
import gbjBuzzAppointmentImg from "../../../Assets/SVG_Images/ContactUsPage/gbj-buzz-appointment.svg";
import gbjBuzzContactImg from "../../../Assets/SVG_Images/ContactUsPage/gbj-buzz-contactus.svg";
import FaqImg from "../../../Assets/SVG_Images/ContactUsPage/gbj-buzz-faq.svg";
import gbjBuzzMeetingImg from "../../../Assets/SVG_Images/ContactUsPage/gbj-buzz-meeting.svg";

const arrayLinks = [
  {
    img: gbjBuzzSupportImg,
    title: "Get Support",
    link: "#QuoteForm",
    alt:"gbj-buzz-support"
  },
  {
    img: gbjBuzzAppointmentImg,
    title: "Appointment",
    link: "https://calendly.com/gbjbuzz",
    alt:"gbj-buzz-appointment"
  },
  {
    img: gbjBuzzContactImg,
    title: "Contacts",
    link: "#QuoteForm",
    alt:"gbj-buzz-contact"
  },
  {
    img: gbjBuzzMeetingImg,
    title: "Meet the Team",
    link: "#QuoteForm",
    alt:"gbj-buzz-meeting"
  },
  {
    img: FaqImg,
    title: "FAQ",
    link: "#formDiv",
    alt:"gbj-buzz-faq"
  },
];

Aos.init()

const Component = () => {
  return (
    <>
      <div
        className="mb-2 shadow-md py-14"
        style={{
          background:
            "radial-gradient(at 90% 50%, rgb(255, 246, 214) 20%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 50%)",
        }}>
        <div className="text-6xl font-bold text-center text-yellow-400 sm:text-2xl uppercase tab:text-5xl"
          data-aos='fade-up'
          data-aos-duration='600'
          data-aos-offset='300'

        >
          <p>
            Together  <span className="text-black"> we can </span>
          </p>
          <p>do what none of us</p>
          <p>
            could <span className="text-black"> do </span>alone
          </p>
        </div>

        <div className="flex flex-row flex-wrap w-8/12 justify-evenly m-auto sm:w-10/12 items-center lg:w-6/12">
          {arrayLinks?.map((item, i) => {
            return (
              <div
                key={i}
                className="w-1/3 flex justify-between flex-col items-center sm:my-5 my-3 sm:w-6/12 lg:w-6/12 tab:w-6/12">
                <div className="">
                  <img src={item.img} alt={item.alt} className="w-60 h-60 my-2 sm:h-40 sm:my-0" loading='lazy' />
                </div>
                {
                  item.link.startsWith('https') ?
                    <a
                      href={item.link}
                      target="_main"
                      className="w-52 rounded-3xl border flex flex-row justify-center gap-2 py-2 px-5 shadow-xl items-center font-medium bg-white sm:w-11/12 sm:text-xs sm:px-2">
                      {item.title} <HiOutlineArrowNarrowRight />
                    </a> :
                    <a
                      href={item.link}
                      className="w-52 rounded-3xl border flex flex-row justify-center gap-2 py-2 px-5 shadow-xl items-center font-medium bg-white sm:w-11/12 sm:text-xs sm:px-2">
                      {item.title} <HiOutlineArrowNarrowRight />
                    </a>
                }
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Component;
