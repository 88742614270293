import React from "react";
import { MdOutlineLocalPhone } from "react-icons/md";
import { GrMail } from "react-icons/gr";
import { FaLocationDot } from "react-icons/fa6";
import { FaFacebookF, FaPinterestP } from "react-icons/fa";
import { BiLogoLinkedin, BiLogoMedium } from "react-icons/bi";
import { FaXTwitter, FaLinkedin } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";
import { BsYoutube } from "react-icons/bs";
import { SiMedium } from "react-icons/si";
import { GbjLogo } from "../GbjLogo/GbjLogo";
import { Link } from "react-router-dom";
import LoveBharatIcon from "../../Assets/Love India Icon 1.svg";

const servicesLinkList = [
  {
    title: "360 Marketing",
    to: "https://medium.com/@mr.gbjbuzz/performance-marketing-magic-elevating-your-nagpur-business-with-gbjbuzz-77f7026d9056",
  },
  {
    title: "Softwares",
    to: "#",
  },
  {
    title: "SEO",
    to: "#",
  },
  {
    title: "App Development",
    to: "#",
  },
  {
    title: "Web Development",
    to: "#",
  },
];
const hotsList = [
  {
    title: "AI/ML",
    to: "https://medium.com/@mr.gbjbuzz/case-study-claude-2-e01b4e4d0f2b",
  },
  {
    title: "Generative AI",
    to: "https://medium.com/@mr.gbjbuzz/case-study-codi-any-to-any-generation-via-composable-diffusion-33bc4556997f",
  },
  {
    title: "Virtual Reality",
    to: "https://medium.com/@mr.gbjbuzz/beyond-boundaries-the-future-of-virtual-reality-in-gaming-and-entertainment-a2cfc16a6f21",
  },
  {
    title: "Workplace",
    to: "https://medium.com/@mr.gbjbuzz/the-future-of-remote-work-how-technology-is-shaping-the-workforce-219a2c661516",
  },
];
const navigationList = [
  {
    title: "Service",
    to: "/all-services",
  },
  {
    title: "Digital Marketing",
    to: "/services/xpan",
  },
  {
    title: "AI/ ML Services",
    to: "/services/7i",
  },
  {
    title: "Education",
    to: "/services/libex",
  },
  {
    title: "Blockchain",
    to: "/services/blockx",
  },
];
const licenceList = [
  {
    title: "Privacy Policy",
    to: "/privacy-policy",
  },
  {
    title: "Term & Conditions",
    to: "/term&conditions",
  },
  {
    title: "Refund Policy",
    to: "/cancellaition-refund-policy",
  },
  {
    title: "Copyright",
    to: "/",
  },
  {
    title: "Assets",
    to: "/",
  },
];
const essentialsList = [
  {
    title: "AI Automations",
    to: "https://www.figma.com/exit?url=https%3A%2F%2Fwww.mckinsey.com%2Ffeatured-insights%2Ffuture-of-work%2Fai-automation-and-the-future-of-work-ten-things-to-solve-for",
  },
  {
    title: "Business Tools",
    to: "https://www.smartpassiveincome.com/guide/business-fundamentals/essential-business-tools",
  },
  {
    title: "Ad Campaign",
    to: "https://medium.com/@mr.gbjbuzz/gbj-buzz-help-unleashing-the-potential-of-360-marketing-for-business-growth-d730d3584578",
  },
  {
    title: "Penetration Testing",
    to: "https://www.imperva.com/learn/application-security/penetration-testing/",
  },
  {
    title: "Predictive Analytics",
    to: "https://medium.com/@mr.gbjbuzz/unlocking-business-growth-with-ai-driven-insights-in-predictive-intelligence-33c1e80f7875",
  },
];
const productList = [
  {
    title: "Healthcare Portal Development",
    to: "#",
  },
  {
    title: "Customer Relationship Management",
    to: "#",
  },
  {
    title: "Enterprise Resource Planning",
    to: "#",
  },
  {
    title: "Marketing Automation Platforms",
    to: "#",
  },
  {
    title: "Database as a Service",
    to: "#",
  },
  {
    title: "Assets",
    to: "#",
  },
];
const socailMediaIcons = [
  {
    title: "Instagram",
    logo: (
      <RiInstagramFill
        size={26}
        className="text-white hover:text-[#ffc900] transition-all duration-200 ease-linear  "
      />
    ),
    link: "https://www.instagram.com/gbj_buzz/",
  },
  {
    title: "Linkedin",
    logo: (
      <FaLinkedin
        size={26}
        className=" text-white hover:text-[#ffc900] transition-all duration-200 ease-linear  "
      />
    ),
    link: "https://www.linkedin.com/company/gbj-buzz/mycompany/",
  },
  {
    title: "Youtube",
    logo: (
      <BsYoutube
        size={26}
        className="text-white hover:text-[#ffc900] transition-all duration-200 ease-linear  "
      />
    ),
    link: "https://www.youtube.com/channel/UCz-6ncQAX2GGKy8v1veDMiw",
  },
  {
    title: "Facebook",
    logo: (
      <FaFacebookF
        size={26}
        className=" text-white hover:text-[#ffc900] transition-all duration-200 ease-linear  "
      />
    ),
    link: "https://m.facebook.com/people/GBJ-BUZZ/100068925834637/",
  },
  {
    title: "Twitter",
    logo: (
      <FaXTwitter
        size={26}
        className=" text-white hover:text-[#ffc900] transition-all duration-200 ease-linear  "
      />
    ),
    link: "https://twitter.com/gbjbuzz",
  },
  {
    title: "Medium",
    logo: (
      <SiMedium
        size={26}
        className=" text-white hover:text-[#ffc900] transition-all duration-200 ease-linear "
      />
    ),
    link: "https://medium.com/@mr.gbjbuzz",
  },
];

const NewFooter = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="bg-[#F7F7F7]  ">
      <div className="max-w-[1600px] mx-auto w-11/12 py-12">
        <div className="grid grid-cols-9 gap-x-8 tab:grid-cols-3 sm:grid-cols-2 gap-y-1 sm:gap-y-4  justify-between items-start tab:gap-y-5">
          <div
            data-aos-duration="2000"
            className="col-span-3 flex flex-col items-start tab:w-3/4 tab:place-content-center tab:hidden sm:hidden"
            id="logo_and_newsletter">
            <GbjLogo />
            <div
              data-aos-duration="2000"
              data-aos-delay="300"
              className="w-full my-5 tab:w-2/3 ">
              <h5 className="font-semibold text-sm text-[#939393]">
                We offer a comprehensive suite of digital marketing services
                that cover all aspects of our online presence . From SEO and
                social media marketing to content creation and PPC advertising,
                they have the expertise and resources to handle our diverse
                marketing needs.
              </h5>
            </div>
          </div>
          <div
            className=" sm:w-full sm:mx-auto min-w-[170px] tab:min-w-0 sm:min-w-0 "
            id="Services">
            <h4
              data-aos-duration="2000"
              data-aos-offset="300"
              className="text-xl font-semibold">
              Services
            </h4>
            <div className="mt-4 text-[#9C9C9C] font-semibold text-xs lg:text-xxs leading-6 ">
              {servicesLinkList?.map((ele, i) => (
                <div key={i} data-aos-duration="2000" data-aos-delay={i * 200}>
                  <a href={ele.to} target="_blank" rel="noopener noreferrer">
                    {ele.title}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full  mx-auto" id="Hots">
            <h4
              className="text-xl font-semibold "
              data-aos-duration="2000"
              data-aos-offset="300">
              Hots
            </h4>
            <div className="mt-4 text-[#9C9C9C] font-semibold text-xs leading-6">
              {hotsList?.map((ele, i) => (
                <div key={i} data-aos-duration="2000" data-aos-delay={i * 200}>
                  <a href={ele.to} target="_blank">
                    {ele.title}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full  mx-auto self-start" id="Navigation">
            <h4
              data-aos-duration="2000"
              data-aos-offset="300"
              className="text-xl font-semibold ">
              Navigation
            </h4>
            <div className="mt-4 text-[#9C9C9C] font-semibold text-xs leading-6">
              {navigationList?.map((ele, i) => (
                <div data-aos-duration="2000" data-aos-delay={i * 200} key={i}>
                  <Link to={ele.to}>{ele.title}</Link>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full  mx-auto self-start" id="Licence">
            <h4
              data-aos-duration="2000"
              data-aos-offset="300"
              className="text-xl font-semibold ">
              Licence
            </h4>
            <div className="mt-4 text-[#9C9C9C] font-semibold text-xs leading-6">
              {licenceList?.map((ele, i) => (
                <div data-aos-duration="2000" data-aos-delay={i * 200} key={i}>
                  <Link to={ele.to}>{ele.title}</Link>
                </div>
              ))}
            </div>
          </div>
          <div
            className="w-full sm:mx-auto col-span-2 tab:col-span-1 sm:col-span-1 self-start"
            id="contact">
            <h4
              data-aos-duration="2000"
              data-aos-offset="300"
              className="text-xl font-semibold ">
              Contact
            </h4>
            <div className="mt-4 text-[#9C9C9C] font-semibold text-xs leading-6 flex flex-col gap-y-2 items-start">
              <div data-aos-duration="2000" data-aos-delay="200">
                <a
                  href="tel:+91 7020107998"
                  className="flex gap-y-4 items-center">
                  <MdOutlineLocalPhone size={20} color="black" />
                  <p className="ms-2">+91 7020107998</p>
                </a>
              </div>
              <div data-aos-duration="2000" data-aos-delay="200">
                <a
                  href="mailto:team@gbjbuzz.com"
                  className="flex gap-y-4 items-center">
                  <GrMail size={20} color="black" />
                  <p className="ms-2">team@gbjbuzz.com</p>
                </a>
              </div>
              <div data-aos-duration="2000" data-aos-delay="200">
                <a
                  href="https://maps.app.goo.gl/3CyY7K3wJj3Wm33U8"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex gap-y-4 items-start ">
                  <FaLocationDot size={20} color="black" />
                  <p className="ms-2 font-bold">Headquarters at Nagpur</p>
                </a>
              </div>
            </div>
          </div>
          <div className="col-span-3 w-full tab:hidden sm:hidden">
            {/* <div className=''>
                            <h6 className=' text-xl font-semibold'>
                                Connect with us
                            </h6>
                        </div> */}
            <div className="flex gap-5 items-center ">
              {socailMediaIcons?.map((ele, i) => (
                <div
                  data-aos-duration="2000"
                  data-aos-delay={i * 200}
                  key={i}
                  className="w-10 h-10 flex items-center justify-center bg-black rounded-full">
                  <a href={ele.link} target="_blank" rel="noopener noreferrer">
                    {ele.logo}
                  </a>
                </div>
              ))}
            </div>
            <div className="flex items-center gap-x-4 mt-8">
              <h4 className="text-2xl lg:text-[22px] font-bold font-['Lato'] ">
                Proudly Made in Bharat
              </h4>
              <img
                src={LoveBharatIcon}
                alt="love bharat icon"
                className="w-8 h-8"
              />
            </div>
          </div>
          <div className="w-full  mx-auto self-start" id="Essentials">
            <h4
              data-aos-duration="2000"
              data-aos-offset="300"
              className="text-xl font-semibold ">
              Essentials
            </h4>
            <div className="mt-4 text-[#9C9C9C] font-semibold text-xs leading-6">
              {essentialsList?.map((ele, i) => (
                <div data-aos-duration="2000" data-aos-delay={i * 200} key={i}>
                  <Link to={ele.to}>{ele.title}</Link>
                </div>
              ))}
            </div>
          </div>
          <div
            className="w-full  mx-auto self-start col-span-2 tab:col-span-1 "
            id="Products">
            <h4
              data-aos-duration="2000"
              data-aos-offset="300"
              className="text-xl font-semibold ">
              Products
            </h4>
            <div className="mt-4 text-[#9C9C9C] font-semibold text-xs leading-6">
              {productList?.map((ele, i) => (
                <div data-aos-duration="2000" data-aos-delay={i * 200} key={i}>
                  <Link to={ele.to}>{ele.title}</Link>
                </div>
              ))}
            </div>
          </div>
          <div
            className="hidden col-span-2  flex-col items-start tab:w-3/4 tab:place-content-center tab:block sm:block "
            id="logo_and_newsletter">
            {/* <div

                            data-aos-duration="2000"
                            className='sm:my-5'>
                            <GbjLogo sm setToggle={() => { }} />
                        </div> */}

            <div className="flex tab:gap-5 tab:justify-center sm:justify-between items-center mt-3">
              {socailMediaIcons?.map((ele, i) => (
                // <div

                //     data-aos-duration="2000"
                //     data-aos-delay={i * 200}
                //     key={i}>
                //     <a href={ele.link} target="_blank" rel="noopener noreferrer"
                //         className='w-8 h-8 flex items-center justify-center bg-black rounded-full'
                //     >
                //         {ele.logo}
                //     </a>
                // </div>
                <div
                  data-aos-duration="2000"
                  data-aos-delay={i * 200}
                  key={i}
                  className="w-10 h-10 flex items-center justify-center bg-black rounded-full">
                  <a href={ele.link} target="_blank" rel="noopener noreferrer">
                    {ele.logo}
                  </a>
                </div>
              ))}
            </div>
            <div className="flex items-center gap-x-4 mt-6">
              <h4 className="text-lg tab:text-xl font-bold font-['Lato'] ">
                Proudly Made in Bharat
              </h4>
              <img
                src={LoveBharatIcon}
                alt="love bharat icon"
                className="w-7 h-7"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black text-[14px] sm:text-[10px] text-[#CCCCCC] py-2 text-center font-semibold">
        <p>Copyright © {year} GBJ Buzz Pvt Ltd. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default NewFooter;
