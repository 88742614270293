import React, { useRef } from "react";
import linebg2 from "../../../Assets/linedbg2.png";
import { BsArrowUpRight } from "react-icons/bs";
import WorkWithUsImg from "../../../Assets/HeroSection/Career.svg";

export const WorkWithUs = () => {
  const element = useRef();
  return (
    <div
      className="py-16 sm:py-10 shadow-md"
      style={{
        background:
          "radial-gradient(at 90% 26%, rgba(255,246,214) 30%, rgba(251,251,251) 56%, rgba(251,251,251) 60%)",
      }}>

      <div className="w-9/12 m-auto flex justify-between gap-6 tab:w-10/12 sm:w-11/12 sm:flex-col"
        data-aos='fade-up'
        data-aos-duration='500'
        data-aos-offset='100'
      >
        <div className="w-7/12 flex flex-col gap-3 sm:w-full sm:gap-1">
          <p className="text-2xl font-semibold sm:text-lg">
            Careers at GBJ BUZZ
          </p>
          <p className="text-6xl font-bold tab:text-[44px] sm:text-3xl">Work with us</p>
          <p className="sm:text-sm">
            Explore remote-friendly, flexible opportunities and join our mission
            to make work life simpler, more pleasant and more productive.
          </p>
          {/* button component */}

          <div
            className="flex shrink-0 justify-start relative mt-2 w-[250px] h-[50px] sm:w-[143px] sm:h-8 bg-[#FFC900] rounded-lg shadow-xl overflow-hidden"
            onMouseEnter={() => {
              element.current.classList.add("-translate-x-0");
              element.current.classList.remove("-translate-x-full");
            }}
            onMouseLeave={() => {
              element.current.classList.remove("-translate-x-0");
              element.current.classList.add("-translate-x-full");
            }}>
            <span
              ref={element}
              className={` absolute w-64 -top-1 -translate-x-full  transition-all duration-300 ease-in sm:w-40 sm:h-10 `}
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "#fff",
              }}>

              <img src={linebg2} alt="" />
            </span>

            <a
              href="https://forms.gle/6Q3aczznFcrm94tB9"
              target="_blank"
              className={` flex justify-around items-center  sm:text-sm rounded-xl font-semibold w-full h-full text-2xl sm:px-4 sm:py-1 py-1 px-5 shadow-custom2 bg-transparent z-10 `}>
              Apply Now <BsArrowUpRight />
            </a>
          </div>
          {/* button component ended */}
          <div>
            <a className="mt-2 text-sky-600 underline underline-offset-4 cursor-pointer" href="https://forms.gle/6Q3aczznFcrm94tB9" target="_main">
            Join Us Now!
            </a>
          </div>

        </div>
        <div className="w-5/12 flex flex-col gap-2 sm:w-full">
          <img src={WorkWithUsImg} alt="wortwithusimage" />
        </div>
      </div>
    </div>
  );
};
