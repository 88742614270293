import React from 'react'

import Ethereum from '../../../../Assets/CardImages/BlockXBuzz/ethereum-eth-logo.svg'
import Hyperledger from '../../../../Assets/CardImages/BlockXBuzz/Hyperledger_Logo.png.svg'
import Corda from '../../../../Assets/CardImages/BlockXBuzz/Corda_Logo.png.svg'
import Substrate from '../../../../Assets/CardImages/BlockXBuzz/Substrate-Grayscale.svg.svg'
import Avalanch from '../../../../Assets/CardImages/BlockXBuzz/Avalanche.svg'
import Polkadot from '../../../../Assets/CardImages/BlockXBuzz/Polkadot-Grayscale.svg.svg'
import NEARProtocol from '../../../../Assets/CardImages/BlockXBuzz/Near-Grayscale.svg.svg'
import Hedera from '../../../../Assets/CardImages/BlockXBuzz/Hashgraph_Logo.png.svg'
import Tezos from '../../../../Assets/CardImages/BlockXBuzz/Tezos-Grayscale.svg.svg'

const array = [
    {
        img: Ethereum,
        title: 'Ethereum',
        desc: "A decentralized public platform that runs dApps"
    },
    {
        img: Hyperledger,
        title: 'Hyperledger',
        desc: "An umbrella project of open-source blockchains to build enterprise-grade blockchain apps"
    },
    {
        img: Corda,
        title: 'Hyperledger',
        desc: "Corda is an open source blockchain platform for businesses"
    },
    {
        img: Substrate,
        title: 'Substrate',
        desc: "Substrate is a blockchain development framework. We build relay chains, parachains, cross chain bridges and dapps using Substrate, ink! and RUST."
    },
    {
        img: Avalanch,
        title: 'Avalanch',
        desc: "Avalanche protocol enables the developers to build custom blockchain networks spanning private and permissionless deployments"
    },
    {
        img: Polkadot,
        title: 'Polkadot',
        desc: "Polkadot is an open-source web3 blockchain network for enabling interoperability and interconnectivity."
    },
    {
        img: NEARProtocol,
        title: 'NEAR Protocol',
        desc: "Near is a smart-contract capable layer 1 blockchain designed to support next-generation dApp development."
    },
    {
        img: Hedera,
        title: 'Hedera',
        desc: "Fair, Fast and most secured acrylic graph DLT solution for building EVM compatible, high speed blockchain solutions."
    },
    {
        img: Tezos,
        title: 'Tezos',
        desc: "An Open-Source Platform for Decentralized Assets and Applications"
    },
]


const Platform = () => {
    return (
        <div className='mb-2 shadow-md' style={{
            background:
                "radial-gradient(at 90% 50%, rgba(255,246,214,0.3), rgba(255,255,255) 80%, rgba(255,255,255) 40%)",
        }}>
            <div className='w-10/12 m-auto tab:w-11/12 sm:w-11/12 py-9'>
                <h1 className='text-3xl text-center font-semibold tab:text-2xl sm:text-xl'>
                    Blockchain Development Platforms We Work On
                </h1>
                <div className='mt-16'>
                    {
                        array?.map((ele, i) => {
                            return <Card key={i} {...ele} />
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Platform

const Card = ({ img, title, desc }) => {
    return (
        <div className='w-8/12 m-auto tab:w-11/12 sm:w-full flex flex-row gap-4 my-6'>
            <div className='w-1/12'>
                <img src={img} alt={title} loading='lazy' />
            </div>
            <div className='w-10/12'>
                <h4 className='font-semibold'>{title}</h4>
                <h5 className='my-1 text-gray-500'>{desc}</h5>
            </div>
        </div>
    )
}