import React from "react";

import firstGroup from "../../../Assets/CardImages/Service/DigitalMarketing.svg";
import secondGroup from "../../../Assets/CardImages/Service/AiSoftwearBasedService.svg";
import thirdGroup from "../../../Assets/CardImages/Service/EdTechTraningPlatform.svg";
import fourthGroup from "../../../Assets/CardImages/Service/Blockchainbasedservice.svg";
import fifthGroup from "../../../Assets/CardImages/Service/CyberSecurity.svg";
import Card from "./Card/Card";
import styles from "./styles.module.css";
import yellowCircle from '../../../Assets/Illustration/topCircle.svg'
import MobileCard from "./Card/MobileCard";

const Cards = () => {
  const cards = [
    {
      id: 0,
      heading: "Xpan Buzz ",
      subHeading: "Digital Marketing Service",
      desc: "Your brand growth partner. Expert social media, content, SEO, and ads strategies. Boost sales, connect with target audience.",
      img: firstGroup,
      link: '/services/xpan'
    },
    {
      id: 1,
      heading: "7i Buzz ",
      subHeading: "AI & Software Based service",
      desc: "AI-powered data solutions. Specializing in analysis, business intelligence, machine learning. Make informed decisions, manage big data.",
      img: secondGroup,
      link: '/services/7i'
    },
    {
      id: 2,
      heading: "LibeX Buzz ",
      subHeading: "EdTech & Traning Platform",
      desc: "Edtech platform empowers interns, enhances product development. Unleash creativity for market success and brand building.",
      img: thirdGroup,
      link: '/services/libex'
    },
    {
      id: 3,
      heading: "BlockX Buzz ",
      subHeading: "Blockchain based service",
      desc: "Blockchain services secure data. Innovative tech and solidity-based apps protect sensitive data, empowering confident business safeguarding.",
      img: fourthGroup,
      link: '/services/blockx'
    },
    {
      id: 4,
      heading: "CyberX Buzz ",
      subHeading: "Cyber Security",
      desc: "Blockchain services ensure data security with innovative technology and solidity-based applications for confident business safeguarding.",
      img: fifthGroup,
      link: '/services/cyber-security'
    },
  ];

  return (
    <div
      className="relative h-full sm:top-0  sm:mb-2 shadow-md shadow-gray-400 w-auto "
      style={{
        background:
          "linear-gradient(to top,rgba(255,205,35,0.1), #fff,rgba(255,205,35,0.1),rgba(255,205,35,0.1), #fff,rgba(255,205,35,0.1), #fff,rgba(255,205,35,0.1)",
      }}>

      {/* animated circle Top */}
      <div className="absolute w-full flex justify-end items-start sm:hidden tab:hidden">
        <img src={yellowCircle} alt="fourYellowCircle" className="animate-[spin_5s_linear_infinite] w-56 z-0 " id="topYellowCircles" loading='lazy' />
      </div>
      {/* animated circle Top end */}

      {/* animated circle Bottom */}
      <div className="absolute flex justify-start items-end w-full h-full sm:hidden tab:hidden">
        <img src={yellowCircle} alt="fourYellowCircle2" className="w-56 animate-[spin_5s_linear_infinite]" id="bottomYellowCircles" loading='lazy' />
      </div>
      {/* animated circle Bottom end */}

      {/* cards Container */}
      <div
        className={`relative mb-2 py-16 ${styles.container} w-11/12 mx-auto sm:py-6 sm:mb-0`}>
        {/* large screen and tablet screen cards */}
        <div className="flex flex-col justify-evenly items-center  mt-1 sm:hidden">
          {cards?.map((card, index) => (
            <Card key={index} {...card} />
          ))}
        </div>
        {/* large screen and tablet screen cards ended */}

        {/* mobile screen cards */}
        <div className="hidden sm:flex flex-col justify-evenly items-center mt-1">

          {
            cards?.map((card, index) => (
              <MobileCard key={index} {...card} />
            ))
          }
        </div>
        {/* mobile screen cards end */}
      </div>
      {/* cards Container ended */}

    </div>
  );
};

export default Cards;
