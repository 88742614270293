import React, { useRef, useState } from "react";
import buttonLine from "../../../Assets/Images/CyberSecurity/buttonline.png";
import { ToastContainer, toast } from "react-toastify";
import supabase from "../../../SupabaseConfig/SupabaseClient";

export const BestContent = () => {
  const SubscribeButton = useRef();
  const [mailId, setMailId] = useState("");

  const handleSubscribe = async () => {
    // console.log("button click")
    if (mailId === "") {
      return toast.error("Add email address...");
    }
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mailId)) {
      // return (true)
      try {
        const { error } = await supabase
          .from("NewsLetterMailRecord")
          .insert({ mailId });
        if (error) {
          toast.error("Something went wrong");
        } else {
          setMailId("");
          return toast.success("your request send successfully");
        }
      } catch (error) {
        toast.error("Server Error");
      }
    } else {
      return toast.error("You have entered an invalid email address!");
    }
  };
  return (
    <div className=" bg-gray-200 py-6 pb-10 mt-6 sm:py-2 sm:pb-8 ">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="w-9/12 m-auto mt-6 flex justify-between tab:w-10/12 tab:flex-col sm:w-11/12 sm:flex-col sm:gap-2">
        <div className="w-3/12 lg:w-[30%] tab:w-full sm:w-full flex flex-col gap-2">
          <p className="text-xl font-bold">
            Get our best content in your inbox
          </p>
          <p className="text-sm">
            All the tips, stories, and resources you could ever need or want —
            straight to your email!
          </p>
        </div>
        <div className="w-7/12 lg:w-[64%] tab:w-full tab:mt-4 flex flex-col gap-4 sm:w-full">
          <form
            action=""
            className="space-y-4"
            onSubmit={e => {
              e.preventDefault();
              handleSubscribe();
            }}>
            <div className="flex gap-6 sm:flex-col sm:gap-2">
              <div className="flex gap-2">
                <input type="checkbox" className="-mt-3" />
                <div>
                  <p className="font-medium">The Supportive Weekly</p>
                  <p className="text-xs">For the customer service obsessed</p>
                </div>
              </div>

              <div className="flex gap-2 ">
                <input type="checkbox" className="-mt-3" />
                <div>
                  <p className="font-medium">In the Works</p>
                  <p className="text-xs">For founders and growing companies</p>
                </div>
              </div>
            </div>
            <div className="flex gap-2 sm:flex-col">
              <input
                type="email"
                placeholder="Enter your email address..."
                value={mailId}
                onChange={e => setMailId(e.target.value)}
                required
                className="w-80 sm:w-full h-8 pl-2 rounded-lg border border-gray-400 border-solid"
              />
              <button
                className="w-32 h-8 px-4 flex justify-around items-center text-base font-bold rounded-lg bg-[#FFC900] relative overflow-hidden
              "
                onMouseEnter={() => {
                  SubscribeButton.current.classList.remove("-translate-x-full");
                  SubscribeButton.current.classList.remove("left-1");
                  SubscribeButton.current.classList.add("right-2");
                }}
                onMouseLeave={() => {
                  SubscribeButton.current.classList.add("-translate-x-full");
                  SubscribeButton.current.classList.add("left-1");
                  SubscribeButton.current.classList.remove("right-2");
                }}>
                <div
                  ref={SubscribeButton}
                  className="flex absolute left-1 -translate-x-full transition-all duration-150 ease-in">
                  <img
                    className="w-6 -mr-3 "
                    src={buttonLine}
                    alt="buttonline"
                  />
                  <img className="w-6 " src={buttonLine} alt="buttonline" />
                </div>
                <div className="flex absolute gap-3 items-center">
                  <p className="font-medium">Subscribe</p>
                </div>
              </button>
            </div>
            <div className="flex gap-2 items-center">
              <input type="checkbox" name="" id="" required />
              <p className="text-base font-medium sm:text-sm">
                I agree to receive content from GBJ BUZZ.
              </p>
            </div>
          </form>
          <div>
            <p className="text-sm font-medium">
              Your privacy matters! gbj buzz only uses this info to send content
              and updates. You may unsubscribe anytime. View our
              <span className="text-blue-700 underline cursor-pointer px-1">
                privacy policy
              </span>
              for more.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
