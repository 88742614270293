import React, { useEffect, useRef, useState } from "react";
import Slider from 'react-slick';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectFade } from "swiper/modules";

import "swiper/css";
import 'swiper/css/pagination';
import "swiper/css/navigation";
import "swiper/css/autoplay";
import 'swiper/css/effect-fade';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ImCheckboxChecked } from 'react-icons/im'


import XpanBuzzImg from "../../../Assets/Services Logo/xpanbuzz_bw.svg";
import CyberXImg from "../../../Assets/Services Logo/cyberX-logo.svg";
import LibexImg from "../../../Assets/Services Logo/Libex_bw.svg"
import SevenIBuzzImg from "../../../Assets/Services Logo/7i_bw.svg"
import BlockXBuzzImg from "../../../Assets/Services Logo/BlockX_bw.svg"
// import XpanBuzzImg from "../../../Assets/Services Logo/Xpan.svg";
// import CyberXImg from "../../../Assets/Services Logo/CyberX.svg";
// import Circle1Img from "../../../Assets/SVG_Images/LandingPage/Circle.svg";
// import Circle2Img from "../../../Assets/SVG_Images/LandingPage/Circle2.svg";
// import LibexImg from "../../../Assets/Services Logo/Libex.png"
// import SevenIBuzzImg from "../../../Assets/Services Logo/7ibuzz.png"
// import BlockXBuzzImg from "../../../Assets/Services Logo/BlockX.png"
// Import Swiper React components

import "aos/dist/aos.css";
import "aos/dist/aos.js";


const servicesCardData = [
    {
        id: 0,
        title: "Digital Marketing",
        img: XpanBuzzImg,
        description: "Boost your brand online, Our digital marketing services enhance visibility and captivate your audience.",
        imgStyle: "",
        buttonText: "Xpan",
        link: "/services/xpan",
        bulletpoints: `SEO
        Social Media Management
        Online Reputation Management
        Digital Ad Campaign
        Branding`,
        bgColor: "#ffc900"

    },
    {
        id: 1,
        title: "Cyber Security",
        img: CyberXImg,
        description: "Safeguard your digital assets and ensure robust protection for business resilience and success, Explore our Cybersecurity solutions.",
        imgStyle: "",
        buttonText: "CyberX",
        link: "/services/cyber-security",
        bulletpoints: `Penetration Testing
        Firewall & Network Security
        Security Consulting
        Data Encryption
        Identity & Access Management`,
        bgColor: "#6ecfbd"

    },
    {
        id: 2,
        title: "Training & Skills",
        img: LibexImg,
        description: "Our advanced training & skills development platform, shaping a knowledgeable and empowered future for your success.",
        imgStyle: "",
        buttonText: "Libex",
        link: "/services/libex",
        bulletpoints: `Professional Development Courses
        Skills Assessment
        Soft Skills Training
        Technology Training
        Workshops & Webinars`,
        bgColor: "#FF9400"

    },
    {
        id: 3,
        title: "AI/ML Development",
        img: SevenIBuzzImg,
        description: "Cutting-edge AI tech development, driving innovation and transformative solutions for your business success",
        imgStyle: "!py-0 tab:!p-0",
        buttonText: "7i",
        link: "/services/7i",
        bulletpoints: `Computer Vision
        Predictive Analytics
        Algorithm Development
        Custom AI Solutions
        Deployment & Integration`,
        bgColor: "#8e5aff"
    },
    {
        id: 4,
        title: "Blockchain Development",
        img: BlockXBuzzImg,
        description: "Elevate your business with our leading-edge development, shaping a secure & decentralized future for success.",
        imgStyle: " ",
        buttonText: "BlockX",
        link: "/services/blockx",
        bulletpoints: `Token Security
        Secure Wallet Development
        Smart Contract
        Decentralized Apps (DApps)
        Privacy-Enhancing Technologies`,
        bgColor: "#0495f2"
    },
    {
        id: 5,
        title: "SaaS & PaaS",
        img: "",
        description: "We excel in delivering cutting-edge cloud services, including Software as a Service (SaaS) and Platform as a Service (PaaS).",
        imgStyle: " ",
        buttonText: "SaaS & PaaS",
        link: "/services/7i",
        bulletpoints: `Customer Relationship Management
        Enterprise Resource Planning
        Sales Performance Management
        Microservices Architecture
        CI/CD Services`,
        bgColor: "#fff"
    }
]

export const Services = () => {
    const sliderRef = useRef();
    const swipeRef = useRef();
    const [currentSlide, setCurrentSlide] = useState(0);
    // const [currentPage, setCurrentPage] = useState(0);



    const settings = {
        dots: true,
        infinite: true,
        fade: true,
        speed: 500, // Autoplay speed (in milliseconds)
        slidesToShow: 1,
        // autoplay: true,
        slidesToScroll: 1,
        dotsClass: "slick-dots !relative !bottom-0 !w-max !mx-auto !mt-5  sm:!my-5 ",
        customPaging: () => (
            <div
                style={{
                    width: "8px",
                    height: "8px",
                    color: "#fff",
                    border: "1px #fff solid",
                    borderRadius: "50%",


                }}
            >
            </div>
        )
    };

    const handleSlideChange = (index) => {
        sliderRef.current?.slickGoTo(index);

        setCurrentSlide(index);
    };

    const handleAutoChange = () => setTimeout(() => {
        if (currentSlide < 5) {
            handleSlideChange(currentSlide + 1)
        } else
            handleSlideChange(0)
    }, 2500)


    useEffect(() => {
        handleAutoChange()
        setCurrentSlide(swipeRef?.current?.swiper?.activeIndex)
    }, [currentSlide])
    // setTimeout(() => , 2500)
    const handleClearTimeout = () => clearTimeout(handleAutoChange)


    return (
        <section className="bg-white sm:bg-black">
            <div className="max-w-[1600px] w-11/12 mx-auto bg-black h-screen max-h-[630px] lg:h-auto lg:max-h-none  grid px-10 sm:px-0 grid-cols-2 justify-center py-14 my-12 sm:my-0 rounded-[65px] tab:rounded-[45px] tab:my-16 tab:w-11/12  tab:h-auto sm:max-h-none sm:h-auto sm:w-11/12 sm:grid-cols-1 sm:pt-[3.5rem] sm:pb-[1rem] sm:rounded-none">
                <div className="text-white  ">
                    <h2 data-aos="fade-up" data-aos-duration="1000" className="text-5xl font-bold text-center mb-[28px] tab:text-3xl sm:text-4xl ">
                        Services
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" className=" text-lg md:text-base sm:text-base sm:hidden">
                        The services provided by GBJ Buzz are designed to foster business growth, all conveniently available in one place. Explore a wide variety of service categories tailored to meet your specific needs.
                    </p>
                    <div className="w-8/12 flex flex-wrap items-stretch gap-y-2 gap-x-4  mt-[49px] sm:hidden">
                        {
                            servicesCardData.map((ele, i) => <ButtonCard key={ele.id} {...ele} handleSlideChange={handleSlideChange} currentSlide={currentSlide} handleClearTimeout={handleClearTimeout} />)
                        }
                    </div>
                </div>
                <div className="text-white" id="servicesSwiper">
                    <div className="w-9/12 xl:w-10/12 lg:w-11/12 ms-auto tab:w-full sm:w-[96%] sm:mx-auto">

                        {/* <div className="swiper ">
                            <Slider ref={sliderRef} {...settings}>
                                {
                                    servicesCardData.map((ele) =>
                                        <SwiperCard key={ele.id} {...ele} link={ele.link} />

                                    )
                                }

                            </Slider>
                        </div> */}
                        <div id="servicesSwiper">
                            <Swiper
                                ref={swipeRef}
                                pagination={{
                                    clickable: false,
                                }}
                                modules={[Autoplay, Pagination, EffectFade]}
                                effect={'fade'}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false
                                }}
                                className="mySwiper !pb-10"
                            >
                                {
                                    servicesCardData.map((ele) => <SwiperSlide key={ele.id}>
                                        <SwiperCard  {...ele} />
                                    </SwiperSlide>

                                    )
                                }
                            </Swiper>

                        </div>
                    </div>
                </div>
                {/* <div className="mt-5">
                    <h2 className="hidden text-white sm:text-base sm:block">
                        The services provide by GBJ buzz helps to grow your business at one Place with the wide verity of services categories:
                        page when looking at its layout. The point of using Lorem Ipsum is.
                    </h2>
                </div> */}

            </div>

        </section >

    )
}


const ButtonCard = ({ id, imgStyle, img, title, buttonText, handleSlideChange, currentSlide, bgColor, handleClearTimeout, link }) => {
    return (
        <Link to={link} data-aos-duration="1000" data-aos-delay={id * 200} className={`bg-[#1D1D1D] flex justify-evenly items-center w-40 p-2 rounded-lg lg:w-36 tab:w-24 ${imgStyle} `}
            style={{
                backgroundColor: id === currentSlide ? bgColor : ''
            }}
        // onClick={() => {
        //     handleSlideChange(id)
        //     handleClearTimeout()
        // }}
        >
            {
                img ?
                    <div className="w-5/12 " >
                        <img src={img} alt={title} className="self-start" />
                    </div>
                    : ""
            }
            <div className={`${img ? "w-7/12" : "w-full "}`}>
                <h3 className={`text-lg text-left tab:text-xs ${img ? "" : "text-center"} 
                ${currentSlide===5 && id === 5 ?"text-black":""}`}
                >
                    {buttonText}
                </h3>

            </div>
        </Link>
    )
}

const SwiperCard = ({ id, title, description, bulletpoints, bgColor, link }) => {
    // const navigate = useNavigate()
    // console.log("LLLLLL",link)
    return (
        <div
            data-aos-duration="1000" data-aos-delay='300'
            id={`${id}`}
            className=" rounded-3xl mx-5 sm:mx-0 tab:mr-0 bg-[#1D1D1D]  self-center"

        >
            <div className="w-[85%] m-auto py-7 md:w-11/12 sm:w-full">
                <div

                    data-aos-duration="1000"
                >
                    <h1 className="text-[1.6rem] text-center font-semibold tab:text-[1.2rem]  sm:text-xl">
                        {title}
                    </h1>
                </div>
                <h5

                    data-aos-duration="1000"
                    data-aos-delay="200"
                    className="text-left h-16 xl:h-[5.2rem] lg:h-[5.4rem]  mt-4 tab:h-28 sm:mt-6 sm:h-28 sm:px-4">
                    {description}
                </h5>
                <div className="sm:w-11/12 mx-auto">
                    {
                        bulletpoints.toString().split('\n').map((ele, i) => <div

                            data-aos-duration="1000"
                            data-aos-delay={i * 200}
                            className="text-left flex items-center gap-x-4 gap-y-1 mt-5 tab:gap-2 sm:gap-2" key={i}>
                            <div >
                                <ImCheckboxChecked className={`rounded-full text-xl `} color={bgColor} />
                            </div>
                            <div>
                                <h6 className=" text-lg tab:text-sm sm:text-base">
                                    {ele}
                                </h6>
                            </div>
                        </div>)
                    }
                </div>
                <div className="mt-10 tab:mt-6 sm:mt-6 flex justify-center items-center">
                    <Link to={link} className="border-[3px] border-white px-6 py-2"
                    >Explore More
                    </Link>
                </div>
            </div>
        </div>
    )
}