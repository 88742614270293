import React from "react";
import OnlineWorldImg from "../../../Assets/SVG_Images/Career/OnlineWorld.svg";
import CheckOKImg from "../../../Assets/SVG_Images/Career/checkOK.svg";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
const workingData = [
  {
    title: "Time off to rest, recharge and volunteer",
  },
  {
    title: "Exceptional healthcare coverage",
  },
  {
    title: "Holistic programmes for well-being, family planning and more",
  },
];
export const WorkingThriving = () => {
  return (
    <div data-aos="fade-left" data-aos-duration="2400" data-aos-offset="400" className="py-16 sm:py-10 shadow-md mb-4 sm:mb-0">
      <div className="w-9/12 tab:w-10/12 m-auto mt-6 flex justify-between gap-2 items-center sm:w-11/12 sm:flex-col-reverse">
        <div className="w-5/12 sm:w-full">
          <img src={OnlineWorldImg} alt="online world image" />
        </div>
        <div className="w-6/12 flex flex-col gap-4 sm:w-full">
          <p className="text-3xl font-bold sm:text-2xl">Working and thriving</p>
          <p className="tab:text-[15px]">
            As part of the global GBJ Buzz family, we’re proud to offer benefits
            that help you to feel and do your best. Access a range of benefits,
            resources and expert guidance to help you to prioritise your
            well-being, so you can thrive with a healthy body and mind.
          </p>
          <div className="flex flex-col gap-2">
            {workingData?.map((el, i) => (
              <div key={i} className="flex gap-4 items-start">
                <img className="mt-1" src={CheckOKImg} alt="check ok image" />
                <p className="tab:text-[15px]">{el.title}</p>
              </div>
            ))}
          </div>
          {/* <Link className="mt-6 text-sky-600 flex gap-1 items-center">
            Learn more 
            <span>
              <BsArrowRight />
            </span>
          </Link> */}
        </div>
      </div>
    </div>
  );
};
