import React from 'react'
import { FaBell } from "react-icons/fa";

const BellButton = ({ length }) => {
    return (
        <div className='relative p-[10px] bg-black text-[#ffc900] rounded-full'>
            <FaBell size={20} />
            <div className='absolute top-[6px] right-2 flex items-center justify-center w-[14px] h-[14px] text-xxs font-semibold rounded-full bg-[#c9372c]' ><p>{length}</p></div>
        </div>
    )
}

export default BellButton;