import React from "react";

import NewMember from "./Member/NewMember";

import Gunjan from "../../../Assets/person/gbjbuzz-ceo.svg";
import AthravaJ from "../../../Assets/person/gbjbuzz-cto.png";
import AthravaB from "../../../Assets/person/gbjbuzz-cmo.svg";
import PraveenB from "../../../Assets/person/gbjbuzz-cfo.svg";
import YashW from '../../../Assets/person/gbjbuzz-cpo.svg'
import Shivprasad from '../../../Assets/person/gbjbuzz-coo.svg'

const memberArray = [
  {
    name: 'Gunjan Jagnade',
    position: 'CEO & Founder',
    img: Gunjan
  },
  {
    name: 'Shivprasad Motghare',
    position: 'Co-Founder & COO',
    img: Shivprasad
  },
  {
    name: 'Yash Wankhade',
    position: 'CPO',
    img: YashW
  },
]

const Members = () => {
  return (
    <div className=" max-w-[1500px] m-auto bg-white">
      <div className="flex flex-row flex-wrap gap-2 justify-center w-11/12 m-auto sm:flex-col items-center pt-6">
        {
          memberArray?.map((ele, i) => {
            return <NewMember {...ele} key={i} />
          })
        }
      </div>











      {/* Container for main members */}
      {/* <div
        div
        className="flex justify-between lg:mx-0 sm:flex-col flex-row relative sm:w-full w-full m-auto mt-7 tab:flex-col">
        <div
          className={`${styles.yellowContainer} p-5 w-1/3 sm:w-2/4 rounded-r-3xl relative h-72 sm:h-60 tab:w-1/2`}
          data-aos='fade-right'
          data-aos-duration="500"
        >
          <Member
            img={KothariDP}
            name={"Dr.D.P. Kothari"}
            role={"Mentor"}
            color={"#000"}
            secColor={"#4E4C4C"}
          />
        </div>
        <div
          className={`${styles.blackContainer} w-2/4 sm:w-3/4 flex rounded-l-3xl relative mt-20 h-72 sm:h-60 left-0  items-center tab:w-3/4 self-end`}
          data-aos='fade-left'
          data-aos-duration='500'
          data-aos-offset='200'
        >
          <Member
            img={Pravin}
            name={"Dr. Pravin Kshirsagar"}
            role={"Founder"}
            color={"#ffd01f"}
            secColor={"#fff"}
          />
          <Member
            img={Gunjan}
            name={"Gunjan Jagnade"}
            role={"CEO & Founder"}
            color={"#ffd01f"}
            secColor={"#fff"}
          />
        </div>
      </div> */}
      {/* Container for main members end */}

      {/* Container for other members */}
      {/* <div
        className={`${styles.greyContainer} py-5 relative top-16 flex sm:justify-evenly justify-normal w-11/12 sm:w-full rounded-r-3xl items-center flex-row sm:flex-col h-56 sm:h-64 sm:rounded-none m-auto ms-0 tab:w-full tab:rounded-none`}>
        <Marquee
          gradient={false}
          speed={100}
          pauseOnClick={true}
          delay={0}
          play={true}
          direction="right"
          gradientWidth={200}>
          <Member
            img={Krushna}
            name={"Krushn D"}
            role={"CTO"}
            secColor={"#fff"}
          />
          <Member
            img={PraveenB}
            name={"Praveen B"}
            role={"COO"}
            secColor={"#fff"}
          />
          <Member
            img={AthravaJ}
            name={"Athrava J"}
            role={"CFO"}
            secColor={"#fff"}
          />
          <Member
            img={AthravaB}
            name={"Athrava B"}
            role={"CMO"}
            secColor={"#fff"}
          />
          <Member
            img={Parag}
            name={"Parag K"}
            role={"CoreDev"}
            secColor={"#fff"}
          />
          <Member
            img={Krushna}
            name={"Krushn D"}
            role={"CTO"}
            secColor={"#fff"}
          />
          <Member
            img={PraveenB}
            name={"Praveen B"}
            role={"COO"}
            secColor={"#fff"}
          />
          <Member
            img={AthravaJ}
            name={"Athrava J"}
            role={"CFO"}
            secColor={"#fff"}
          />
          <Member
            img={AthravaB}
            name={"Athrava B"}
            role={"CMO"}
            secColor={"#fff"}
          />
          <Member
            img={Parag}
            name={"Parag K"}
            role={"CoreDev"}
            secColor={"#fff"}
          />
        </Marquee>
      </div> */}
      {/* Container for other members  ended */}
    </div>
  );
};

export default Members;
