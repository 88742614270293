import React from 'react'
import Marquee from "react-fast-marquee";

import Client1 from '../../../Assets/clients/Dudhe-clinic-nagpur.png'
import Client2 from '../../../Assets/clients/MyFitMeals-nagpur.png'
import Client3 from '../../../Assets/clients/TheBlissEvents.png'
import Client5 from '../../../Assets/clients/VsMahakalkar.png'
import Client6 from '../../../Assets/clients/AvModular-nagpur.png'
import Client7 from '../../../Assets/clients/Big-Bite-cafe.png'
import Client8 from '../../../Assets/clients/BlueRoof.png'
import Client9 from '../../../Assets/clients/WelnessGym.png'


const arrayImg = [
    {
        img: Client1,
        alt: 'Dudhe-clinic-nagpur',
        imgClasses: ""
    },
    {
        img: Client2,
        alt: 'MyFitMeals-nagpur',
        imgClasses: ""
    },
    {
        img: Client3,
        alt: "THe Bliss Events",
        imgClasses: "w-58 h-52 sm:w-42 sm:h-36"
    },
    {

        img: Client5,
        alt: "V.S. Mahakalkar",
        imgClasses: ""
    },
    {

        img: Client6,
        alt: "A.V. Modular-nagpur",
        imgClasses: ""
    },
    {
        img: Client7,
        alt: "Big-Bite-cafe",
        imgClasses: ""
    },
    {
        img: Client8,
        alt: "Blue Roof Restaurant",
        imgClasses: ""
    },
    {
        img: Client9,
        alt: "Wellness Gym",
        imgClasses: ""

    }
]
const ClientMarquee = () => {
    return (
        <div>
            <div className="relative w-full h-72 m-auto flex mt-6 py-6  bg-[#d9d9d9] sm:h-40 items-center z-10 ">
                <Marquee
                    gradient={false}
                    speed={50}
                    pauseOnClick={true}
                    delay={0}
                    play={true}
                    direction="left"
                    gradientWidth={200}>
                    {arrayImg?.map((ele, index) => (
                        <Client {...ele} key={index} />
                    ))}
                </Marquee>
            </div>
        </div>
    )
}

export default ClientMarquee

const Client = ({ img, alt, imgClasses }) => {
    const style = {
        // borderRadius: '41px',
        background: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // height: 'max-content',
        flexShrink: 0,
        // border: "1px solid #aeaeae",
        // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    };
    return (
        <div
            className=" flex mx-4 items-center rounded-2xl sm:rounded-lg shrink-0 sm:w-48 sm:h-24 w-64 h-36 overflow-hidden shadow-custom2"
            style={style}>
            <img src={img} className={imgClasses} alt={alt} loading='lazy' />
        </div>
    )
}