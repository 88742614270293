import React from "react";

import checkBox from "../../../Assets/SVG_Images/Service/CheckedBox.svg";
import gbjBuzzServices from "../../../Assets/SVG_Images/Service/gbj-buzz-services.svg";

const Promotion = () => {
  return (
    <div className="relative h-2/3  sm:mt-0 mb-2 shadow-md shadow-gray-400">
      <div
        className="relative  sm:top-0 h-2/3  py-24"
        style={{
          background:
            "radial-gradient(circle, rgba(255, 205, 35, 0.3), rgb(255, 255, 255))",
        }}>
        <div className="flex justify-center flex-col sm:w-11/12 m-auto ">
          <div className="flex justify-center">
            <div id="checkedImgDiv">
              <img src={checkBox} alt="checkBok" className="sm:w-12 sm:h-12 tab:h-24 tab:w-24 " loading='lazy' />
            </div>
            <div
              className="text-8xl sm:text-5xl font-semibold relative top-5 sm:top-3 tab:top-0"
              id="largeTextDiv">
              <p>Exclusive</p>
            </div>
            <div className="text-3xl sm:text-base font-semibold relative top-9 sm:top-3 tab:top-5">
              <p>Premium</p>
              <p>SUPPORT</p>
            </div>
          </div>
          <div className="flex  text-4xl flex-col  font-medium relative top-6 sm:text-base text-center tab:text-3xl">
            <p>Skyrocket Your Business Success:Unleash</p>
            <p>the Power of GBJ Premium Support with</p>
          </div>
          <div
            className="relative top-12 flex justify-center"
            id="shortIconForServices">
            <img className="sm:w-72 tab:w-1/2 w-[35%]" src={gbjBuzzServices} alt="gbj-buzz-services" loading='lazy' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promotion;
