import React, { useEffect } from 'react'
import Iluustration from '../../Assets/Illustration/ProductConstruction.svg'
import Gear from '../../Assets/Illustration/Gear.svg'
import Gears from '../../Assets/Illustration/Gears.svg'

const Product = () => {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <div style={{
            background:
                "radial-gradient(at 50% 50%, rgba(255,246,214,0.9), rgba(255,255,255) 80%, rgba(255,255,255) 40%)",
        }}>
            <div className='relative w-10/12 tab:w-11/12 sm:w-11/12 m-auto py-5 flex flex-col justify-end items-center'>
                <div className='w-9/12 m-auto self-center'>
                    <img src={Iluustration} alt="ComingSoonProductImg" loading='lazy' />
                </div>
                <div className='w-10/12 m-auto mt-5 tab:w-9/12 sm:w-9/12'>
                    <h2 className='text-center text-5xl font-semibold tab:text-3xl sm:text-xl'>
                        Product You Looking for is Coming soon
                    </h2>
                </div>
                <div className='absolute left-1 tab:left-0 sm:left-0 w-20 tab:w-16 sm:w-10'>
                    <img src={Gears} alt="Gears" loading='lazy' />
                </div>
                <div className='absolute right-0 w-28 tab:w-20 sm:w-14 bottom-0 '>
                    <img src={Gear} alt="Gear" loading='lazy' />
                </div>
            </div>
        </div>
    )
}

export default Product