import React from 'react'

const NewMember = ({ img, position, name }) => {
    return (
        <div className='flex flex-col w-[18rem] justify-center items-center my-3 flex-wrap  sm:w-2/3'>
            <div className='flex items-center'>
                <img style={{ filter: "drop-shadow(0px 6px 4px rgba(0, 0, 0, 0.25))" }} src={img} alt={`gbj-${position}`} className='w-52 h-52 sm:w-40 sm:h-40 tab:w-40 tab:h-40' loading='lazy' />
            </div>
            <div className='text-[1.5rem] font-semibold text-center'>
                <p >{name}</p>
            </div>
            <div className='rounded-full bg-[#FFC900] text-black  px-3 py-1 font-semibold text-center'>
                <p className='opacity-50'>{position}</p>
            </div>
        </div>
    )
}

export default NewMember