import React from "react";
import BlockXImg from "../../../../Assets/CardImages/BlockXBuzz/We'reHereToAmplify.svg";
import { Protect } from "../../../UniversalComponent/Protect/Protect";

export const WeAreHereToAmplify = () => {

  return (
    <div className="mt-6 mb-12 sm:my-0 ">
      <Protect
        img={BlockXImg}
        title="We're Here to Amplify"
        topDescription="Enhancing your operations through cutting-edge blockchain technology, our skilled team transforms procedures, guarantees the security of data, and strengthens the digital infrastructure with steadfast dedication."
        bottomDescription="Enhancing your initiatives using state-of-the-art blockchain technology, our proficient team reimagines procedures, ensures the security of data, and fortifies your digital infrastructure with unwavering commitment."
        projectDone="25+"
        yearExp="02+"
        projectDoneTitle="Working On Project"
        yearExpTitle="Blockchain developer"
        projectDescription="Explore our accomplished cybersecurity initiatives that exemplify our proficiency and client contentment."
        experecneDescription="Established knowledge forged from years of experience in the cybersecurity field."
      />
    </div>
  );
};
