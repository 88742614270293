import React, { useEffect, useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import supabase from "../../../SupabaseConfig/SupabaseClient";
import Moment from "react-moment";
import { Button } from "flowbite-react";
import { client } from "../../../sanity";

const marketingCardData = [
  {
    title: "How a Digital Marketing Agency Can Boost Your Business",
    description:
      "We are the top digital marketing agency for branding corp. We offer a full range service...",
    buttonColor: "bg-[#ffc900]",
  },
  {
    title: "The Latest Trends and Strategies with a Digital Marketing Agency",
    description:
      "Working with this marketing agency has been a true partnership. They have...",
    buttonColor: "bg-[#FF7B00]",
  },
  {
    title: "Maximizing ROI with the Expertise of a Digital Marketing Agency",
    description:
      "What sets this digital marketing agency apart is their exceptional quality to maintain the brand... ",
    buttonColor: "bg-[#00ff1a]",
  },
];

const StrategicBusinessGrowth = () => {
  const navigate = useNavigate();
  // const [supabaseData, setSupabaseData] = useState([])
  const [blogs, setBlogs] = useState([]);

  // const getData = async () => {
  //     try {
  //         const { data, error } = await supabase
  //             .from('BlogData')
  //             .select('*')
  //             .order('id', { ascending: false })
  //             .limit(3)
  //         setSupabaseData(data)
  //     } catch (error) {
  //         console.log(error)
  //     }
  // }
  // useEffect(() => {
  //     getData();
  // }, [])

  const fetchData = async () => {
    const query = `*[_type == "blog"][0..2]`;
    const blogs = await client.fetch(query);
    // console.log("SANITY QUERY", blogs)
    setBlogs(blogs);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="bg-[#f7f7f7]">
      <div className="max-w-[1600px] m-auto">
        <div className="w-11/12 m-auto pb-12 sm:py-8">
          <div className="flex justify-between gap-x-10 tab:grid sm:grid tab:grid-cols-1  sm:grid-cols-1 sm:gap-x-3">
            <div className="2xl:pr-32 lg:w-6/12 xl:w-7/12 2xl:w-7/12 sm:w-11/12 sm:m-auto">
              <h2
                data-aos="fade-up"
                data-aos-duration="1000"
                className="text-[50px] xl:text-5xl lg:text-4xl leading-[3.7rem] xl:leading-[4rem] lg:leading-[3.5rem] tab:leading-[3.8rem]  tab:text-5xl sm:text-[26px] sm:leading-[34px] sm:font-semibold">
                <span className="bg-[#ffc900] rounded-lg py-[2px] ">
                  Strategic Business Growth
                </span>{" "}
                Solutions and IT Innovations for Driving Revenue.
              </h2>
            </div>
            <div className="lg:w-6/12 xl:w-5/12 2xl:w-5/12  sm:w-11/12 sm:m-auto flex flex-col justify-between sm:mt-2">
              <p
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="100"
                className="text-lg leading-6 lg:text-lg sm:text-[15px] sm:leading-normal text-[#797979]">
                Choose our Strategic Solutions Agency for expert corporate
                branding. Our tailored services are crafted to boost business
                visibility and drive enhanced traffic and sales for our clients'
                enterprises.
              </p>
              <button
                onClick={() => navigate("blogs")}
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
                className="w-40 lg:text-32 tab:hidden sm:hidden text-xl lg:text-lg bg-[#FFC900] font-semibold text-black rounded-full py-4 lg:py-3 !transition-all !duration-200 !ease-linear hover:bg-black hover:text-white">
                See more
              </button>
            </div>
          </div>
          <div className="grid grid-cols-3 sm:flex sm:flex-col-reverse gap-8 sm:gap-4 tab:grid-cols-2 tab:justify-center mt-10 sm:mt-6">
            {blogs.length &&
              blogs?.map((el, i) => <MarketindCard key={i} el={el} i={i} />)}
            <div className="hidden tab:flex justify-center items-center sm:mt-2">
              <button
                onClick={() => navigate("blogs")}
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
                className="w-28  font-semibold  text-black rounded-full py-2 sm:py-[5px] border-[2px] border-black !transition-all !duration-200 !ease-linear hover:bg-black hover:text-white">
                See more
              </button>
            </div>
          </div>
          <div className="hidden sm:flex justify-center items-center sm:mt-2">
            <button
              onClick={() => navigate("/blogs")}
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              className="w-28 font-semibold bg-black  text-white rounded-full py-2 sm:py-[5px] border-[2px] border-black !transition-all !duration-200 !ease-linear hover:bg-white hover:text-black">
              See more
            </button>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default StrategicBusinessGrowth;

const MarketindCard = ({ el, i }) => {
  return (
    <div
      data-aos-offset="100"
      data-aos="fade-right"
      data-aos-duration="1000"
      data-aos-delay={100 * (marketingCardData.length - i)}
      className="sm:w-full flex flex-col gap-y-6 sm:gap-y-2 bg-white rounded-2xl p-8 sm:p-6">
      <div className="flex justify-between items-center">
        <div
          className={`w-[62%] lg:w-[55%] ${
            (i === 0 && "bg-[#ffc900]") ||
            (i === 1 && "bg-[#FF7B00]") ||
            (i === 2 && "bg-[#00ff1a]")
          } h-2 sm:h-[6px] rounded-full`}></div>
        <div className=" sm:text-[14px] text-[#797979]">
          <Moment format="D MMM YYYY" withTitle>
            {el.createdAt}
          </Moment>
        </div>
      </div>
      <div className="sm:mt-1 2xl:h-[6.5rem] lg:h-[4.4rem]">
        <h2 className="text-[28px] leading-9 lg:leading-6 xl:text-[26px] lg:text-xl tab:text-xl sm:text-xl sm:leading-6 font-semibold line-clamp-3">
          {el.title}
        </h2>
      </div>
      <div className="flex justify-between gap-2 sm:flex-col">
        <div className="w-[78%] sm:w-full">
          <p className="text-lg tab:text-[14px] sm:text-[15px] sm:leading-normal text-[#797979] line-clamp-3 sm:line-clamp-2">
            {el.metadesc}
          </p>
        </div>
        <Link to={`/blog-detail/${el._id}`} className=" self-end">
          <div
            className={`w-24 tab:w-12 sm:w-16 h-[3.2rem] tab:h-6 sm:h-7 flex self-end sm:self-start justify-center items-center rounded-full sm:mt-4 ${
              (i === 0 && "bg-[#ffc900]") ||
              (i === 1 && "bg-[#FF7B00]") ||
              (i === 2 && "bg-[#00ff1a]")
            } `}>
            <FaArrowRightLong className="text-xl tab:text-base sm:text-[18px]" />
          </div>
        </Link>
      </div>
    </div>
  );
};
