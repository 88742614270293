import React from "react";

export const News = () => {
  return (
    <div
      className="shadow-md"
      style={{
        background:
          "radial-gradient(at 10% 90%, rgb(255,246,214) 20%, rgba(251,251,251) 80%, rgba(251,251,251) 40%)",
      }}>
      <div className="w-10/12 lg:w-[85%] m-auto  py-10 flex justify-between gap-7 items-center tab:flex-col sm:flex-col sm:w-11/12 sm:py-6">
        <div className="w-6/12 text-left flex flex-col gap-4 sm:text-center tab:w-full sm:w-full sm:gap-2">
          <p className="text-6xl font-bold tab:text-5xl sm:text-3xl">
            Read <span className="text-yellow-400">Our</span>
          </p>
          <p className="text-6xl font-bold tab:text-5xl sm:text-3xl">
            News &<span className="text-yellow-400"> Article</span>
          </p>
          <p className=" text-2xl font-medium leading-7 text-gray-700 sm:text-lg sm:leading-6">
            We remain current on the most recent developments and knowledge in the field of cybersecurity through our exploration of the informative news and articles available.
          </p>
          <div className="w-36 mt-4 mb-4 border-4 border-yellow-400 border-solid sm:hidden"></div>
        </div>
        <div className="flex sm:flex-col gap-10 justify-between tab:w-full tab:justify-around">
          <div
            className="w-[16.6rem] flex flex-col text-left p-4 rounded-3xl shadow-xl sm:w-full sm:flex-row sm:gap-2 sm:p-2  bg-white"
          >
            <img
              className="w-full sm:w-5/12 aspect-[1.5] object-cover rounded-lg"
              src="https://miro.medium.com/v2/resize:fit:828/format:webp/1*oT1Uadw9D4wLJU5JJKPe5A.jpeg"
              alt="codingcomputerimage"
              loading='lazy'
            />
            <div className="flex flex-col sm:w-6/12">
              <p className=" mt-2 font-bold">
                GBJ BUZZ: Revolutionizing Cybersecurity for Organizations </p>
              <p className="text-[14px] font-semibold text-yellow-500">
                June 8, 2023              </p>
              {/* <p className="font-bold">252 Comments</p> */}
              <p className=" text-base font-medium leading-5 text-gray-700 sm:hidden">
                In the ever-evolving landscape of cybersecurity, organizations are constantly seeking innovative solutions to safeguard their digital assets and protect against malicious attacks.
              </p>
              <a className="text-yellow-500 font-semibold sm:hidden"
                target="_blank"
                href="https://medium.com/@mr.gbjbuzz/gbj-buzz-revolutionizing-cybersecurity-for-organizations-d3ce6c122fe0">
                Read More
              </a>
            </div>
          </div>
          <div
            className="w-[16.6rem] flex flex-col text-left p-4 rounded-3xl shadow-xl sm:w-full sm:flex-row sm:gap-2 sm:p-2  bg-white"
          >
            <img
              className="w-full sm:w-5/12 aspect-[1.5] object-cover rounded-lg"
              src='https://miro.medium.com/v2/resize:fit:828/format:webp/1*cK94LOCT3RtfghMOwlx9Sw.jpeg'
              alt="codingcomputerimage"
              loading='lazy'
            />
            <div className="flex flex-col sm:w-6/12">
              <p className=" mt-2 font-bold">
                A Step-by-Step Guide to Implementing GBJ BUZZ for Robust Cyber Defense
              </p>
              <p className="text-[14px] font-semibold text-yellow-500">
                June 10, 2023
              </p>
              {/* <p className="font-bold">252 Comments</p> */}
              <p className=" text-base font-medium leading-5 text-gray-700 sm:hidden">
                In an era where cybersecurity threats continue to evolve, organizations need comprehensive solutions to protect their digital infrastructure.
              </p>
              <a className="text-yellow-500 font-semibold sm:hidden" target="_blank"
                href="https://medium.com/@mr.gbjbuzz/gbj-buzz-revolutionizing-cybersecurity-for-organizations-d3ce6c122fe0">
                Read More
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};
