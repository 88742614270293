import React from "react";

import ClientMarquee from "../../UniversalComponent/ClientMarquee/ClientMarquee";
import WhiteStrip from "../../../Assets/SVG_Images/AboutUs/WhiteStrip.svg";



const Clients = () => {
  const container = {
    position: "relative",
  };

  const text = {
    color: "rgb(94,95,94)",
    textAlign: "center",
    fontWeight: "700",
    fontSize: "6vw",
    fontFamily: "Montserrat",
    position: 'relative'
  };

  return (
    <div style={container} className="m-auto bg-white">
      <h1 style={text}>Our Clients</h1>

      <ClientMarquee />
      <div className=" flex justify-center items-center h-full" id="absoluteImage">

      </div>

    </div >
  );
};

export default Clients;
