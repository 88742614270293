import React from 'react'
import { Typewriter } from 'react-simple-typewriter';
import Libex from '../../../../Assets/HeroSection/LibexBuzz.svg'


export const Herosection = () => {


  return (

    <div className='py-10 shadow-md' style={{
      background:
        "radial-gradient(at 90% 6%, rgba(255,246,214) 20%, rgba(251,251,251) 56%, rgba(251,251,251) 60%)",
    }}>
      <div className='w-10/12 tab:w-11/12 sm:w-11/12 m-auto flex justify-between sm:flex-col-reverse gap-4'
        data-aos='fade-up'
        data-aos-duration='500'
        data-aos-offset='100'
      >
        <div className='flex flex-col gap-4 tab:gap-3 sm:gap-2 text-5xl lg:text-[43px] tab:text-3xl sm:text-2xl font-bold'>
          <h1>
            <Typewriter
              words={['Libex Buzz', 'Edtech Training']}
              loop={false}
              cursor
              cursorStyle='_'
              typeSpeed={120}
              deleteSpeed={80}
            />
          </h1>
          <h2>
            <span className='text-gray-400'>Unlock</span>  Your Potential
          </h2>
          <h2>
            <span className='text-gray-400'>Unleash</span>   Success
          </h2>
          <h2>
            Through Learning
          </h2>
          <h2 className='text-gray-400'>
            Level Up Your Skills
          </h2>
        </div>
        <div className='w-5/12  sm:w-full'>
          <img className='tab:w-[370px] sm:w-11/12 sm:m-auto' src={Libex} alt="libex-image" loading='lazy' />
        </div>
      </div>
    </div>
  )
}
