import React from 'react'
import { Typewriter } from 'react-simple-typewriter'

import BlockXHeroImg from '../../../../Assets/HeroSection/BlockXHero.svg'
import GetQuoteButton from '../../GetQuoteButton/GetQuoteButton'

const HeroSection = () => {
    return (
        <div className='mb-2 shadow-md py-8' style={{
            background:
                "radial-gradient(at 90% 6%, rgba(255,246,214) 20%, rgba(255,255,255) 56%, rgba(255,255,255) 60%)",
        }}>
            <div className='w-10/12 m-auto  tab:w-11/12 sm:w-11/12 flex justify-evenly items-center sm:flex-col-reverse'
                data-aos='fade-up'
                data-aos-duration='500'
                data-aos-offset='100'
            >
                <div className='text-5xl font-bold text-gray-400 flex flex-col gap-2 tab:text-3xl sm:text-2xl'>
                    <h1 className='text-black'>
                        <Typewriter
                            words={['Block X Buzz', 'Blockchain Service']}
                            loop={false}
                            cursor
                            cursorStyle='_'
                            typeSpeed={120}
                            deleteSpeed={80}
                        />
                    </h1>
                    <h2>
                        <span className='text-black'>Fueling</span> Business
                    </h2>
                    <h2>
                        Growth <span className='text-black'>through</span>
                    </h2>
                    <h2>
                        Blockchain Solutions
                    </h2>
                    <p className='text-lg text-black font-normal my-4 tab:text-sm sm:text-base sm:my-0 sm:mt-1'>
                        Experience seamless innovation as we craft cutting-edge blockchain solutions
                    </p>
                    <GetQuoteButton text={'Get Quote'} />
                </div>
                <div className='w-5/12 lg:w-1/2 tab:w-1/2 sm:w-full sm:mb-6'>
                    <img src={BlockXHeroImg} alt="BlockXHeroImg" loading='lazy' />
                </div>
            </div>
        </div>
    )
}

export default HeroSection