import React, { useRef } from 'react'
import linebg2 from "../../../Assets/linedbg2.png";
import linebg3 from "../../../Assets/linedBgBlack3.png";
import { Link } from 'react-router-dom';
import { BsArrowUpRight } from "react-icons/bs";

export const RequestButton = ({ setToggle, toggle }) => {
    const element = useRef();

    const handleMouseEnter = () => {

        const length = window.screen.width

        if (length > 1024) {
            element.current.classList.add("translate-x-[-9rem]");
            element.current.classList.remove("-translate-x-full");
        } else if (length <= 1024 && length >= 600) {
            element.current.classList.add("translate-x-[-7.5rem]");
            element.current.classList.remove("-translate-x-full");
        }


    }
    const handleMouseLeave = () => {
        const length = window.screen.width
        console.log(length)

        if (length > 1024) {

            element.current.classList.remove("translate-x-[-9rem]");
            element.current.classList.add("-translate-x-full");
        } else if (length <= 1024 && length >= 600) {
            element.current.classList.remove("translate-x-[-7.5rem]");
            element.current.classList.add("-translate-x-full");
        }
    }

    return (
        <div
            className={'mx-auto flex justify-center items-center '}>
            {/* button component */}

            <div
                className="flex shrink-0 justify-center gap-0 relative w-[160px] h-[44px] sm:w-[110px] sm:h-[36px] bg-[#ffc900] hover:bg-white transition-all duration-200 ease-linear rounded-full  shadow-[0px_1px_3px_rgba(0,0,0,0.25)] overflow-hidden tab:w-[120px] tab:h-[34px]"
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                onClick={() => setToggle(true)}
            >
                {/* <span
                    ref={element}
                    className={` absolute w-56  sm:w-48 tab:w-48 -top-1  -translate-x-full z-10 transition-all duration-500 ease-in p-0 `}
                >
                    <img src={linebg2} className='block ' alt="backgroundLine" />
                </span> */}

                <Link
                    to={"/contact"}
                    className={`relative flex justify-evenly text-black items-center py-4  px-5  bg-transparent  rounded-lg font-semibold sm:font-bold w-full h-full text-base sm:px-0 sm:ps-0 sm:text-[14px] tab:px-1 tab:ps-1 z-50`}>
                    Get Quote
                </Link>
            </div>
            {/* button component ended */}
        </div>
    )
}
