import React, { useState, useEffect } from 'react';
import { GoArrowRight, GoArrowLeft } from "react-icons/go";
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";
import './ClientCarousel.css';

// import Kaizer from '../../../../Assets/person/Clients/CEOKaizer.svg'
import hoora from '../../../../Assets/clients/bwImge/hoora-logo.svg'
import dudheClinic from '../../../../Assets/clients/bwImge/dudhe-clinic-nagpur.svg'
import myfitMeals from '../../../../Assets/clients/bwImge/my-fit-meals.svg'
import bliss from '../../../../Assets/clients/bwImge/the-bliss-events-nagpur.svg'
import wellness from '../../../../Assets/clients/bwImge/wellness-gym.svg'
// import Kaizer from '../../../../Assets/person/Clients/CEOKaizer.svg'
import YashwantImg from '../../../../Assets/clients/ClientProfileImages/client-Yashwant_Budhwani.png';
import VijayImg from '../../../../Assets/clients/ClientProfileImages/client-Vijay_Barapatre.png';
import AmarImg from '../../../../Assets/clients/ClientProfileImages/client-Amar_Sondawale.png';
import NitinImg from '../../../../Assets/clients/ClientProfileImages/clint-nitin_dudhe.png';
import Sanketimg from '../../../../Assets/clients/ClientProfileImages/clint-sanket.png';
import { Link } from 'react-router-dom';



const swiperArray = [
  {
    feedback: "“A reliable partner in our business development, Their team's dedication to understanding our business and delivering unique solutions sets them apart”",
    img: YashwantImg,
    name: "Yashwant Budhwani",
    designation: "CEO & Founder, Hoora",
    clientImg: hoora,
    link:"https://hoora.in/"
  },
  {
    feedback: "“Results-driven professionals! The team consistently analyzes data, making strategic adjustments that keep our marketing campaigns effective.”",
    img: VijayImg,
    name: "Vijay Barapatre",
    designation: "CEO & Founder, The Bliss Events",
    clientImg: bliss,
    link:"https://theblissevents.in/"

  },
  {
    feedback: "“Our social media presence has never been stronger. The team's social media marketing strategies are on point, engaging our audience effectively.”",
    img: AmarImg,
    name: "Amar Sondawale",
    designation: "CEO, MyFitMeals",
    clientImg: myfitMeals,
    link:"https://myfitmealsindia.com/"

  },
  // {
  //   feedback: "“Partnering with this agency has been transformative. Their comprehensive business development strategies and IT solutions significantly boosted our online presence and operational efficiency.”",
  //   img: NitinImg,
  //   name: "Nitin Dudhe",
  //   designation: "Paediatrician & Owner, Dudhe Multispeciality Clinic",
  //   clientImg: dudheClinic,
  //   link:"https://dudhemultispecialityclinic.com/"

  // },
  // {
  //   feedback: "“As the owner of our gym, I am thrilled with the results achieved through our collaboration with this stellar digital marketing agency. Their efforts have been instrumental in expanding our gym's reach and fostering a stronger connection with our fitness community",
  //   img: Sanketimg,
  //   name: "Sanket Gobade",
  //   designation: "Owner, Wellness Gym Club",
  //   clientImg: wellness,
  //   link:"#"

  // },
]


const ClientCarousel = ({ autoplayInterval = 4000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % swiperArray.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + swiperArray.length) % swiperArray.length);
  };
  useEffect(() => {
    const autoplayTimer = setInterval(() => {
      nextSlide();
    }, autoplayInterval);

    return () => {
      clearInterval(autoplayTimer);
    };
  }, [currentIndex, autoplayInterval]);

  return (
    <div className="swiper-container">
      <button className="hidden absolute h-8 w-8 sm:flex items-center justify-center top-[18px] left-5 text-black bg-[#ffc900] rounded-full z-[100]" onClick={prevSlide}>
        <HiArrowLeft size={20} />
      </button>
      <div className="swiper-track" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {swiperArray.map((ele, index) => (
          <div key={index} className="swiper-slide">
            <div className="w-11/12 mx-auto sm:w-10/12 sm:pb-0">
              <div className='h-28 tab:h-32 flex items-center justify-center gap-x-4'>
                <div className='w-72'>
                  <div data-aos-duration="1000" className={`${index === 0 && "sm:py-5"} flex items-center self-center sm:mt-4 sm:mb-10 sm:block w-52 tab:w-40 sm:w-44 mx-auto `}>
                    <img src={ele.clientImg} alt={ele.name} className="" />
                  </div>
                </div>

                <div className='sm:hidden self-center'>
                  <p data-aos-duration="1000" className="text-left sm:text-left tab:text-[15px] sm:text-xs sm:leading-normal font-semibold sm:h-20 ">{ele.feedback}</p>
                </div>
              </div>

              <div className='hidden sm:block'>
                <p data-aos-duration="1000" className="text-left sm:text-left tab:text-[15px] sm:text-xs sm:leading-normal font-semibold h-12 lg:h-[4.5rem] tab:h-20 sm:h-20 ">{ele.feedback}</p>
              </div>
              <div data-aos-duration="1000" data-aos-delay="300" className="flex items-center gap-x-2 mt-7 tab:mt-4">
                <div>
                  <img src={ele.img} alt={ele.name} className="!w-20 !h-20 tab:!w-16 tab:!h-16 sm:!w-16 sm:!h-16  rounded-full" />
                </div>
                <div className="text-left ">
                  <p className="font-semibold sm:text-sm">{ele.name}</p>
                  <Link to={ele.link} target='_blank' className="text-sm text-[#AEAEAE] sm:text-xs">{ele.designation}</Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button className="hidden absolute h-8 w-8 sm:flex items-center justify-center top-[18px] right-5 text-black bg-[#ffc900] rounded-full z-[100]" onClick={nextSlide}>
        <HiArrowRight size={20} />
      </button>
      <div data-aos-duration="1000" data-aos-delay="300" className='flex sm:hidden gap-x-6 items-center absolute bottom-6 tab:bottom-4 right-28 tab:right-4 z-[100]'>
        <button className=" text-black bg-[#ffc900] py-3 px-6 tab:py-2 tab:px-4  rounded-full" onClick={prevSlide}>
          <HiArrowLeft size={22} />

        </button>
        <h4 className='font-semibold w-12'>0{currentIndex + 1}<span className='text-[#AEAEAE]'>/0{swiperArray.length}</span></h4>
        <button className=" bg-black text-[#ffc900] py-3 px-6 tab:py-2 tab:px-4 rounded-full" onClick={nextSlide}>
          <HiArrowRight size={22} />
        </button>
      </div>
    </div>
  );
};

export default ClientCarousel;

