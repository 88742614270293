import React, { useRef, useState } from 'react'
import style from "./CardComponent.module.css";
import { useEffect } from 'react';
export const CardComponent = ({
  title,
  img,
  desc,
  features,
  learnMore,
  bg,
  bor,
  featureSize,
  descColor,
  id
}) => {

  const imgEle = useRef()
  const descEle = useRef()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update windowWidth state
    const handleResize = () => setWindowWidth(window.innerWidth);

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      data-aos='zoom-out-up'
      data-aos-duration='500'
      data-aos-offset='300'
      data-aos-delay={`${id * 1}00`}
      className={style.cardMainContainer}
      style={{
        background: bg,
        border: bor,
      }}
      onMouseEnter={() => {
        imgEle.current.classList.add('-translate-y-80')
        descEle.current.classList.remove('translate-y-80')
      }}
      onMouseLeave={() => {
        imgEle.current.classList.remove('-translate-y-80')
        descEle.current.classList.add('translate-y-80')
      }}

    >
      <div ref={imgEle} className={style.imgContainer}>
        <p style={{ fontSize: `${windowWidth < 600 ? "12px" : "16px"}`, fontWeight: "600" }}>{title}</p>
        <img className={style.cardImage} src={img} alt={`safeboximage for ${title}`} />
      </div>
      <div ref={descEle} className='absolute left-1 p-4 sm:p-2 transition-all duration-300 ease-in translate-y-80'>
        {
          desc ? (
            <p style={{ fontSize: `${windowWidth < 600 ? "10px" : "14px"}`, fontWeight: "500", color: descColor }}>
              {desc}
              {learnMore ? (
                <button className="text-yellow-400 ml-1">Learn More</button>
              ) : (
                ""
              )}
            </p>
          )
            :
            (
              <ul className={style.list}>
                {
                  features?.map((el, i) => (
                    <li key={i} style={{ fontSize: `${windowWidth < 600 ? "10px" : "14px"}` }}>{el}</li>
                  ))
                }
              </ul>

            )
        }
      </div>
    </div>
  );
};
