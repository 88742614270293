import React from "react";
import Password2Img from "../../../Assets/Images/CyberSecurity/Password2.png";

export const Services = () => {
  return (
    <>
      <div className="hidden tab:block tab:w-10/12 tab:mt-20 w-11/12 m-auto  sm:block sm:mt-12">
        <p className="tab:text-5xl text-left font-bold sm:text-center sm:text-3xl">
          Our <span className="text-yellow-400">Aim is </span> to deliver
          <span className="text-yellow-400"> Top-Notch </span> Services
        </p>
      </div>

      <div className="w-9/12 lg:w-[85%] tab:w-10/12 m-auto py-10 flex justify-between sm:w-11/12 sm:py-2 sm:items-align sm:gap-0">
        <div className="sm:flex sm:items-center">
          <img
            className="w-96 sm:w-52"
            src={Password2Img}
            alt="password2image"
            loading='lazy'
          />
        </div>
        <div className="w-9/12 text-left flex flex-col gap-6 sm:gap-2">
          <p className="text-7xl font-bold lg:text-6xl tab:hidden sm:hidden">
            Our <span className="text-yellow-400">Aim is </span> To Deliver
            <span className="text-yellow-400"> Top-Notch </span> Services
          </p>
          <p className=" text-xl font-medium leading-8 text-gray-600 sm:text-sm sm:leading-4">
            Bolstering your business with resilient cybersecurity measures, our skilled team stands guard against potential risks, ensures the protection of valuable data, and diligently preserves your online presence.
          </p>
          <div className="w-36 mt-4 mb-4 border-4 border-yellow-400 border-solid sm:w-20 sm:mt-2 sm:border-2"></div>
        </div>
      </div>
    </>
  );
};
