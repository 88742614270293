import React, { useEffect } from 'react'

import Herosection from '../Components/ServicesPages/7iBuzz/HeroSection/Herosection'
import AiConsulting from '../Components/ServicesPages/7iBuzz/AiConsulting/AiConsulting'
import ProductDev from '../Components/ServicesPages/7iBuzz/ProductDev/ProductDev'
import { Outlet } from '../Components/ServicesPages/7iBuzz/Outlet/Outlet'
import { TechnologyStack } from '../Components/ServicesPages/7iBuzz/TechnologyStack/TechnologyStack'
import { OurServices } from '../Components/ServicesPages/7iBuzz/OurServices/OurServices'
import { WeAreHereToInnovate } from '../Components/ServicesPages/7iBuzz/WeAreHereToInnovate/WeAreHereToInnovate'
import { GetReady } from '../Components/GetReady/GetReady'
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet'
ReactGA.initialize(process.env.REACT_APP_GA_KEY);

const Buzz7i = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "7i Buzz page" });
        window.scroll(0, 0)
    }, [])
    return (
        <>
            <Helmet>
                <title>7iBuzz - AI and Machine Learning Solutions</title>
                <meta name="description" content="Explore the power of AI and machine learning through our innovative solutions. From predictive analytics to AI-driven applications, we're committed to driving your business forward with intelligent technology" />
            </Helmet>
            <div className="max-w-[1700px] m-auto">
                <Herosection />
                <OurServices />
                <WeAreHereToInnovate />
                <AiConsulting />
                <Outlet />
                <ProductDev />
                <TechnologyStack />
            </div>
            <GetReady />
        </>
    )
}

export default Buzz7i