import React from 'react'
import { Typewriter } from 'react-simple-typewriter'

import GetQuoteButton from '../../GetQuoteButton/GetQuoteButton'
import BuzzHeroSection from '../../../../Assets/HeroSection/7iBuzzHerosection.svg'

const Herosection = () => {
    return (
        <div className='mb-2 shadow-md py-8' style={{
            background:
                "radial-gradient(at 90% 6%, rgba(255,246,214) 20%, rgba(255,255,255) 56%, rgba(255,255,255) 60%)",
        }}>
            <div className='w-10/12 m-auto  tab:w-11/12 sm:w-11/12 flex justify-between items-center sm:flex-col-reverse'
                data-aos='fade-up'
                data-aos-duration='500'
                data-aos-offset='100'
            >
                <div className='text-5xl font-bold flex flex-col gap-2 lg:text-3xl tab:text-3xl sm:text-2xl'>
                    <h1>
                        <Typewriter
                            words={['7i Buzz', 'Business Development', 'Saas Product Development', 'Paas Product development']}
                            loop={false}
                            cursor
                            cursorStyle='_'
                            typeSpeed={120}
                            deleteSpeed={80}
                        />
                    </h1>
                    <h2 className='text-gray-400'>
                        Empowering Business
                    </h2>
                    <h2 className='text-gray-400'>
                        Growth with Advanced
                    </h2>
                    <h2>
                        Generative AI
                    </h2>
                    <p className='text-lg text-black font-normal my-4 tab:text-sm sm:text-base sm:my-0 sm:mt-1'>
                        Experience seamless innovation as we pioneer cutting-edge AI solutions.
                    </p>
                    <GetQuoteButton text={'Get Quote'} />
                </div>
                <div className='w-[30%] lg:w-1/2 tab:w-1/2 sm:w-8/12 sm:mb-6'>
                    <img src={BuzzHeroSection} alt="7iHeroSectionImg" loading='lazy' />
                </div>
            </div>
        </div>
    )
}

export default Herosection