import React, { useRef } from 'react'
import StarImg from '../../../../Assets/CardImages/Libex/Star.png'
import EffortImg from '../../../../Assets/CardImages/Libex/Effort.png'
import SandWatchImg from '../../../../Assets/CardImages/Libex/Sand Watch.png'
import CustomerSuppImg from '../../../../Assets/CardImages/Libex/Customer Support.png'
import buttonLine from "../../../../Assets/Images/CyberSecurity/buttonline.png";
import { useNavigate } from 'react-router-dom'


const cardData = [
    {
        title: "UI/UX"
    },
    {
        title: "Web Devlopment"
    }, {
        title: "Data analytics"
    },
]
export const ImproveKnowledge = () => {

    return (
        <div style={{
            background: 'radial-gradient(at -5% 50%, rgba(255, 209, 35, 0.20), rgba(255, 255,255)',
        }}>

            <div className='w-10/12 sm:w-11/12 m-auto py-6'   >
                <div className='text-center mb-10 sm:mb-6'>
                    <p className='text-[44px]  tab:text-3xl sm:text-3xl font-bold text-gray-400 leading-[70px] lg:leading-[50px]'><span className='text-black'>Improve</span>  Knowledge <span className='text-black'>with</span> Best Courses</p>
                </div>
                <div className='flex flex-col gap-10'>
                    {
                        cardData?.map((el, i) => (
                            <ImproveKnowledgeCard key={i} title={el.title} />
                        ))

                    }
                </div>
            </div>
        </div>
    )
}

const ImproveKnowledgeCard = ({ title }) => {
    const SubscribeButton = useRef();
    const navigate = useNavigate()

    return (
        <div className='flex w-full sm:w-11/12 m-auto px-12 lg:px-6 sm:px-6 py-3 sm:py-6 tab:flex-wrap sm:flex-col gap-y-4 bg-[#FFC900] justify-between rounded-[70px] tab:rounded-3xl sm:rounded-3xl border-4 border-[#DBDBDB] border-solid'>
            <div className='w-60 lg:w-52 sm:m-auto flex flex-col items-center justify-center'>
                <p className='text-2xl font-bold lg:text-[20px]'>{title}</p>
                <p className='text-[15px]'>Rating : 4000+ students</p>
                <div className='flex gap-1 items-center justify-center'>
                    <p className='text-[15px]'>(4)</p>
                    <img className='w-4' src={StarImg} alt="star" loading='lazy' />
                    <img className='w-4' src={StarImg} alt="star" loading='lazy' />
                    <img className='w-4' src={StarImg} alt="star" loading='lazy' />
                    <img className='w-4' src={StarImg} alt="star" loading='lazy' />
                </div>
            </div>
            <div className=' flex gap-2 justify-center items-center'>
                <img className='w-16 h-16' src={EffortImg} alt="effort-img" loading='lazy' />
                <div>
                    <p className='text-[18px] text-[#767070]'>Difficulty </p>
                    <p className='text-[18px] font-semibold'>Advance</p>
                </div>

            </div>
            <div className=' flex gap-2 justify-center items-center'>
                <img className='w-16 h-16' src={SandWatchImg} alt="effort-img" />
                <div>
                    <p className='text-[18px] text-[#767070]'>Learn period</p>
                    <p className='text-[18px] font-semibold'>32 Weeks</p>
                </div>

            </div>
            <div className=' flex gap-2 justify-center items-center'>
                <img className='w-16 h-16' src={CustomerSuppImg} alt="effort-img" loading='lazy' />
                <div>
                    <p className='text-[18px] text-[#767070]'>Language</p>
                    <p className='text-[18px] font-semibold'>English</p>
                </div>

            </div>
            <div className='flex items-center justify-center'>
                <button onClick={() => navigate('/career')}
                    className=" w-48 lg:w-44 sm:w-40 py-6 px-4 sm:py-5 flex justify-around items-center text-[20px] lg:text-[19px] tab:text-[18px] sm:text-[16px] font-bold rounded-3xl bg-white relative overflow-hidden  shadow-lg border-b-4 border-gray-400 border-solid"
                    onMouseEnter={() => {
                        SubscribeButton.current.classList.remove("-translate-x-32");
                    }}
                    onMouseLeave={() => {
                        SubscribeButton.current.classList.add("-translate-x-32");
                    }}
                >
                    <div
                        ref={SubscribeButton}
                        className="flex absolute -translate-x-32   transition-all duration-250 ease-linear">
                        <img
                            className="w-9 h-14 sm:w-8 -mr-4 "
                            src={buttonLine}
                            alt="buttonline"
                            loading='lazy'
                        />
                        <img className="w-9 h-14 sm:w-8" src={buttonLine} alt="buttonline"
                            loading='lazy' />
                    </div>

                    <div className="flex absolute gap-3 items-center font-bold">
                        Register Today
                    </div>
                </button>
            </div>
        </div>
    )

}
