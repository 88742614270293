import React from 'react'
import style from "./OurServices.module.css";
import blockChain from '../../../../Assets/CardImages/BlockXBuzz/blockchain.png'
import smartContact from '../../../../Assets/CardImages/BlockXBuzz/smartContact.png'
import tokenization from '../../../../Assets/CardImages/BlockXBuzz/token.png'
import supply from '../../../../Assets/CardImages/BlockXBuzz/supplyChain.png'
import blockChainCon from '../../../../Assets/CardImages/BlockXBuzz/blockchainCons.png'
import decentralization from '../../../../Assets/CardImages/BlockXBuzz/decentralization.png'
import { CardComponent } from '../../CardComponent/CardComponent';

const cardData = [
    {
        title: "Blockchain Development",
        img: blockChain,
        description:
            "Transform your concepts into tangible results through Block X Services' Blockchain Development. Modernise your enterprise with state-of-the-art, reliable, and effective blockchain solutions expertly designed by our team.",
    },
    {
        title: "Smart Contract Development",
        img: smartContact,
        description:
            "Our specialists develop automated agreements that simplify operations, guarantee openness, and bolster protection. Boost your business productivity by harnessing the capabilities of intelligent contracts.",
    },
    {
        title: "Tokenization Services",
        img: tokenization,
        description:
            "Transform your concepts into actuality through Block X Services' Blockchain Development. Elevate your enterprise by harnessing state-of-the-art, secure, and effective blockchain solutions expertly designed by our team.",
    },
    {
        title: "Supply Chain Solutions",
        img: supply,
        description:
            "Utilizing our blockchain-based strategy guarantees legitimacy, minimizes deception, and streamlines operations. Immerse yourself in a fresh epoch of supply chain administration that elevates confidence and productivity.",
    },
    {
        title: "Blockchain Consulting",
        img: blockChainCon,
        description:
            "Our specialists lead you through strategic perspectives and hands-on measures to leverage this game-changing technology. Gain the knowledge needed to make well-informed choices and unleash creativity using our customised consultation services.",
    },
    {
        title: "Decentralized Applications (DApp)",
        img: decentralization,
        description:
            "Our innovations go beyond conventional applications, providing openness, safeguarding, and user-focused interactions. Embrace the decentralised movement and deliver advanced solutions to your user base.",
    },
]

export const OurServices = () => {

    return (
        <div style={{
            background: 'radial-gradient(at -5% 50%, rgba(255, 209, 35, 0.20), rgba(255, 255,255)'
        }}>
            <div className='w-10/12 sm:w-11/12 m-auto py-8 sm:py-4'>
                <div className="w-48 m-auto mb-4 border-[3px] border-yellow-400 border-solid sm:w-24 sm:border-2 sm:my-6"></div>
                <div className='text-center mb-8 sm:mb-6'>
                    <p className='text-[44px]  tab:text-3xl sm:text-3xl font-bold text-gray-400 leading-[70px] lg:leading-[50px]'><span className='text-black'>Our</span> Services</p>
                    <p className='w-7/12 m-auto sm:w-full'>
                        Fuel your business expansion within the digital realm by harnessing Xpan Buzz's revolutionary digital marketing solutions.
                    </p>
                </div>
                <div className={style.cardMainContainer}>
                    {cardData?.map((el, i) => (
                        <CardComponent
                            title={el.title}
                            img={el.img}
                            desc={el.description}
                            learnMore={el.learnMore}
                            key={i}
                            bg="#fff5cc"
                            bor="none"
                            descColor="rgba(0, 0, 0, 0.7)"
                            id={i}
                        />
                    ))}
                </div>
            </div>
        </div>

    )
}
