import React, { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
// import style from "../Navbar.module.css";
import style from './ServicesLink.module.css'

const serviceSublink = [
  {
    title: "All Services",
    to: "/all-services",
  },
  {
    title: "Marketing",
    to: "/services/xpan"
  },
  {
    title: "AI & Development",
    to: "/services/7i"
  },
  {
    title: "Cyber Security",
    to: "/services/cyber-security",
  },
  {
    title: "Edtech Training",
    to: "/services/libex",
  },

  {
    title: "Blockchain",
    to: "/services/blockx"
  },

];

export const ServicesLink = ({ toggle, setToggle }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [hover, setHover] = useState(false)
  useEffect(() => {
    // Function to update windowWidth state
    const handleResize = () => setWindowWidth(window.innerWidth);

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <>
      {/* ----Desktop Screen view Navbar link--- */}
      <div
        // onMouseLeave={() => setOpen(!open)}
        className={style.desktopViewContainer}>
        <a className={style.serviceContainer}
          onClick={() => setOpen(!open)}>
          <p>Services</p>
          <span>{open ? <IoIosArrowUp /> : <IoIosArrowDown />}</span>
        </a>

        <div onMouseLeave={() => setOpen(!open)}
          className={`${style.desktopDropdownLink} left-0 top-[60px] w-48 p-3 mt-[1px] rounded-lg shadow-md  ${open ? "block" : "hidden"
            }`}>
          {serviceSublink?.map((el, i) => (
            <Link to={el.to}
              key={i}
              className={` text-[12px] flex h-10 items-center px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer`}
              style={{ color: '#313131' }}
            >
              <p style={{ textAlign: "left", fontSize: "14px" }}
                className={style.Content_underline}>{el.title}</p>
            </Link>
          ))}
        </div>
      </div>

      {/* ---Mobile Screen view Navbar link --- */}
      <div
        onClick={() => setOpen(!open)}
        className={style.mobileViewContiner}>
        <a className={style.mobileViewButton}
          onClick={() => setOpen(!open)}>
          <p>Services</p>
          <div>{open ? <IoIosArrowUp /> : <IoIosArrowDown />}</div>
        </a>
        <div
          className={`${open ? "block" : "hidden"
            }`}>
          <div className="block lg:hidden tab:hidden sm:hidden">
            {serviceSublink?.map((el, i) => (
              <div
                key={i}
                className={style.dropDownDiv} onClick={() => {
                  setToggle(!toggle)
                  navigate(el.to)
                }}>
                <Link key={i} className="text-gray-700 font-light text-sm"

                >
                  {el.title}
                </Link>
              </div>
            ))}
          </div>

          <div className="hidden mb-4 lg:flex tab:flex sm:flex  rounded-md p-3 bg-inherit items-end flex-col">
            {serviceSublink?.map((el, i) => (
              <div
                key={i}
                className={`${style.dropDownDiv} flex items-center`}
                onClick={() => {
                  setToggle(!toggle)
                  navigate(el.to)
                }}>
                <Link key={i} className="text-gray-700 font-semibold text-sm"
                >
                  {el.title}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};


// all services GrServices