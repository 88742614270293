import React from "react";
import DesktopRocketImg from "../../../Assets/rocket-and-stars-desktop-view.svg";
import MobileRocketImg from "../../../Assets/rocket-and-stars-mobile-view.svg";

const ReadWithUs = () => {
  return (
    <div className="bg-[#f7f7f7]">
      <div className="max-w-[1600px] m-auto sm:bg-black">
        <div className="sm:hidden w-11/12 m-auto relative">
          <img
            src={DesktopRocketImg}
            alt="rocket image"
            className="w-full tab:h-32"
          />
          <div className="absolute top-8 lg:top-6 tab:top-6 left-12">
            <h2
              data-aos-duration="2000"
              className="text-7xl xl:text-6xl lg:text-5xl tab:text-[34px] font-semibold text-white">
              Ready to work with us?
            </h2>
            <a href="#contact-form">
              <button
                data-aos-duration="2000"
                data-aos-delay="200"
                className="w-40 tab:w-32 py-2 tab:p-1 mt-4 tab:mt-2 lg:mt-3 text-lg tab:text-xs font-semibold bg-[#ffc900] rounded-full hover:bg-white transition-all duration-150 ease-linear">
                Get Quote
              </button>
            </a>
          </div>
        </div>
        <div className="hidden sm:block w-11/12 m-auto relative">
          <img
            src={MobileRocketImg}
            alt="mobile rocket image"
            className="w-full"
          />
          <div className="absolute top-5 left-3">
            <h2
              data-aos-duration="2000"
              className="text-[28px]  font-semibold text-white">
              Read to work with us?
            </h2>
            <a href="#contact-form">
              <button
                data-aos-duration="2000"
                className="w-24 py-1 mt-3 text-xs font-semibold bg-[#ffc900] rounded-full  hover:bg-white transition-all duration-150 ease-linear">
                Get Quote
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadWithUs;
