import React from 'react'

import helpPersonsImg from '../../../../Assets/Illustration/HelpIllustrations.svg'

const cardArray = [
    {
        img: "https://pll.harvard.edu/sites/default/files/styles/16_9_medium/public/course/CS50W_pll.png?itok=pvEE-46J",
        title: "CS50's Web Programming with Python and JavaScript",
        // subTitle: 'Lorem ipsum dolor, sit amet',
        desc: "This course picks up where CS50 leaves off, diving more deeply into the design and implementation of web apps....",
        // person: 2225,
        time: '12 weeks',
        // lesson: '16 lesson',
        href: "https://pll.harvard.edu/course/cs50s-web-programming-python-and-javascript/2023-05"
    },
    {
        img: "https://pll.harvard.edu/sites/default/files/styles/16_9_medium/public/course/stained-glass-1181864.jpg?itok=Hk00xL0R",
        title: "Data Science: Capstone",
        // subTitle: 'Lorem ipsum dolor, sit amet',
        desc: "Show what you’ve learned from the Professional Certificate Program in Data Science.",
        // person: 2225,
        time: '2 weeks',
        // lesson: '16 lesson',
        href: "https://pll.harvard.edu/course/data-science-capstone"
    },
    {
        img: "https://pll.harvard.edu/sites/default/files/styles/16_9_medium/public/course/CS50S_pll.png?itok=b7x2JUsp",
        title: "CS50's Introduction to Programming with Scratch",
        // subTitle: 'Lorem ipsum dolor, sit amet',
        desc: 'A gentle introduction to programming that prepares you for subsequent courses in coding.',
        // person: 2225,
        time: '3 weeks',
        // lesson: '16 lesson',
        href: "https://pll.harvard.edu/course/cs50s-introduction-programming-scratch"
    },
]

const FindFreeCourse = () => {
    return (
        <div style={{
            background: 'radial-gradient(at -5% 50%, rgba(255, 209, 35, 0.20), rgba(255, 255,255)',
        }}>
            <div className='w-10/12 m-auto pt-8 sm:w-11/12'>
                <div className='text-[44px] text-center font-semibold sm:text-3xl tab:text-4xl'>
                    <p>
                        Find a <span className='text-gray-400'> free Course</span>  that fits your <span className='text-gray-400'> domain</span>
                    </p>
                </div>
                <div className='flex justify-evenly gap-4 items-center mt-16 tab:flex-wrap tab:gap-2 sm:flex-wrap'>
                    {
                        cardArray?.map((ele, i) => {
                            return <Card {...ele} key={i} />
                        })
                    }
                </div>
                <div className='flex justify-between items-center gap-8 mt-14 sm:flex-col'>
                    <div className='w-1/2 sm:w-full'>
                        <p className='text-5xl font-bold sm:text-3xl tab:text-3xl'>
                            What <span className='text-yellow-400'> Experience </span>will you get in Libex Buzz
                        </p>
                        <p className='text-lg text-gray-400 mt-4 tab:text-base sm:text-base'>
                            At Libex Buzz, you'll receive comprehensive IT training with expert instructors, personalized learning paths, hands-on projects, industry-recognized certifications, and a supportive community to help you advance your IT career.
                        </p>
                    </div>
                    <div className='w-1/2 sm:w-11/12'>
                        <div>
                            <img src={helpPersonsImg} alt="helpPersonsImg" className='w-11/12 m-auto tab:w-10/12' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FindFreeCourse

const Card = ({ href, img, title, subTitle, desc, person, time, lesson }) => {
    return (
        <a href={href} target='_main' className='w-80 tab:w-72 h-[400px] bg-[#ffd02566] border-4 border-white rounded-3xl shadow-md sm:my-4'>
            <div>
                <img src={img} alt={`${title}`} className='rounded-3xl rounded-b-none' />
            </div>
            <div className='p-3 w-11/12 m-auto pb-10'>
                <p className='text-lg font-semibold'>
                    {title}
                </p>
                <p className='font-semibold mt-2'>
                    {subTitle}
                </p>

                <p className=' border-black mt-2 pb-0'>
                    {desc}
                </p>
            </div>
        </a>
    )
}