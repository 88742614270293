import React, { useRef } from "react";
import { BsArrowUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import Aos from 'aos';
import 'aos/dist/aos.css';

import styles from "../styles.module.css";
import linebg2 from '../../../../Assets/linedbg2.png'

Aos.init()

const MobileCard = ({ heading, subHeading, img, desc, link }) => {
    const element = useRef();
    return (
        <div className='flex flex-col justify-between w-10/12  border shadow-xl p-12 rounded-xl sm:w-full sm:p-4 relative sm:my-2 tab:w-full'
            data-aos='fade-up'
            data-aos-duration='500'
            data-aos-offset='200'
        >
            <div className='sm:text-3xl sm:font-semibold relative '>
                <p>{heading}</p>
            </div>
            <div className=' text-lg font-medium mb-4'>
                <p>{subHeading}</p>
            </div>
            <div className="flex justify-center items-center mb-2">
                <img src={img} alt={`${heading} Image`} className="h-40 w-60" loading='lazy' />
            </div>
            <div className="sm:text-base font-normal mb-4">
                <p>{desc}</p>
            </div>
            <div
                className="flex shrink-0 justify-start relative   bg-[#FFD01F] rounded-xl sm:rounded-lg shadow-2xl overflow-hidden w-[185px] sm:w-[137px] sm:text-xs text-lg font-semibold "
                onMouseEnter={() => {
                    element.current.classList.add("-translate-x-0");
                    element.current.classList.remove("-translate-x-full");
                    element.current.classList.remove("hidden");
                }}
                onMouseLeave={() => {
                    element.current.classList.remove("-translate-x-0");
                    element.current.classList.add("-translate-x-full");
                    element.current.classList.add("hidden");
                }}>
                <span
                    ref={element}
                    className={`${styles.buttons} absolute  -translate-x-full sm:w-28 sm:h-10 h-14 -top-1 transition-all duration-300 ease-linear delay-300`}>
                    <img src={linebg2} alt={`linedBg for ${heading}`} loading='lazy' />
                </span>

                <Link
                    to={link}
                    className={` flex justify-evenly items-center  sm:text-sm rounded-lg font-semibold w-full h-full text-xl  sm:px-4 sm:py-1 shadow-custom2 bg-transparent p-2 z-10 sm:rounded-lg`}>
                    Learn More <BsArrowUpRight />
                </Link>
            </div>
        </div>
    )
}

export default MobileCard