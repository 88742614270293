import React from "react";
import { BsPlusCircle } from "react-icons/bs";
import { LuMinusCircle } from "react-icons/lu";

const AccordionComponent = ({ item, id, active, handleToggle }) => {
  return (
    <div
      className="p-4 transition-all
        duration-300
        ease-in">
      <div
        className={`  p-3 ${active === id ? "" : "border-black border-b-4 "}`}>
        <h2
          onClick={() => handleToggle(id)}
          className="flex flex-row justify-between items-center text-xl sm:text-base font-semibold transition-all
                    duration-300
                    ease-linear">
          {item.question}{" "}
          <div>

            {id === active ? (
              <LuMinusCircle className="w-8 h-8" />
            ) : (
              <BsPlusCircle className="w-8 h-8" />
            )}
          </div>
        </h2>
      </div>
      <div
        className={`transition-all duration-1000 ease-in delay-300
                ${id === active ? " border-black border-b-4" : "hidden"}
                text-gray-400
                text-lg
                mt-2
                m-auto
                text-justify
                sm:text-base
                
            `}>
        <p
          className="w-11/12 m-auto transition-all
                duration-1000
                ease-in pb-5">
          {item.answer}
        </p>
      </div>
    </div>
  );
};

export default AccordionComponent;
