import React from 'react'
import { Typewriter } from 'react-simple-typewriter'

export const GbjLogo = ({ setToggle, sm }) => {
  return (
    <div className='flex items-center' onClick={() => setToggle(true)}>
      <img src={"/GBJlogo.svg"} alt="gbjlogo" className='w-[3.75rem] lg:w-[46px] tab:w-[46px] sm:w-[40px] z-10' />
      <div className={`bg-black w-[122px] lg:w-[100px] tab:w-[100px] sm:w-[90px] flex items-center font-medium gap-[1px] h-[37px] lg:h-7 tab:h-7 sm:h-7 pr-2  pl-1  -ml-2 -z-0 rounded-tr-lg rounded-br-lg sm:py-[0px]  ps-[6px] sm:ps-[8px] ${sm ? "sm:!block" : ""}`}>
        <span className='text-white font-semibold text-2xl lg:text-xl  tab:text-xl sm:text-lg'>GBJ</span>
        <span className='text-[#ffc500] font-semibold text-[22px] lg:text-lg tab:text-lg sm:text-base'>
          <Typewriter
            words={['Buzz']}
            loop={false}
            cursor
            cursorStyle=''
            typeSpeed={160}
            deleteSpeed={100}
          />
        </span>
      </div>
    </div>
  )
}
