import React from "react";
import EmpathyImg from "../../../Assets/SVG_Images/Career/emoji-empathy.jpg.svg";
import CourtesyImg from "../../../Assets/SVG_Images/Career/emoji-courtesy.jpg.svg";
import ThrivingImg from "../../../Assets/SVG_Images/Career/emoji-thriving.jpg.svg";
import CraftmanshipImg from "../../../Assets/SVG_Images/Career/emoji-craftsmenship.jpg.svg";
import PlayfunlessImg from "../../../Assets/SVG_Images/Career/emoji-playfulness.jpg.svg";
import SolidarityImg from "../../../Assets/SVG_Images/Career/emoji-solidarity.jpg.svg";
import gbjBuzzCoreImg from "../../../Assets/SVG_Images/Career/gbj-buzz-core.svg";
const ourCoreValuesData = [
  {
    img: EmpathyImg,
    title: "Empathy",
  },
  {
    img: CraftmanshipImg,
    title: "Craftmanship",
  },
  {
    img: CourtesyImg,
    title: "Courtesy",
  },
  {
    img: PlayfunlessImg,
    title: "Playfunless",
  },
  {
    img: ThrivingImg,
    title: "Thriving",
  },
  {
    img: SolidarityImg,
    title: "Solidarity",
  },
];
export const OurCoreValues = () => {
  return (
    <div data-aos="fade-right" data-aos-duration="2000" data-aos-offset="200" className="w-9/12 m-auto flex sm:flex-col justify-between tab:gap-4 tab:w-10/12 sm:w-11/12">
      <div className="w-6/12 flex flex-col gap-2 tab:w-6/12 sm:w-full sm:gap-2">
        <p  className="text-3xl font-bold sm:text-2xl">Our core values</p>
        <p className="tab:text-[15px]">
          These are some of the values that we live by as a company. We work by
          them, too. We’re building a platform and products that we believe in,
          knowing that there is real value to be gained from helping people to
          simplify whatever it is that they do and bring more of themselves to
          their work, wherever they are.
        </p>
        <div className="grid grid-cols-2 col-auto justify-between gap-x-44 tab:gap-x-6 gap-y-2 sm:flex sm:flex-col">
          {ourCoreValuesData?.map((el, i) => (
            <div key={i} className="flex gap-2 items-center">
              <img src={el.img} alt={el.title} />
              <p className="text-lg font-bold  tab:text-base sm:text-base">{el.title}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="w-5/12 tab:w-5/12 m-auto sm:w-full">
        <img src={gbjBuzzCoreImg} alt="gbj-buzz-core" />
      </div>
    </div>
  );
};
