import React, { useRef, useState } from "react";
import buttonLine from "../../Assets/Images/LandingPage/buttonline.png";
import EllipseImg from "../../Assets/Images/LandingPage/EllipseHalf.png";
import Ellipse1Img from "../../Assets/Images/LandingPage/EllipseQuater.png";
import DecoratorImg from "../../Assets/Images/LandingPage/decorator.png";
import supabase from "../../SupabaseConfig/SupabaseClient";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const GetReady = () => {
  const element = useRef();
  const [emailId, setEmailId] = useState('')

  const handleSubmitMail = async (e) => {
    e.preventDefault()
    console.log(emailId)

    try {
      const { data, error } = await supabase
        .from('signUpMailRecord')
        .insert([{ emailId }])
      if (error) {
        toast.error("Something went wrong!")
      }
      else {
        toast.success("Your Request Sent Successfully!")
        setEmailId("")
      }
    }
    catch (error) {
      console.log(error)
      toast.error("Server Error!")
    }
  }

  return (

    <div className=" bg-gray-100 relative">
      <div className="max-w-[1700px] m-auto py-16  sm:py-0 sm:pb-8 ">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <div className="w-3/6 m-auto text-center tab:w-8/12 sm:w-10/12 z-50">
          <p className="relative m-auto z-50 text-4xl font-semibold text-gray-700  sm:top-4 sm:text-2xl">
            Ready to get started?
          </p>
          <p className="text-2xl mt-4 font-normal text-gray-600 sm:text-sm sm:mt-6">
            Supercharge Your Business with GBJ BUZZ's Digital Marketing, AI, and
            Cybersecurity Services!
          </p>
          <div className="flex justify-center mt-6 sm:mt-2">
            <form method="GET" className="tab:w-full sm:w-full" onSubmit={handleSubmitMail}>
              <div className="w-full flex items-center justify-between text-gray-500 bg-gray-200 rounded-lg focus-within:text-gray-500 border border-gray-500 border-solid">
                <input
                  type="email"
                  name="q"
                  className="w-96 tab:w-[90%] sm:w-[90%] py-2 pl-2 sm:py-1 text-base sm:text-[13px] text-black rounded-lg focus:outline-none rounded-r-none"
                  placeholder="Enter your email address..."
                  autoComplete="off"
                  required
                  value={emailId}
                  onChange={(e) => setEmailId(e.target.value)}
                />
                <button
                  className=" w-32 -ml-2 h-10 sm:h-8 sm:text-sm flex justify-around items-center px-2.5 text-base font-bold rounded-lg bg-[#FFC900] relative sm:w-28 sm:font-medium overflow-hidden"
                  onMouseEnter={() => {
                    element.current.classList.remove("-translate-x-full");
                  }}
                  onMouseLeave={() => {
                    element.current.classList.add("-translate-x-full");
                  }} type='submit'>
                  <div
                    ref={element}
                    className="flex absolute left-10 -translate-x-full w-full transition-all duration-300 ease-in">
                    <img
                      className="h-10 sm:h-8 -mr-3"
                      src={buttonLine}
                      alt="buttonline"
                      loading='lazy'
                    />
                    <img className="h-10 sm:h-8" src={buttonLine} alt="buttonline"
                      loading='lazy' />
                  </div>
                  <div className="flex absolute text-lg sm:text-[15px] text-gray-700">
                    <p>Sign Up</p>
                  </div>
                </button>
              </div>
            </form>
          </div>

        </div>

      </div>
      <img
        className="w-44 absolute sm:w-[114px] sm:h-44 top-[26px] left-[10px] sm:top-[37px] sm:left-[-4px] z-0"
        src={DecoratorImg}
        alt="decoratorimage"
        loading='lazy'
      />
      <img
        className="w-32 tab:w-28 absolute top-0 right-0 z-0 sm:w-12"
        src={EllipseImg}
        alt="decoratorimage"
        loading='lazy'
      />
      <img
        className="w-64 tab:w-52 absolute top-0 right-0 sm:absolute z-0 sm:w-24 "
        src={Ellipse1Img}
        alt="decoratorimage"
        loading='lazy'
      />
    </div>

  );
};
