import React, { useState, useEffect } from "react";
import FileImg from "../../../Assets/CardImages/CyberSecurity/File.png";
import PasswordImg from "../../../Assets/CardImages/CyberSecurity/Password.png";
import LaptopImg from "../../../Assets/CardImages/CyberSecurity/Laptop.png";
import NetworkImg from "../../../Assets/CardImages/CyberSecurity/Network.png";
import CloudImg from "../../../Assets/CardImages/CyberSecurity/Cloud.png";
import { Card } from "../../UniversalComponent/Card/Card";
import style from "./OurExperties.module.css";

const ourExpertiseCardData = [
  {
    title: "Manage Endpoint Security",
    img: FileImg,
    description:
      "Safeguard your hardware against evolving online dangers using our all-encompassing endpoint security management offerings.",
    learnMore: true,
  },
  {
    title: "IT Audit & Compliance",
    img: PasswordImg,
    description:
      "Enhance security and mitigate risks by leveraging IT audits and expert guidance to meet regulatory compliance requirements.",
    learnMore: true,
  },
  {
    title: "Manage Endpoint Security",
    img: FileImg,
    description:
      "Assume command over endpoint security through our efficient management tools, guaranteeing preemptive safeguarding against online threats.",
    learnMore: true,
  },
  {
    title: "Infrastructure Security",
    img: LaptopImg,
    description:
      "Strengthen your infrastructure by implementing strong security measures, protecting against potential threats, and ensuring the integrity of your system.",
    learnMore: true,
  },
  {
    title: "Applications Security",
    img: NetworkImg,
    description:
      "Ensure the security and safeguarding of software assets with all-encompassing solutions to secure applications and maintain their integrity.",
    learnMore: true,
  },
  {
    title: "Cloud Security ",
    img: CloudImg,
    description:
      "Enhance the protection and durability of cloud infrastructure by implementing sophisticated security measures to ensure safety and resilience.",
    learnMore: true,
  },
];

export const OurExpertise = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update windowWidth state
    const handleResize = () => setWindowWidth(window.innerWidth);

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        background:
          "radial-gradient(at 90% 50%, rgb(255,246,214) 30%, rgba(251,251,251) 80%, rgba(251,251,251) 40%)",
      }}>
      <div className="w-10/12 lg:w-[85%] m-auto mt-16 text-left sm:text-center sm:w-11/12 sm:mt-12">
        <p className="text-5xl tab:text-[44px] font-bold sm:text-3xl">Our Expertise</p>
        <p className="mt-3 text-2xl tab:text-xl font-medium text-gray-800 sm:text-base ">
          Leverage our extensive industry expertise to fortify your business
          against cyber threats, providing robust and tailored cybersecurity
          solutions for ultimate protection.
        </p>
        <div className={style.cardMainContainer}>
          {ourExpertiseCardData?.map((el, i) => (
            <Card
              title={el.title}
              img={el.img}
              desc={el.description}
              learnMore={el.learnMore}
              key={i}
              bg="white"
              bor="none"
              shadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
              titleSize={windowWidth < 600 ? "12px" : "18px"}
              descSize={windowWidth < 600 ? "10px" : "17px"}
              id={i}
              descColor="rgba(0, 0, 0, 0.7)"
            />
          ))}
        </div>
      </div>
    </div>
  );
};
