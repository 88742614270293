import React from "react";

import SalesContact from "../Components/ContactUs/SalesTeamContact/SalesContact";
import GetQuote from "../Components/ContactUs/GetQuote/GetQuote";
import FaqForm from "../Components/ContactUs/FaqForm/FaqForm";
import Component from "../Components/ContactUs/TogetherWeCan/Component";
import SocialMediaLinks from "../Components/ContactUs/SocialMediaLinks/SocialMediaLinks";
import Answer from "../Components/ContactUs/NeedMoreAnswers/Answere";
import { GetReady } from "../Components/GetReady/GetReady";
import { useEffect } from "react";
import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet";
import NewContactForm from "../Components/ContactUs/NewContactForm/NewContactForm";
import ContactOurSalesTeam from "../Components/NewLandingPage/ContactOurSalesTeam/ContactOurSalesTeam";
ReactGA.initialize(process.env.REACT_APP_GA_KEY);
const ContactUs = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Contact-us Page" });
    window.scroll(0, 0)
  }, [])
  return (
    <>
      <Helmet>
        <title>Contact Us - Get in Touch with Us</title>
        <meta name="description" content="We're here to answer your questions, discuss your needs, and explore how our expertise can propel your business forward. Let's connect and chart a path to digital excellence." />
      </Helmet>

      <ContactOurSalesTeam />
      <div className="max-w-[1700px] m-auto">
        <Component />
        <FaqForm />
        <GetQuote />
        <SocialMediaLinks />
        <Answer />
      </div>
      <GetReady />
    </>
  );
};

export default ContactUs;
