import React from "react";
import Marquee from "react-fast-marquee";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Pagination, Navigation } from 'swiper/modules';

import daksh from '../../../Assets/clients/bwImge/daksh-scene.svg'
import dudheClinic from '../../../Assets/clients/bwImge/dudhe-clinic-nagpur.svg'
import myfitMeals from '../../../Assets/clients/bwImge/my-fit-meals.svg'
import bliss from '../../../Assets/clients/bwImge/the-bliss-events-nagpur.svg'
import bigBite from '../../../Assets/clients/bwImge/big-bite-nagpur.svg'
import blueRoof from '../../../Assets/clients/bwImge/blueroof-nagpur.svg'
import hoora from '../../../Assets/clients/bwImge/hoora-logo.svg'
import avModular from '../../../Assets/clients/bwImge/av-modular-nagpur.svg'
import vsInfra from '../../../Assets/clients/bwImge/vs-infrastucture-nagpur.svg'
import wellness from '../../../Assets/clients/bwImge/wellness-gym.svg'
import Kaizer from '../../../Assets/person/Clients/CEOKaizer.svg'
import ClientCarousel from "./ClientCarousel/ClientCarousel";

const cardArray = [
  {
    img: bliss,
    alt: "the-bliss-events-nagpur",
    imgClasses: "w-72 tab:w-44 sm:w-28 mx-16 tab:mx-10 sm:mx-1"
  },
  {
    img: hoora,
    alt: "hoora-nagpur",
    imgClasses: "w-60 tab:w-32 sm:w-24 mx-16 sm:mx-1"
  },
  {
    img: myfitMeals,
    alt: "my-fit-meals",
    imgClasses: "w-56 tab:w-32 sm:w-24 mx-16 sm:mx-1"
  },
  {
    img: daksh,
    alt: "daksh-scene",
    imgClasses: "w-[132px] sm:w-[5rem] mx-16 sm:mx-1"
  },
  {
    img: dudheClinic,
    alt: "dudhe-clinic-nagpur",
    imgClasses: "w-[175px] mx-10 sm:mx-1 sm:w-[100px]"
  },
  {
    img: wellness,
    alt: "wellness-gym-nagpur",
    imgClasses: "w-[200px]  mx-5 sm:mx-1 sm:w-[120px]"
  },
  {
    img: bigBite,
    alt: "big-bite-nagpur",
    imgClasses: "w-24 mx-10 sm:mx-1 sm:w-16"
  },
  {
    img: blueRoof,
    alt: "blue-roof-restaurant-nagpur",
    imgClasses: "w-[165px] mx-5 sm:mx-1 sm:w-[100px]"
  },
  {
    img: avModular,
    alt: "av-modular-nagpur",
    imgClasses: "w-[19rem] mx-10 tab:w-56 sm:mx-1 sm:w-28"
  },
  // {
  //   img: vsInfra,
  //   alt: "v.s.-mahakalkar-nagpur",
  //   imgClasses: "tab:w- mx-16 sm:mx-1 sm:w-32"
  // },
]


export const HappyClients = () => {
  return (
    <div className="bg-white">

      <div
        className="m-auto pt-10 sm:pt-6 sm:pb-5 max-w-[1600px] overflow-hidden"
      >
        <div className=" text-center">
          <h2 data-aos-duration="1000" className=" text-[41px] sm:text-[22px] font-semibold pb-1 sm:pb-0">
            Companies We've Fueled to New Heights
          </h2>
        </div>
        <div className="mx-auto w-11/12 bg-white rounded-3xl sm:rounded-none tab:w-full tab:rounded-none sm:w-full">
        </div>
        <div className="w-11/12 sm:bg-white mx-auto pt-4 pb-16 tab:py-8 sm:py-6 sm:w-full sm:mb-0" id="client_swiper">

          <ClientCarousel />
        </div>

      </div >
    </div>
  );
};
