import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import Client1 from '../../../../Assets/clients/Dudhe-clinic-nagpur.png'
import Client2 from '../../../../Assets/clients/MyFitMeals-nagpur.png'
import Client3 from '../../../../Assets/clients/TheBlissEvents.png'

const array = [
    {
        img: Client1,
        feedback: "GBJ BUZZ has been an absolute game-changer for Dudhe Multispeciality Clinic. Our online visibility skyrocketed, patient interactions flourished, and that website they whipped up? It's like they captured the very essence of our clinic in a digital masterpiece",
        name: 'Dudhe Multispeciality clinic',
        industry: 'Healthcare'
    },
    {
        img: Client2,
        feedback: "Their expertise in digital marketing and website development has been instrumental in elevating our brand. Thanks to their efforts, we've not only reached a wider audience but also forged new franchise deals, expanding our mission of providing wholesome meals.GBJ BUZZ truly understands our vision, and their dedication is unmatched.",
        name: 'MyfitMeals',
        industry: 'FMGC  Industry'
    },
    {
        img: Client3,
        feedback: "teaming up with GBJ BUZZ has been a total win. They've cranked up our online presence and connected us with our dream clients. Our events are getting so much more love, and it's all thanks to GBJ BUZZ's digital magic.",
        name: 'The Bliss Events ',
        industry: 'Events and management '
    },
]


const Testimonials = () => {
    return (
        <div>
            <div className='w-10/12 m-auto tab:w-11/12 sm:w-11/12 pt-10 pb-5'>
                <div>
                    <h2 className='uppercase text-2xl text-center text-gray-700 font-medium'>
                        Testimonials
                    </h2>

                    <Swiper
                        pagination={{
                            dynamicBullets: true,
                            clickable: true,

                        }}
                        modules={[Pagination, Autoplay]}
                        className="mySwiper"
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                    >
                        {
                            array?.map((ele, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <div className='flex items-center flex-col pb-10'>
                                            <div className='w-96 h-52 sm:w-60 sm:h-48'>
                                                <img src={ele.img} alt={ele.name} loading='lazy' />
                                            </div>
                                            <p className='sm:text-sm'>{ele.name}</p>
                                            <p className='text-gray-400 mb-8 sm:text-sm'>{ele.industry}</p>
                                            <p className={`text-gray-700 text-base text-['Open Sans'] italic w-10/12 m-auto before:text-yellow-900   after:text-yellow-900    tab:w-11/12 tab:text-base sm:w-full sm:text-sm`}> <span className="text-yellow-400 text-[30px]">"</span> {ele.feedback}<span className="text-yellow-400 text-[30px] -mt-20">"</span></p>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default Testimonials