import React, { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import BellButton from '../Navbar/BellButton/BellButton';
import supabase from '../../SupabaseConfig/SupabaseClient';
import Moment from 'react-moment';
import { GoDotFill } from "react-icons/go";


// const notificationList = [
//     {
//         title: "New inspection assigned: QW0001 - Pepsi Asia",
//         time: "Now"
//     },
//     {
//         title: "New inspection assigned: AR5567 - Pepsi Europe",
//         time: "4hr"
//     }, {
//         title: "Inspection import has been successfully created",
//         time: "05 May 2023"
//     }
// ]
const NotificatonBox = ({ isOpen, setIsOpen }) => {
    const [supabaseData, setSupabaseData] = useState([])

    const getData = async () => {
        try {
            const { data, error } = await supabase
                .from('Notification_table')
                .select('*')
                .order('id', { ascending: false })
            setSupabaseData(data)
            console.log("Notifications Error-", error)
        } catch (error) {
            console.log("Notifications Error-", error)
        }
    }

    useEffect(() => {
        getData();
    }, [])
    return (
        <div className="relative inline-block text-left ">
            <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="inline-flex justify-center w-full px-2 py-2 text-sm font-medium "
            >
                <BellButton length={supabaseData.length || 0} />
            </button>

            <Transition
                show={isOpen}
                enter="transition-opacity duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="w-96 absolute right-0 mt-1 origin-top-right top-16 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none overflow-y-scroll h-auto max-h-[61vh] ">
                    {/* Dropdown content goes here */}
                    <div className='px-4 pt-2 font-bold'>Notification</div>
                    {
                        supabaseData.length && supabaseData?.map((el, i) => (
                            <div key={i} className={`flex gap-x-1 items-center px-4 py-2 hover:bg-gray-100 `}
                            >
                                <div className='w-6 text-[#fcc900]' >
                                    <GoDotFill size={22} />
                                </div>
                                <div>
                                    <a href="#" className=' text-xs font-semibold line-clamp-2'>
                                        {el.title}
                                    </a>
                                    <p className='text-xs'>
                                        <Moment format="D MMM YYYY" withTitle>
                                            {el.date}
                                        </Moment>
                                    </p>
                                </div>

                            </div>
                        ))
                    }

                </div>
            </Transition>
        </div>
    );
};
export default NotificatonBox;