import React, { useEffect, useState } from 'react'
// import Markdown from 'markdown-to-jsx'
import MDEditor from '@uiw/react-md-editor'
import { useParams } from 'react-router-dom'

import supabase from '../../SupabaseConfig/SupabaseClient'



// const blogData = {
//     writerName: "GBJBuzz",
//     title: "Revamp Your Social Media Strategy with AI: The Ultimate Guide to Boosting Your Online Presence",
//     introduction: "Discover the potential influence of Artificial Intelligence (AI) on global governance in this case study. Explore how AI systems could shape the world and control various domains. While the analysis is speculative, it provides valuable insights into potential scenarios and their implications.",
//     imageUrl: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*ui4LKGVtFihuX5UEtUm6nA.png",
//     description: `Artificial Intelligence (AI) has rapidly transformed the way we live, work, and interact with technology. It's no longer a concept of the distant future; AI is here and now, shaping our world in profound ways. In this blog post, we'll explore the incredible power of AI and its impact on various aspects of our lives.

// ## 1. Automation and Efficiency
//    AI is revolutionizing industries by automating tasks that were once time-consuming and repetitive. Machine learning algorithms can analyze vast datasets in seconds, making data-driven decision-making more accessible. For businesses, this means increased efficiency, reduced costs, and the ability to innovate at a faster pace.

//    ## 2. Healthcare Advancements
//    In the medical field, AI is improving diagnostic accuracy and patient care. Machine learning models can analyze medical images, detect diseases, and predict patient outcomes. This technology is helping healthcare professionals make more informed decisions, ultimately saving lives.

//    ## 3. Personalization
//    AI powers recommendation systems that understand our preferences. From streaming platforms to e-commerce websites, AI algorithms tailor content and product suggestions to individual users, enhancing the user experience and increasing engagement.
//    `

// }

// const str = `
// # Dillinger
// ## _The Last Markdown Editor, Ever_
// [![Build Status](https://travis-ci.org/joemccann/dillinger.svg?branch=master)](https://travis-ci.org/joemccann/dillinger)
// Dillinger is a cloud-enabled, mobile-ready, offline-storage compatible,
// AngularJS-powered HTML5 Markdown editor.
// - Type some Markdown on the left
// - See HTML in the right
// - :sparkles:Magic :sparkles:
// ## Features
// - Import a HTML file and watch it magically convert to Markdown
// - Drag and drop images (requires your Dropbox account be linked)
// - Import and save files from GitHub, Dropbox, Google Drive and One Drive
// - Drag and drop markdown and HTML files into Dillinger
// - Export documents as Markdown, HTML and PDF
// Markdown is a lightweight markup language based on the formatting conventions
// that people naturally use in email.
// As [John Gruber] writes on the [Markdown site][df1]
// > The overriding design goal for Markdown's
// > formatting syntax is to make it as readable
// > as possible. The idea is that a
// > Markdown-formatted document should be
// > publishable as-is, as plain text, without
// > looking like it's been marked up with tags
// > or formatting instructions.
// This text you see here is *actually- written in Markdown! To get a feel
// for Markdown's syntax, type some text into the left window and
// watch the results in the right.
// ## Tech
// Dillinger uses a number of open source projects to work properly:
// - [AngularJS] - HTML enhanced for web apps!
// - [Ace Editor] - awesome web-based text editor
// - [markdown-it] - Markdown parser done right. Fast and easy to extend.
// - [Twitter Bootstrap] - great UI boilerplate for modern web apps
// - [node.js] - evented I/O for the backend
// - [Express] - fast node.js network app framework [@tjholowaychuk]
// - [Gulp] - the streaming build system
// - [Breakdance](https://breakdance.github.io/breakdance/) - HTML
// to Markdown converter
// - [jQuery] - duh
// And of course Dillinger itself is open source with a [public repository][dill]
//  on GitHub.
// ## Installation
// Dillinger requires [Node.js](https://nodejs.org/) v10+ to run.
// Install the dependencies and devDependencies and start the server.


// ## Plugins
// Dillinger is currently extended with the following plugins.
// Instructions on how to use them in your own application are linked below.
// | Plugin | README |
// | ------ | ------ |
// | Dropbox | [plugins/dropbox/README.md][PlDb] |
// | GitHub | [plugins/github/README.md][PlGh] |
// | Google Drive | [plugins/googledrive/README.md][PlGd] |
// | OneDrive | [plugins/onedrive/README.md][PlOd] |
// | Medium | [plugins/medium/README.md][PlMe] |
// | Google Analytics | [plugins/googleanalytics/README.md][PlGa] |
// ## Development
// Want to contribute? Great!
// Dillinger uses Gulp + Webpack for fast developing.
// Make a change in your file and instantaneously see your updates!
// Open your favorite Terminal and run these commands.
// First Tab:
// `

const BlogPage = () => {
    const [blogData, setBlogData] = useState({})
    const { id } = useParams()
    const getData = async () => {
        try {
            const { data, error } = await supabase
                .from('BlogData_copy')
                .select('*')
                .eq('id', id.split("=")[1])
            console.log(data)
            setBlogData(data[0])
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        window.scroll(0, 0)
        getData()
    }, [])

    console.log(id)
    return (
        <div className='my-10'>
            <div className='w-7/12 tab:w-11/12 sm:w-11/12 mx-auto'>
                <div>
                    <h2 className='text-3xl font-semibold tab:text-3xl sm:text-xl'>
                        {blogData.title}
                    </h2>
                    <h4 className='text-2xl font-semibold my-5 flex gap-2 sm:text-lg items-center border-b-4 border-gray-500 pb-3'>
                        <div className='p-5 rounded-full bg-yellow-300 w-max'>

                        </div>
                        {blogData.writerName}
                    </h4>
                </div>
                <div className='w-[30rem] tab:w-96 sm:w-60 mx-auto'>
                    <img src={blogData.imageUrl} alt="" className=' object-cover -[1]' />
                </div>
                <div className='my-10' id='markdownContainer'>
                    <MDEditor.Markdown
                        className='!bg-white !text-black !whitespace-normal'
                        source={blogData.description}
                    // source={blogData.desc}
                    />

                </div>
            </div>
        </div>
    )
}

export default BlogPage