import React from 'react'

import Product from '../Components/CommingSoon/Product'

const ComingSoonProduct = () => {
    return (
        <div className="max-w-[1700px] m-auto">
            <Product />
        </div>
    )
}

export default ComingSoonProduct