import React, { useEffect } from 'react'

import BetterFuture from '../Components/ServicesPages/LibexBuzz/BetterFuture/BetterFuture'
import FindFreeCourse from '../Components/ServicesPages/LibexBuzz/FindFreeCourse/FindFreeCourse'
import { Herosection } from '../Components/ServicesPages/LibexBuzz/Herosection/Herosection'
import { GrowUpSkills } from '../Components/ServicesPages/LibexBuzz/GrowUpSkills/GrowUpSkills'
import { ImproveKnowledge } from '../Components/ServicesPages/LibexBuzz/ImproveKnowledge/ImproveKnowledge'
import { GetReady } from '../Components/GetReady/GetReady'
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet'
ReactGA.initialize(process.env.REACT_APP_GA_KEY);
export const LibexService = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Libex Page" });
    window.scroll(0, 0)
  }, [])
  return (
    <>
      <Helmet>
        <title>LibeX Buzz - Elevating Skills and Expertise through Professional Learning</title>
        <meta name="description" content="A platform for professional learning and skill development. Our courses and expert-led training programs cater to diverse industries and individuals. Elevate your skills and expertise in a professional environment with Libex Buzz." />
      </Helmet>
      <div className="max-w-[1700px] m-auto">
        <Herosection />
        <GrowUpSkills />
        <ImproveKnowledge />
        <BetterFuture />
        <FindFreeCourse />
      </div>
      <GetReady />
    </>
  )
}
