import React from 'react'
import style from './OurServices.module.css';
import Img1 from '../../../../Assets/CardImages/7iBuzz/Artificial Intelligence.png'
import Img2 from '../../../../Assets/CardImages/7iBuzz/Cloud Development.png'
import Img3 from '../../../../Assets/CardImages/7iBuzz/Lock.png'
import Img4 from '../../../../Assets/CardImages/7iBuzz/Robotic.png'
import { CardComponent } from '../../CardComponent/CardComponent';

const cardData = [
    {
        title: "Customer Interaction",
        img: Img1,
        features: [],
        description: "By harnessing the capabilities of chatbots and virtual assistants, in conjunction with the advancements in natural language processing (NLP) and speech recognition and synthesis technologies, we can create a tailored, individualised solution.",
    },
    {
        title: "Business Optimization",
        img: Img4,
        features: [],
        description: "Leveraging the toolbox of business optimization, we utilize safeboximage for predictive analytics, train machine learning models, employ AI-driven automation, and implement AI solutions in supply chain management, finance, and energy management.",
    }, {
        title: "Industry-Specific Solutions:",
        img: Img2,
        features: [],
        description: "Utilizing AI in Healthcare, Agriculture, Education, and E-commerce sectors has the potential to significantly enhance outcomes, making these domains more efficient and effective.",
    }, {
        title: " Data Management",
        img: Img1,
        features: [],
        description: "Labelling and annotating data, integrating artificial intelligence services, and implementing fraud detection and prevention techniques.",
    }, {
        title: "Advanced Technologies",
        img: Img4,
        features: [],
        description: "Crafting personalised artificial intelligence solutions and harnessing the power of computer vision.",
    }, {
        title: "Marketing and Customer Engagement",
        img: Img2,
        features: [],
        description: "Chatbots and virtual assistants employ machine learning, while personalization solutions use artificial intelligence. Predictive analytics leverage advanced algorithms, and Natural Language Processing (NLP) processes human language for diverse applications.",
    }, {
        title: "Financial and Risk Management",
        img: Img3,
        features: [],
        description: "Leveraging finance AI for predictive analytics is crucial in enhancing fraud detection and prevention through advanced machine learning model training.",
    }, {
        title: "Supply Chain and Logistics",
        img: Img3,
        features: ['AI-driven Automation',
            'Supply Chain AI',
            'Predictive Analytics',
            'Machine Learning Model Training'],
        description: false,
    }, {
        title: "Healthcare and Medical Research",
        img: Img3,
        features: ['Healthcare AI',
            'Predictive Analytics',
            'Natural Language Processing (NLP)',
            'Computer Vision'],
        description: false,
    },
    {
        title: "PAAS",
        img: Img3,
        features: [],
        description: 'Empower your business with the efficiency-enhancing capabilities of smart contracts, meticulously crafted by our experts to optimise operations, guarantee transparency, and fortify security. These self-executing contracts are designed to streamline processes.',
    }, {
        title: "SAAS",
        img: Img3,
        features: [],
        description: 'Leverage the proficiency of our professionals who craft self-executing contracts, optimising operations, guaranteeing transparency, and bolstering security. Boost your business efficiency by harnessing the potential of intelligent contracts.',
    }, {
        title: "Alaas",
        img: Img3,
        features: [],
        description: 'Leverage the expertise of our professionals to craft self-executing contracts, optimising operations, guaranteeing transparency, and bolstering security. Harness the potential of smart contracts to elevate your business efficiency.',
    },
]

export const OurServices = () => {

    return (
        <div style={{
            background: 'radial-gradient(at -5% 50%, rgba(255, 209, 35, 0.20), rgba(255, 255,255)'
        }}>
            <div className='w-10/12  sm:w-11/12 m-auto py-8 sm:py-4'>
                <div className="w-48 m-auto mb-4 border-[3px] border-yellow-400 border-solid sm:w-24 sm:border-2 sm:my-6"></div>
                <div className='text-center mb-8 sm:mb-6'>
                    <p className='text-[44px]  tab:text-3xl sm:text-3xl font-bold text-gray-400 leading-[70px] lg:leading-[50px]'><span className='text-black'>Our</span> Services</p>
                    <p className='w-7/12 m-auto sm:w-full'>Ignite your business growth in the digital landscape with Xpan Buzz's transformative digital marketing services</p>
                </div>
                <div className={style.cardMainContainer}>
                    {cardData?.map((el, i) => (
                        <CardComponent
                            title={el.title}
                            img={el.img}
                            desc={el.description}
                            features={el.features}
                            learnMore={el.learnMore}
                            key={i}
                            bg="#fff5cc"
                            bor="none"
                            descColor="rgba(0, 0, 0, 0.7)"
                            id={i}

                        />
                    ))}
                </div>
            </div>
        </div>

    )
}
