import React, { useState } from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify'
import supabase from '../../../SupabaseConfig/SupabaseClient'
import GoalImg from '../../../Assets/SVG_Images/LandingPage/Goal-cuate.svg'

const ContactOurSalesTeam = () => {
    return (
        <div id="contact-form" className='bg-[#FFC900] tab:bg-[#F7F7F7] sm:bg-[#F7F7F7]'>

            <div className='max-w-[1600px] m-auto relative sm:pb-0 '>
                <div className='w-11/12 tab:w-full sm:w-full m-auto grid grid-cols-2 tab:grid-cols-1 sm:grid-cols-1'>
                    <div className=' flex flex-col justify-between tab:relative sm:relative pt-12 2xl:pl-10 xl:pl-9 tab:pt-0 sm:pt-4 tab:w-11/12 sm:w-10/12 tab:m-auto sm:m-auto tab:pb-20 sm:pb-20 '>
                        <div>
                            <h2 data-aos="fade-up"
                                data-aos-duration="1000" className='text-6xl sm:text-[28px] font-medium sm:font-semibold sm:leading-10'>
                                Let’s Scale your <span className='hidden sm:inline '> Brand, Together.</span>
                            </h2>
                            <h2 data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" className='sm:hidden text-6xl sm:text-[28px] font-medium sm:font-semibold mt-2'>
                                Brand, Together.
                            </h2>
                            <h4 data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" className='flex gap-1 text-xl sm:text-xs font-semibold text-[#393939] mt-3'>Get a start
                                <a href="mailto:team@gbjbuzz.com" className='flex gap-y-4 items-center'>
                                    @gbjbuzz.com
                                </a>
                            </h4>
                        </div>
                        <div data-aos="fade-right"
                            data-aos-duration="1000"
                            className='tab:absolute sm:absolute bottom-0 -right-2 '>
                            <img src={GoalImg} alt="gbj-buzz-goal-image" className='w-9/12 xl:w-11/12 lg:w-11/12 tab:w-48 sm:w-[8.5rem]' />
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="1000" className='hidden tab:flex sm:flex bg-[#FFC900] p-4 h-full items-center justify-center '>
                        <h3 className='text-xl font-extrabold uppercase'>
                            GET A QUOTE IN 5 MIN!
                        </h3>
                    </div>
                    <div className='w-4/5 lg:w-11/12 tab:w-full sm:w-full h-min-[730px] max-h-[780px] h-[730px] tab:h-[750px] sm:h-[740px]  ms-auto bg-white mr-12 tab:mr-0 sm:mr-0'>
                        {/* <ContactForm /> */}
                        <iframe aria-label='Sales form' frameBorder="0" style={{ width: "100%", border: "none", backgroundColor: "white" }} className='h-full overflow-hidden mb-0  px-0 bg-inherit' src='https://forms.zohopublic.in/gbjbuzz/form/Salesform1/formperma/M1Wq8_k6dPjnW-YieSguo-cUim9bmTg9cksONsimCCo'></iframe>
                        <div className=' w-full bg-white overflow-hidden'></div>

                    </div>
                    {/* <iframe aria-label='Sales form' frameborder="0" style={{height:"500px",width:"99%",border:"none"}} src='https://forms.zohopublic.in/gbjbuzz/form/Salesform1/formperma/M1Wq8_k6dPjnW-YieSguo-cUim9bmTg9cksONsimCCo'></iframe> */}
                </div>
                <div data-aos="zoom-in-left" data-aos-duration="1000" className='tab:hidden sm:hidden absolute top-0 right-0 w-24 h-full flex items-center justify-center' style={{ writingMode: "vertical-rl" }}>
                    <h1 className='text-5xl font-semibold uppercase'>
                        GET A QUOTE IN 5 MIN!
                    </h1>

                </div>
            </div>

        </div>

    )
}

export default ContactOurSalesTeam;



const ContactForm = () => {

    const [formData, setFormData] = useState({
        name: "",
        company: "",
        phoneNumber: "",
        email: "",
        message: ""
    });

    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        if ({ ...formData }) {


            toast.success('your request was successful')

            try {
                const { error } = await supabase
                    .from('New_Contact_Form_Landing_Page')
                    .insert({ ...formData })

                if (error) {
                    toast.error('Something went wrong')
                }
            } catch (error) {
                console.log(error)
                toast.error('Server error ')
            }

            setFormData({
                name: "",
                company: "",
                phoneNumber: "",
                email: "",
                message: ""

            })
        }
    };
    return (
        <div
            className="py-20 tab:py-6 sm:py-4 bg-[#f7f7f7]">
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <div className='w-10/12 tab:w-11/12 sm:w-10/12 m-auto' id="formDiv">
                <h1 className='tab:hidden sm:hidden 2xl:text-4xl text-[34px] font-medium text-center'>Contact Our Sales Team</h1>
                <form method="post" onSubmit={handleSubmit}>
                    <div className="  mt-6 sm:mt-5">
                        <label
                            htmlFor="firstName"
                            className="block text-xl font-semibold sm:text-base tab:text-base">
                            Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            className="w-full bg-transparent border border-b-2 sm:border-b-1 border-t-0 border-r-0 border-l-0 py-2 sm:py-1 border-b-black text-xl focus:outline-0 tab:text-base sm:text-sm placeholder:text-[#797979]"
                            name="name"
                            placeholder='Type Name'
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className=" mt-6 sm:mt-5">
                        <label
                            htmlFor="lastName"
                            className="block text-xl font-semibold sm:text-base tab:text-base">
                            Company
                        </label>
                        <input
                            type="text"
                            id="company"
                            className="w-full bg-transparent border border-b-2 sm:border-b-1 border-t-0 border-r-0 border-l-0 py-2 sm:py-1 border-b-black text-xl focus:outline-0 tab:text-base sm:text-sm placeholder:text-[#797979]"
                            placeholder='Type Company name'
                            name="company"
                            value={formData.company}
                            onChange={handleChange}
                        />
                    </div>
                    <div className=" mt-6 sm:mt-5">
                        <label
                            htmlFor="phoneNumber"
                            className="block text-xl font-semibold sm:text-base tab:text-base">
                            Phone
                        </label>
                        <input
                            type="tel"
                            id="phoneNumber"
                            className="w-full bg-transparent border border-b-2 sm:border-b-1 border-t-0 border-r-0 border-l-0 py-2 sm:py-1 border-b-black text-xl focus:outline-0 tab:text-base sm:text-sm placeholder:text-[#797979]"
                            placeholder='Type phone number'
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                        />
                    </div>
                    <div className=" mt-6 sm:mt-5">
                        <label
                            htmlFor="email"
                            className="block text-xl font-semibold sm:text-base tab:text-base">
                            Email Address
                        </label>
                        <input
                            type="email"
                            id="phoneNumber"
                            className="w-full bg-transparent border border-b-2 sm:border-b-1 border-t-0 border-r-0 border-l-0 py-2 sm:py-1 border-b-black text-xl focus:outline-0 tab:text-base sm:text-sm placeholder:text-[#797979]"
                            placeholder='Type email address'
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mt-6 sm:mt-5 ">
                        <label
                            htmlFor="lastName"
                            className="block text-xl font-semibold sm:text-base tab:text-base">
                            Message
                        </label>
                        <textarea
                            rows="2"
                            id="message"
                            className="w-full bg-transparent border border-b-2 sm:border-b-1 border-t-0 border-r-0 border-l-0 py-2 sm:py-1 border-b-black text-xl focus:outline-0 tab:text-base sm:text-sm placeholder:text-[#797979] placeholder:absolute placeholder:bottom-2"
                            placeholder='Type message'
                            name="message"
                            value={formData.message}
                            onChange={handleChange}></textarea>
                        <div className='flex gap-3 items-center mt-6 sm:mt-5'>
                            <button
                                type="submit"
                                className="w-48 tab:w-40 sm:w-32 bg-black tab:bg-[#FFC900] sm:bg-[#FFC900] text-xl sm:text-sm font-semibold text-white tab:text-black sm:text-black py-3 rounded-full sm:py-2 shadow-[0px_1px_3px_rgba(0,0,0,0.25)]">
                                Submit
                            </button>
                            <p className='sm:text-xxs'>
                                By clicking Submit, I hereby agree to the <span className=' underline underline-offset-2'>terms of service</span>
                            </p>
                        </div>

                    </div>
                </form>
            </div>

        </div>
    )
}