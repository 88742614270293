import React from 'react'
import CyberSecurityImg from '../../../Assets/Images/CyberSecurity/Protect.png'
import { Protect } from '../../UniversalComponent/Protect/Protect'

export const WhyWeAreProtect = () => {
  return (
    <div className='mt-16 mb-10 sm:mt-10 sm:mb-4'>
      <Protect
        img={CyberSecurityImg}
        title="
        We are present to ensure security."
        topDescription='Bolstering the security of your business through resilient cybersecurity measures, our proficient team shields against malicious attacks, protects sensitive information, and upholds the safety of your online presence with unwavering vigilance.'
        bottomDescription='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.'
        projectDone="18+"
        yearExp="03+"
        projectDoneTitle="Projects Done"
        yearExpTitle="Years of Experience"
        projectDescription="Discover our successful cybersecurity projects showcasing expertise and client satisfaction."
        experecneDescription="Trusted expertise built through years of cybersecurity industry experience."
      />

    </div>
  )
}
