import React, { useState } from "react";
import Aos from "aos";
import 'aos/dist/aos.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import leftDownArrow from "../../../Assets/SVG_Images/ContactUsPage/leftDownArrow.svg";
import rightDownArrow from "../../../Assets/SVG_Images/ContactUsPage/rightDownArrow.svg";
import phoneIcon from "../../../Assets/SVG_Images/ContactUsPage/Phone-26-svg.svg";
import emailIcon from "../../../Assets/SVG_Images/ContactUsPage/Email-50-svg.svg";
import addressIcon from "../../../Assets/SVG_Images/ContactUsPage/Meeting-9-svg.svg";
import rightUpArrow from "../../../Assets/SVG_Images/ContactUsPage/upArrow.svg";
import supabase from "../../../SupabaseConfig/SupabaseClient";

Aos.init()

const FaqForm = () => {


  const cardsIcon = [
    {
      icon: phoneIcon,
      desc: "Phone 7020107390",
      alt: "phone Icon",
      link: 'tel:+917020107390'
    },
    {
      icon: addressIcon,
      desc: "Nagpur, Maharastra",
      alt: "address Icon",
      link: 'https://goo.gl/maps/DkpqSwC9dVinRkaD9'
    },
    {
      icon: emailIcon,
      desc: "team@gbjbuzz.com",
      alt: "email Icon",
      link: 'mailto:team@gbjbuzz.com'
    },
  ];

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    message: "",
  });

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    if ({ ...formData }) {


      toast.success('your request was successful')

      try {
        const { error } = await supabase
          .from('FaqForm')
          .insert({ ...formData })

        if (error) {
          toast.error('Something went wrong')
        }
      } catch (error) {
        console.log(error)
        toast.error('Server error ')
      }

      setFormData({
        firstName: "",
        lastName: "",
        message: "",
      })
    }
  };

  return (
    <div
      className="py-12 mb-1 shadow-md relative font-[Rubik]"
      style={{
        background:
          "radial-gradient(at 99% 60%, rgb(255, 246, 214) 20%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 50%)",
      }}>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="absolute top-56 left-10 sm:w-24 sm:top-48 sm:left-0 tab:top-72 tab:-left-6">
        <img src={leftDownArrow} alt="leftSpiralDownArrow" loading='lazy' />
      </div>
      <div
        className="w-11/12 sm:w-8/12 grid gap-6 mb-16 grid-cols-1 m-auto  flex-col"
        id="faq">
        <div className="text-center w-11/12 sm:w-full m-auto"
          data-aos='fade-up'
          data-aos-duration='600'
          data-aos-offset='300'
        >
          <p className="text-gray-400 text-6xl font-bold sm:text-xl uppercase tab:text-5xl">
            If you have <span className="text-black">any question</span>, please
            don't hesitate to send us a message
          </p>
        </div>
        <div id="formDiv">
          <form method="post" onSubmit={handleSubmit}>
            <div className=" w-9/12 sm:w-11/12 m-auto text-center mt-5">
              <label
                htmlFor="firstName"
                className="block mb-2 text-xl text-gray-500 font-semibold sm:text-sm">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                className="w-full bg-transparent border border-b-4 border-t-0 border-r-0 border-l-0 border-black p-4 sm:p-2 text-xl focus:outline-0 sm:text-sm"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div className=" w-9/12 sm:w-11/12 m-auto text-center mt-5">
              <label
                htmlFor="lastName"
                className="block mb-2 text-xl text-gray-500 font-semibold sm:text-sm">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                className="w-full bg-transparent border border-b-4 border-t-0 border-r-0 border-l-0 border-black p-4 sm:p-2 text-xl focus:outline-0 sm:text-sm"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
            <div className=" w-9/12 sm:w-11/12 m-auto text-center my-10 sm:mt-5 ">
              <label
                htmlFor="lastName"
                className="block mb-2 text-xl text-gray-500 font-semibold sm:text-sm">
                Message
              </label>
              <textarea
                rows="4"
                id="message"
                className="w-full bg-transparent font-light border border-b-4 border-t-0 border-r-0 border-l-0 border-black p-4 sm:p-2 text-xl focus:outline-0 sm:text-sm"
                name="message"
                value={formData.message}
                onChange={handleChange}></textarea>
              <button
                type="submit"
                className="w-44 bg-black text-white p-4 mt-6 rounded-3xl">
                START HERE
              </button>
            </div>
          </form>
          {/* Absolute Img right Spiral Arrow down */}
          <div
            className="sm:hidden absolute top-[38rem] right-16 tab:top-[42rem] tab:-right-1"
            style={{ maxWidth: "1600px" }}>
            <img src={rightDownArrow} alt="rightDownArrow" loading='lazy' />
          </div>
          {/* Absolute Img right Spiral Arrow down */}
        </div>

      </div>
      <div className="hidden sm:block sm:absolute top-[30rem] right-12">
        <img src={rightUpArrow} alt="rightSpiralUpArrow" />
      </div>
      <div className="flex flex-row flex-wrap w-8/12 justify-evenly m-auto sm:w-full items-baseline">
        {cardsIcon?.map((card, i) => {
          return (
            <a href={card.link} target="_about"
              className="flex justify-center sm:justify-between items-center flex-col w-1/3 sm:w-1/4 text-center sm:my-3 sm:gap-2"
              key={i}>
              <img src={card.icon} alt={card.alt} loading='lazy' />
              <p >{card.desc}</p>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default FaqForm;
