import React, { useEffect } from "react";
import Cards from "../Components/Services/Cards/Cards";
import Introduction from "../Components/Services/Introduction/Introduction";
import Promotion from "../Components/Services/Promotion/Pramotion";
import { GetReady } from "../Components/GetReady/GetReady";
import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet";
ReactGA.initialize(process.env.REACT_APP_GA_KEY);
const Services = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "All Service Page" });
    window.scroll(0, 0)
  }, [])
  return (
    <>
      <Helmet>
        <title>GBJ Buzz - All Services</title>
        <meta name="description" content="GBJ Buzz Services" />
      </Helmet>
      <div className="max-w-[1700px] m-auto">
        <Introduction />
        <Cards />
        <Promotion />
      </div>
      <GetReady />
    </>
  );
};

export default Services;
