import React from 'react'
import { Typewriter } from 'react-simple-typewriter'

import Frame1 from '../../../../Assets/XpanFrameImages/XpanFrame1.svg'
import Frame2 from '../../../../Assets/XpanFrameImages/xpanFrame2.svg'
import Frame3 from '../../../../Assets/XpanFrameImages/xpanFrame3.svg'
import Frame4 from '../../../../Assets/XpanFrameImages/xpanFrame4.svg'
import Frame5 from '../../../../Assets/XpanFrameImages/xpanFrame5.svg'


const arrayLinks = [
    {
        img: Frame1,
        title: 'Tailored Strategies'
    },
    {
        img: Frame2,
        title: 'Expert Team'
    },
    {
        img: Frame3,
        title: 'Data-Driven Approach'
    },
    {
        img: Frame4,
        title: 'Measurable Results'
    },
    {
        img: Frame5,
        title: 'Client-Centric Approach'
    },
]

const WhyChoose = () => {
    return (
        <div className='mb-2 shadow-md py-10 ' style={{
            background: 'radial-gradient(at -5% 50%, rgba(255, 209, 35, 0.20), rgba(255, 255,255)',
        }}>
            <div className='flex items-center flex-col'>
                <div className='border-t-4 p-1 w-60 border-yellow-300 mb-8'></div>
                <h2 className='text-center text-gray-400 text-4xl font-bold mb-16 tab:text-3xl sm:text-lg sm:mb-0'>
                    Why Choose <span className='text-black'><Typewriter
                        words={['Xpan Buzz ', 'Digital Marketing ']}
                        loop={false}
                        cursor
                        cursorStyle='_'
                        typeSpeed={120}
                        deleteSpeed={80}
                    /></span>
                </h2>
            </div>
            <div className="flex flex-row flex-wrap w-9/12 justify-evenly m-auto sm:w-10/12 items-center lg:w-9/12">
                {arrayLinks?.map((item, i) => {
                    return (
                        <div
                            key={i}
                            className="flex justify-between flex-col items-center w-4/12 sm:my-5 my-3 sm:w-6/12 lg:w-6/12 tab:w-6/12 text-center">
                            <div>
                                <img src={item.img} alt={item.title} className="h-60 sm:h-40" loading='lazy' />
                            </div>
                            <a
                                href={item?.link || '#'}
                                className="w-60 rounded-3xl border py-2 px-5 shadow-xl font-medium bg-white sm:w-11/12 sm:text-xs sm:px-1 ">
                                {item.title}
                            </a>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default WhyChoose