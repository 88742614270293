import React, { useState } from "react";
import { Link } from "react-router-dom";

import AccordionComponent from "./Accordion/AccordionComponent";

const accordionArray = [
  {
    question: "Who is GBJ Buzz (Grow Your Business At Junction)",
    answer:
      "We are your dedicated partners in Digital Engineering and Enterprise Modernization, a trusted source of innovative solutions. We offer a comprehensive suite of IT services, including SaaS, PaaS, software development, App Development, & holistic business IT solutions. Explore the essence of our identity as the driving force propelling you beyond boundaries.",
  },
  {
    question: "What makes GBJ Buzz stand out in business development?",
    answer:
      "By integrating diverse channels, utilizing compelling content, and implementing robust analytics, we ensure a strategic and dynamic approach. Regular performance assessments and agile adjustments guarantee our strategies stay effective in the ever-evolving digital landscape.",
  },
  {
    question: "Does GBJ Buzz provide marketing services",
    answer:
      "Certainly! We focus on providing personalised strategies exclusively for our clients. If you're interested in exploring how our exclusive marketing services can benefit your business, please reach out for a consultation to discuss your unique needs & objectives.",
  },
  {
    question: "How can my business benefit from GBJ Buzz's milestones?",
    answer:
      "Revolutionizing omnichannel experiences with cutting-edge tech in design, GenAI, data, and cloud-native solutions. Our focus spans innovative software engineering, seamlessly integrating disruptive tech for optimized value chains across industries. From global communications to financial solutions, we simplify operations, enabling growth and delivering top-tier customer experiences.",
  },
];

const DigitalMarketingFaq = () => {
  const [active, setActive] = useState(0);

  const handleToggle = id => {
    if (active === id) {
      setActive(null);
    } else {
      setActive(id);
    }
  };
  return (
    <div className="bg-[#F7F7F7] sm:bg-white">
      <h3
        data-aos="fade-up"
        data-aos-duration="1000"
        className="font-bold hidden sm:block text-3xl text-center py-5 bg-[#4CAF50] text-white animate-bounce">
        Frequently Asked Questions
      </h3>
      <div
        data-aos="flip-up"
        data-aos-duration="1000"
        className="font-semibold text-lg hidden sm:block w-11/12 mx-auto text-[#333] py-8 hover:animate-smallBounce">
        As a forefront technology solutions provider, GBJ Buzz is committed to
        offering extensive resources & addressing frequently asked questions to
        support and empower our clients in navigating innovative solutions and
        digital transformations.
      </div>
      <div className="max-w-[1600px] w-11/12 mx-auto py-16 sm:py-0">
        <div className="flex justify-between items-start sm:flex-col">
          <div className="w-5/12 sm:w-full  sm:hidden">
            <h3
              data-aos="fade-up"
              data-aos-duration="1000"
              className="font-semibold text-4xl mb-10 tab:text-2xl">
              Frequently Asked Questions
            </h3>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
              className="font-medium mb-10 tab:text-sm hover:animate-smallBounce">
              As a forefront technology solutions provider, GBJ Buzz is
              committed to offering extensive resources & addressing frequently
              asked questions to support and empower our clients in navigating
              innovative solutions and digital transformations.
            </div>
            <div className="flex gap-y-5 items-center">
              <button
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
                className="px-6 py-4 tab:px-4 tab:py-2 tab:text-sm bg-[#ffc900] rounded-full me-5 font-medium hover:bg-white hover:shadow-lg transition-all duration-700 ease-linear">
                More Questions
              </button>
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="400">
                <Link
                  to={"/contact"}
                  className="underline  underline-offset-4 font-medium tab:text-sm">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="400"
            className="w-6/12 sm:w-full">
            {accordionArray?.map((item, i) => {
              return (
                <AccordionComponent
                  item={item}
                  id={i}
                  active={active}
                  handleToggle={handleToggle}
                  key={i}
                />
              );
            })}
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="hidden sm:block py-9">
            <Link
              to={"/"}
              className="p-4 rounded-full me-5 text-base font-semibold">
              More Questions
            </Link>
            <Link
              to={"/contact"}
              className="text-base underline underline-offset-4 font-semibold tab:text-sm">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalMarketingFaq;
