import React from 'react'

const array = [
    {
        title: 'Ideation',
        desc: 'We explore the viability of your blockchain initiative in collaboration with our blockchain experts, establishing the business objectives and operational procedures for your system.'
    },
    {
        title: 'Assessment',
        desc: 'We conduct an examination of your current solution to determine its potential for migration to the blockchain. Our team evaluates the entirety of your business processes and pinpoints the areas where blockchain technology can be seamlessly integrated into your specific use case.'
    },
    {
        title: 'Technical Component Definition',
        desc: 'Our team of experts in blockchain technology can determine the most suitable blockchain platform in accordance with your business needs and the specific blockchain variety.'
    },
    {
        title: 'PoC',
        desc: 'We develop a framework and prototype to identify potential business applications with essential features. The demonstration serves to assess the feasibility of an actual solution.'
    },
    {
        title: 'Integration',
        desc: "We develop a framework and prototype to identify potential business applications with essential features. The demonstration serves to assess the feasibility of an actual solution."
    },
    {
        title: 'Development',
        desc: "When you're prepared to embark on the journey of developing blockchain technology, we assist you in creating a complete blockchain solution, encompassing everything from UI/UX design to comprehensive front-end and back-end execution."
    },
]

const BlockChainProcess = () => {
    return (
        <div className='mb-2 shadow-md pb-8' style={{
            background:
                "radial-gradient(at 90% 50%, rgba(255,246,214,0.4), rgba(255,255,255) 80%, rgba(255,255,255) 40%)",
        }}>
            <div className='w-10/12 m-auto tab:w-11/12 sm:w-11/12'>
                <div className='p-1 border-t-4 border-yellow-300 my-8 w-4/12 m-auto'></div>
                <h2 className='text-center text-3xl font-semibold sm:text-xl'>
                    Our Blockchain Consulting Process
                </h2>

                <div className='flex flex-wrap gap-4 justify-between mt-10'>
                    {
                        array?.map((ele, i) => {
                            return <Card key={i} {...ele} />
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default BlockChainProcess

const Card = ({ title, desc }) => {
    return (
        <div className='w-3/12 sm:w-full'>
            <h3 className='font-semibold'>
                {title}
            </h3>
            <h4 className='text-gray-400 text-sm'>
                {desc}
            </h4>
        </div>
    )
}