import React, { useRef } from "react";
import SevenImg from "../../../../Assets/CardImages/7iBuzz/WeAreHereToInnovate.svg";
import { useNavigate } from "react-router";
import { Protect } from "../../../UniversalComponent/Protect/Protect";

export const WeAreHereToInnovate = () => {
  const element = useRef()
  const navigate = useNavigate()

  return (
    <div>
      <Protect
        img={SevenImg}
        title="We are here to advance and enhance artificial intelligence"
        topDescription="Dedicated to pushing the boundaries of AI technology, we provide businesses with state-of-the-art solutions that revolutionise their operations. Our AI services not only expand the realm of possibilities but also fuel growth, guaranteeing that your business continues to lead the way in innovation."
        bottomDescription="Embrace the future with us, as we lead the way in AI innovation for your success."
        projectDone="50+"
        yearExp="04+"
        projectDoneTitle="Projects Done"
        yearExpTitle="Years of Experience"
        projectDescription="Explore our accomplished AI initiatives, which serve as a testament to our proficiency and client contentment."
        experecneDescription="Years of experience in the field of artificial intelligence have cultivated a deep reservoir of trusted expertise"
      />
    </div>
  );
};
