import React, { useState } from "react";
import AccordionComponent from "./Accordion/AccordionComponent";

const accordionData = [
  {
    question: 'What is GBJ Buzz?',
    answer: 'GBJ Buzz is a dynamic and innovative company that specializes in cutting-edge marketing solutions. We help businesses of all sizes reach their target audience, increase brand visibility, and drive sustainable growth through our creative strategies.'
  },
  {
    question: 'What services does GBJ Buzz offer ?',
    answer: `GBJ Buzz offers a wide range of services, including:
    Digital Marketing: We provide comprehensive digital marketing services, including social media marketing, search engine optimization(SEO), email marketing, and pay - per - click(PPC) advertising.
  Content Creation: Our team of skilled content creators crafts engaging and compelling content, including blog posts, articles, infographics, and videos, tailored to meet your brand's unique needs.
  Website Development: We design and develop responsive and user - friendly websites that make a lasting impression on your audience and drive conversions.
  Branding and Design: Our experts can help you establish a strong brand identity through logo design, brand guidelines, and other branding elements.
  Event Marketing: From planning to execution, we provide event marketing services to make your events a great success.`
  },
  {
    question: 'How can GBJ Buzz benefit my business ?',
    answer: `GBJ Buzz can benefit your business in several ways:
    Increased Online Visibility: Our digital marketing strategies can boost your online presence, allowing you to reach a broader audience and attract potential customers.
  Enhanced Brand Reputation: We help you build a positive brand image and reputation through effective content and engagement strategies.
  Targeted Marketing: Our team ensures that your marketing efforts are focused on the right audience, maximizing your return on investment.
    Data - Driven Insights: We use data analytics to refine and optimize your marketing campaigns for better performance.`
  },
  {
    question: 'Is GBJ Buzz suitable for small businesses ?',
    answer: ' Absolutely! GBJ Buzz caters to businesses of all sizes, including small and medium - sized enterprises(SMEs).We offer scalable solutions that fit your budget and objectives, helping you grow your business efficiently.'
  },
  {
    question: 'How do I get started with GBJ Buzz ?',
    answer: 'Getting started with GBJ Buzz is simple.You can either fill out the contact form on our website or give us a call.Our team will schedule a consultation to discuss your business goals, challenges, and how we can tailor our services to meet your specific needs.'
  },
  {
    question: 'What sets GBJ Buzz apart from other marketing agencies ?',
    answer: `GBJ Buzz stands out due to several reasons:
    Innovative Strategies: We constantly adapt to the latest marketing trends and technologies to provide cutting - edge solutions.
    Dedicated Team: Our team is passionate about what they do, and we are committed to achieving the best results for our clients.
    Personalized Approach: We understand that each business is unique, so we tailor our strategies to suit your individual requirements.
    Measurable Results: We focus on data - driven strategies, allowing us to track and measure the success of your campaigns effectively.`
  },
  {
    question: 'What industries does GBJ Buzz specialize in?',
    answer: 'GBJ Buzz has experience working with diverse industries, including technology, healthcare, fashion, e - commerce, education, and more.Our adaptable approach allows us to deliver successful campaigns across various sectors.'
  },
  {
    question: 'How does GBJ Buzz ensure data security and privacy ?',
    answer: ' At GBJ Buzz, we take data security and privacy seriously.We follow industry best practices and adhere to strict confidentiality standards.Your data is secure with us, and we never share sensitive information with third parties without your consent.'
  },
  {
    question: 'Does GBJ Buzz provide ongoing support and maintenance for websites and marketing campaigns ?',
    answer: 'Yes, GBJ Buzz offers ongoing support and maintenance for websites and marketing campaigns.We believe in building long - term partnerships with our clients, and our team is available to assist you with any updates, optimizations, or changes that may be required.'
  },
  {
    question: ' Can I see examples of previous projects and success stories ?',
    answer: 'Certainly! We showcase our portfolio and success stories on our website.You can explore the projects we have worked on to get a better understanding of our capabilities and the results we have achieved for our clients.'
  },


];
const Answer = () => {
  const [active, setActive] = useState(null);

  const handleToggle = id => {
    if (active === id) {
      setActive(null);
    } else {
      setActive(id);
    }
  };
  return (
    <div className="font-[Rubik] py-16 mb-2 shadow-md">
      <div className="w-11/12 m-auto">
        <div className="font-semibold text-5xl text-center mb-10 uppercase sm:text-3xl tab:text-4xl">
          <p>
            <span className="text-gray-400">Need</span> more answers? Our FAQ
          </p>
          <p className="text-gray-400">page has got you covered</p>
        </div>
        <div
          className="w-7/12 m-auto sm:w-full transition-all duration-300 ease-in lg:w-10/12 tab:w-11/12">
          {accordionData?.map((item, i) => {
            return (
              <AccordionComponent
                item={item}
                id={i}
                active={active}
                handleToggle={handleToggle}
                key={i}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Answer;
