import React from "react";

import twitterIcon from "../../../Assets/SVG_Images/ContactUsPage/gbj-buzz-twitter.svg";
import linkedinIcon from "../../../Assets/SVG_Images/ContactUsPage/gbj-buzz-linkedin.svg";
import youtubeIcon from "../../../Assets/SVG_Images/ContactUsPage/gbj-buzz-youtube.svg";
import facebookIcon from "../../../Assets/SVG_Images/ContactUsPage/gbj-buzz-facebook.svg";
import instagramIcon from "../../../Assets/SVG_Images/ContactUsPage/gbj-buzz-instagram.svg";

const LinksArray = [
  {
    icon: twitterIcon,
    link: "https://twitter.com/gbjbuzz",
    title: "Twitter",
    alt:"gbj-buzz-twitter"
  },
  {
    icon: facebookIcon,
    link: "https://m.facebook.com/people/GBJ-BUZZ/100068925834637/",
    title: "Facebook",
    alt:"gbj-buzz-facebook"
  },
  {
    icon: instagramIcon,
    link: "https://www.instagram.com/gbjbuzz/",
    title: "Instagram",
    alt:"gbj-buzz-instagram"

  },
  {
    icon: youtubeIcon,
    link: "https://www.youtube.com/channel/UCz-6ncQAX2GGKy8v1veDMiw",
    title: "Youtube",
    alt:"gbj-buzz-youtube"
  },
  {
    icon: linkedinIcon,
    link: "https://www.linkedin.com/company/gbj-buzz/mycompany/",
    title: "LinkedIn",
    alt:"gbj-buzz-linkedin"
  },
];

const SocialMediaLinks = () => {
  return (
    <div className="py-10 font-[Rubik] mb-2 shadow-md">
      <div className="uppercase text-center text-5xl  font-bold mb-6 sm:mb-4 sm:text-3xl">
        <p className="sm:flex flex-col">
          Follow us <span className="text-yellow-300">on social media</span>
        </p>
      </div>
      <div className="flex flex-row justify-center items-center gap-8 sm:gap-4">
        {LinksArray?.map((item, i) => {
          return (
            <a
              href={item.link}
              target="_blank"
              rel="noreferrer"
              className="flex justify-between items-center flex-col "
              key={i}
            >
              <div>
                <img src={item.icon} alt={item.alt} loading='lazy' className="w-14 sm:w-10" />
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SocialMediaLinks;
