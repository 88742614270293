import React from 'react'
import { ImStarHalf, ImStarFull } from 'react-icons/im'

import SocialMediaRocket from '../../../../Assets/Illustration/SocialMediaRocket.svg'

const BetterFuture = () => {
    return (
        <div className='shadow-md mb-2 pb-8 pt-2' style={{
            background: 'radial-gradient(at -5% 50%, rgba(255, 209, 35, 0.20), rgba(255, 255,255)',
        }}>
            <div className='w-10/12 m-auto mt-10 sm:w-11/12'>
                <div className='flex sm:flex-col-reverse'>
                    <div className='w-7/12 sm:w-full tab:w-1/2 m-auto'>
                        <p className='text-[42px] font-bold sm:text-3xl tab:text-3xl'>
                            Better <span className='text-yellow-400'> Future</span> learn the Secrets life Success
                        </p>
                        <p className='text-xl text-gray-600 mt-4 tab:text-[14px] sm:text-base tab:leading-5'>
                            Our unwavering commitment to achieving the highest standards propels our determination to provide exceptional services that not only meet but exceed expectations, guaranteeing unmatched contentment for our cherished clientele.
                        </p>
                        <div className='flex justify-evenly mt-12 sm:justify-between'>
                            {/* rating container */}
                            <div id='rating' className='flex items-center flex-col justify-center text-center'>
                                <p id='ratingNumber' className='text-4xl font-semibold sm:text-xl tab:text-xl' >9.8</p>
                                <div id='ratingStar' className='flex justify-evenly items-center w-24 sm:w-20 tab:w-20'>
                                    <ImStarFull color='#FFD01F' />
                                    <ImStarFull color='#FFD01F' />
                                    <ImStarFull color='#FFD01F' />
                                    <ImStarFull color='#FFD01F' />
                                    <ImStarHalf color='#FFD01F' />
                                </div>
                                <div className='sm:text-sm tab:text-sm'>
                                    <p>
                                        Rated Course
                                    </p>
                                    <p>
                                        1000+ rating user
                                    </p>
                                </div>
                            </div>
                            {/* rating container ended */}

                            {/* subscriber container */}
                            <div id='subscriber' className='text-center'>
                                <p className='text-4xl font-semibold sm:text-xl tab:text-xl'>
                                    2000+
                                </p>
                                <p className='sm:text-sm tab:text-sm'>
                                    23+ Million subscriber
                                </p>
                                <p className='text-xl sm:text-sm tab:text-sm'>
                                    Video tutorial
                                </p>
                                <p className='text-xl sm:text-sm tab:text-sm'>
                                    2000+ online course
                                </p>
                            </div>
                            {/* subscriber container ended */}

                            {/* registration  container */}
                            <div className='text-xl text-center sm:text-sm tab:text-sm'>
                                <p className='text-4xl font-semibold sm:text-xl tab:text-xl'>
                                    12K+
                                </p>
                                <p>
                                    Registration
                                </p>
                                <p>
                                    Registration on web
                                </p>
                                <a href="#" className='text-yellow-400 font-bold'>
                                    Click Here
                                </a>
                            </div>
                            {/* registration  container ended */}
                        </div>
                    </div>
                    <div className='w-5/12 flex items-center sm:w-11/12 tab:w-1/2' id='SocialMediaRocket'>
                        <div >
                            <img src={SocialMediaRocket} alt="SocialMediaRocket" className='animate-smallBounce sm:animate-[smallBounce_3.5s_infinite_linear]'
                                loading='lazy'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BetterFuture