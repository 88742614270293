// import React from "react";
// import { GoPlus } from "react-icons/go";
// import { AiOutlineMinus } from "react-icons/ai";

// const AccordionComponent = ({ item, id, active, handleToggle }) => {
//   return (
//     <div
//       className="px-4 sm:px-0 pt-2 transition-all
//         duration-300
//         ease-in last-element-child">
//       <div
//         className={` py-5 px-3 sm:p-3 border-[#434343] border-t-2 ${id === active ? "pt-10 " : "last-element-child__border-bottom"}`}>
//         <h2
//           onClick={() => handleToggle(id)}
//           className="flex flex-row justify-between items-center font-semibold text-lg tab:text-base sm:text-[16px] sm:leading-6 gap-x-1 ">
//           {item?.question}{" "}
//           <div>

//             {id === active ? (
//               <AiOutlineMinus className="w-5 h-5 hover:cursor-pointer " />
//             ) : (
//               <GoPlus className="w-5 h-5 hover:cursor-pointer" />
//             )}
//           </div>
//         </h2>
//       </div>
//       <div
//         className={`
//                 ${id === active ? " last-element-child__border-bottom transition-all duration-1000 ease- delay-800" : "hidden"}
//                 text-[#797979]
//                 text-lg
//                 mt-2
//                 m-auto
//                 text-justify
//                 sm:text-base

//             `}>
//         <p
//           className="w-11/12 m-auto transition-all
//                 duration-500 text-[15px] sm:text-[14px] leading-5 sm:leading-[normal] font- ease-in-out pb-4">
//           {item?.answer}
//         </p>
//       </div>
//     </div>
//   );
// };

// export default AccordionComponent;

import React, { useState, useEffect } from "react";
import { GoPlus } from "react-icons/go";
import { AiOutlineMinus } from "react-icons/ai";

const AccordionComponent = ({ item, id, active, handleToggle }) => {
  const [effect, setEffect] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <div className="px-4 sm:px-0 pt-2">
      <div
        className={`py-5 cursor-pointer px-3 sm:p-3 transition-all duration-[5000ms] ease-linear border-[#434343] border-t-2 ${
          id === active ? "" : "last-element-child__border-bottom"
        }`}>
        <h2
          onClick={() => {
            setEffect(true);
            const newTimeoutId = setTimeout(() => handleToggle(id), 300);
            setTimeoutId(newTimeoutId);
          }}
          className="flex flex-row justify-between items-center font-semibold text-lg tab:text-base sm:text-[16px] sm:leading-6 gap-x-1">
          {item?.question}{" "}
          <div
            className={`${effect ? "animate-spin" : ""} inline-block`}
            onClick={() => {
              setEffect(true);
              const stopAnimationTimeoutId = setTimeout(() => {
                setEffect(false);
              }, 300);
              setTimeoutId(stopAnimationTimeoutId);
            }}
            onAnimationEnd={() => setEffect(false)}>
            {id === active ? (
              <AiOutlineMinus className={`w-5 h-5 hover:cursor-pointer`} />
            ) : (
              <GoPlus className={`w-5 h-5 hover:cursor-pointer`} />
            )}
          </div>
        </h2>
      </div>
      <div
        className={`overflow-hidden transition-[max-height] duration-[5000ms] delay-100 ease-in ${
          id === active ? "max-h-52" : "max-h-0 duration-[10000ms] ease-in-out"
        }`}>
        <div className="text-[#797979] text-lg mt-2 m-auto text-justify sm:text-base">
          <p className="w-11/12 m-auto text-[15px] sm:text-[14px] leading-5 sm:leading-normal  pb-4">
            {item?.answer}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AccordionComponent;
