import React, { useRef } from "react";
import ProtectImg from "../../../Assets/Images/CyberSecurity/Protect.png";
import buttonLine from "../../../Assets/Images/CyberSecurity/buttonline.png";
import { BsArrowUpRight } from "react-icons/bs";
import { useNavigate } from "react-router";

export const Protect = ({ img, title, topDescription, bottomDescription, projectDoneTitle, yearExpTitle, yearExp,
  projectDone }) => {
  const element = useRef()
  const navigate = useNavigate()

  return (
    <div className="w-10/12 lg:w-[85%] tab:w-10/12 m-auto my-4 p-10 lg:p-4 tab:p-6 border border-gray-500 bg-gray-100 border-solid rounded-2xl sm:w-11/12 sm:p-4 sm:mt-6">
      <div className="flex justify-between sm:flex-col items-cente gap-6 tab:gap-4 sm:gap-2">
        <div className="w-[41%] tab:w-[30%] sm:w-full flex items-center ">
          <img className="w-full h-[510px] tab:w-full tab:h-full sm:w-full sm:h-80 object-cover rounded-lg" src={img} alt={title} loading='lazy'
          />
        </div>
        <div className="w-[78%] text-left sm:w-full">
          <p className="text-[30px] tab:text-2xl font-bold sm:text-xl">
            {title}
          </p>
          <p className="text-lg font-medium leading-6 text-gray-500 sm:text-xs">
            {topDescription}
          </p>
          <div className="w-36 mt-4 mb-4 border-4 border-yellow-400 border-solid tab:hidden sm:hidden"></div>
          <div className="flex flex-col gap-4">
            <div className="w-full flex justify-around items-center tab:hidden sm:hidden">
              <p className="w-28 text-5xl font-bold text-yellow-400">{projectDone}</p>

              <div className="w-10/12">
                <p className="text-[26px] font-semibold">{projectDoneTitle}</p>
                <p className="text-lg font-medium leading-6 text-gray-500">
                  Discover our successful cybersecurity projects showcasing
                  expertise and client satisfaction.
                </p>
              </div>
            </div>
            <div className="w-full flex justify-around items-center tab:hidden sm:hidden">
              <p className="w-28 text-5xl font-bold text-yellow-400">{yearExp}</p>

              <div className="w-10/12">
                <p className="text-[26px] font-semibold">{yearExpTitle}</p>
                <p className="text-lg font-medium leading-6 text-gray-500">
                  Trusted expertise built through years of cybersecurity
                  industry experience.
                </p>
              </div>
            </div>
            <p className="text-xl font-semibold tab:hidden sm:hidden">
              {bottomDescription}
            </p>
            <button className=" w-48 h-12 flex justify-around items-center px-2.5 text-base font-bold rounded-xl bg-[#FFC900] relative sm:w-28 sm:h-8 sm:rounded-lg mt-2 overflow-hidden"
              onMouseEnter={() => {
                element.current.classList.remove('-translate-x-full')
              }}
              onMouseLeave={() => {
                element.current.classList.add('-translate-x-full')
              }}
              onClick={() => navigate("/contact")}

            >
              <div ref={element} className="flex absolute w-full left-16 -translate-x-full transition-all duration-300 ease-in">
                <img
                  className="h-14  -mr-5 sm:h-8 sm:-mr-3"
                  src={buttonLine}
                  alt="buttonSingleLine1"
                  loading='lazy'
                />
                <img
                  className="h-14  sm:h-8"
                  src={buttonLine}
                  alt="buttonSingleLine1"
                  loading='lazy'
                />
              </div>
              <div className="flex absolute gap-3 items-center sm:gap-2">
                <p className="text-2xl font-bold sm:text-sm">Contact Us</p>
                <BsArrowUpRight className="text-white font-extrabold " />
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="hidden w-full text-left tab:block sm:block sm:mt-1">
        <div className="py-1 flex justify-start items-center gap-2 ">
          <p className="w-16 text-3xl font-bold text-yellow-400 ">{projectDone}</p>
          <div className="w-10/12">
            <p className="text-lg font-semibold">{projectDoneTitle}</p>
            <p className="text-sm font-medium leading-4 text-gray-500">
              Discover our successful cybersecurity projects showcasing
              expertise and client satisfaction.
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2 ">
          <p className="w-16 text-3xl font-bold text-yellow-400">{yearExp}</p>

          <div className="w-10/12">
            <p className="text-lg font-semibold">{yearExpTitle}</p>
            <p className="text-sm font-medium leading-4 text-gray-500 ">
              Trusted expertise built through years of cybersecurity industry
              experience.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
