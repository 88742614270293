import React, { useRef } from 'react'
import { BsArrowUpRight } from "react-icons/bs";

import styles from "./styles.module.css";
import { Link } from "react-router-dom";

const GetQuoteButton = ({ text }) => {
  const element = useRef()
  return (
    <div>
      <div
        className="flex shrink-0 justify-start  relative  w-[230px] h-[50px] sm:w-[133px] sm:h-[30px] bg-[#FFC900] rounded-lg sm:rounded-lg shadow-2xl overflow-hidden sm:mt-4 tab:w-[160px] tab:h-[35px]"
        onMouseEnter={() => {
          element.current.classList.add("-translate-x-0");
          element.current.classList.remove("-translate-x-full");
        }}
        onMouseLeave={() => {
          element.current.classList.remove("-translate-x-0");
          element.current.classList.add("-translate-x-full");
        }}>
        <span
          ref={element}
          className={`${styles.buttons} absolute w-[16rem] tab:w-[11rem] sm:w-[9rem] h-[48rem] -translate-x-full transition-all duration-300 ease-in`}></span>

        <Link
          to={"/contact"}
          className={`text-black flex justify-around items-center  sm:text-sm font-semibold w-full h-full text-2xl  sm:px-4 sm:py-1 py-1 px-5 shadow-custom2 bg-transparent z-10 rounded-lg tab:text-lg`}>
          {text} <BsArrowUpRight />
        </Link>
      </div>
    </div>
  )
}

export default GetQuoteButton