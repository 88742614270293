import { useEffect } from "react";
import Clients from "../Components/AboutUS/Clients/Clients";
import Introduction from "../Components/AboutUS/Inntroduction/Indtrudction";
import Members from "../Components/AboutUS/Members/Members";
import Projects from "../Components/AboutUS/Projects/Projects";
import { GetReady } from "../Components/GetReady/GetReady";
import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet";
ReactGA.initialize(process.env.REACT_APP_GA_KEY);
const Aboutus = () => {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "About us page" });
    window.scroll(0, 0)
  }, [])
  return (
    <>
      <Helmet>
        <title>About - GBJBuzz</title>
        <meta name="description" content="Learn about our commitment to innovation, expertise, and client-centric solutions that drive your success." />
      </Helmet>
      <div className="m-auto">
        <Introduction />
        <Members />
        <Clients />
        <Projects />
      </div>
      <GetReady />
    </>

  );
};

export default Aboutus;
