import React from "react";
import { WorkWithUs } from "../Components/Career/WorkWithUs/WorkWithUs";
import { FutureWork } from "../Components/Career/FutureWork/FutureWork";
import { OurCoreValues } from "../Components/Career/OurCoreValues/OurCoreValues";
import { WorkingThriving } from "../Components/Career/WorkingThriving/WorkingThriving";
import { CareerOpportunities } from "../Components/Career/CareerOpportunities/CareerOpportunities";
import { GetReady } from "../Components/GetReady/GetReady";
import ReactGA from 'react-ga4';
import { useEffect } from "react";
import { Helmet } from "react-helmet";
ReactGA.initialize(process.env.REACT_APP_GA_KEY);
export const Career = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Career Page" });
    window.scroll(0, 0)
  }, [])
  return (
    <>
      <Helmet>
        <title>Join Our Team - Explore Careers at GBJ Buzz</title>
        <meta name="description" content="We're always looking for talented individuals who share our passion for innovation. Discover how you can contribute to our journey and become a part of our dynamic team." />
      </Helmet>
      <div className="max-w-[1700px] m-auto">
        <WorkWithUs />
        <FutureWork />
        <OurCoreValues />
        <WorkingThriving />
        {/* <CareerOpportunities /> */}
      </div>
      <GetReady />
    </>
  );
};
