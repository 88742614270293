import React from "react";
import Aos from "aos";
import 'aos/dist/aos.css';

import GetQuoteForm from "./GetQuoteForm/GetQuoteForm";
import businessImg from "../../../Assets/SVG_Images/ContactUsPage/businessEthics.svg";
Aos.init()
const GetQuote = () => {
  return (
    <div id="">

      <div className="mb-2 shadow-md py-24 sm:pt-0 sm:pb-16" id="">
        <div className="font-[Poppins] flex justify-evenly items-baseline w-11/12 m-auto sm:flex-col sm:w-11/12 tab:justify-between tab:gap-3 tab:flex-col">
          {/* Text Container */}
          <div className="w-6/12 h-auto flex items-center flex-col justify-evenly sm:w-11/12 sm:m-auto tab:w-10/12 tab:m-auto">
            <div className="text-3xl font-semibold my-8 sm:text-xl sm:text-center sm:mb-0 tab:text-2xl tab:text-center">
              <p
                data-aos='fade-right'
                data-aos-duration='1000'
                data-aos-offset='400'
                data-aos-delay='100'
              >
                Connect with us to unlock endless possibilities, get a quote
                tailored for your business growth in just 5 minutes.
              </p>
            </div>

            <div className="h-96 w-11/12 sm:m-auto me-0 text-base text-gray-800 flex flex-col justify-evenly sm:text-base sm:hidden tab:text-sm tab:hidden">
              <p className="border-l border-l-1 border-gray-300 ps-5  sm:text-center  border-b-1 sm:pb-5 sm:flex sm:flex-col sm:items-center "
                data-aos='fade-up'
                data-aos-duration='1000'
                data-aos-offset='200'
                data-aos-delay='200'

              >
                <hr className="w-1/3 hidden" />
                Empowering Your Success: Discover How Our Solutions Will
                Transform Your Business
              </p>
              <p className="border-l border-l-1 border-gray-300 ps-5  sm:text-center  border-b-1 sm:pb-5 sm:flex sm:flex-col sm:items-center"
                data-aos='fade-up'
                data-aos-duration='1000'
                data-aos-offset='200'
                data-aos-delay='300'
              >
                <hr className="w-1/3 hidden" />
                Empowering Your Success:Experience Our Expertise and Innovative
                Strategies for Unprecedented Growth.
              </p>
              <p className="border-l border-l-1 border-gray-300 ps-5  sm:text-center  border-b-1 sm:pb-5 sm:flex sm:flex-col sm:items-center"
                data-aos='fade-up'
                data-aos-duration='1000'
                data-aos-offset='200'
                data-aos-delay='400'
              >
                <hr className="w-1/3 hidden" />
                Customer-Centric Approach: Partner with Us for Personalized
                Solutions and Exceptional Service Experience
              </p>
              <p className="sm:text-center"
                data-aos='fade-up'
                data-aos-duration='1000'
                data-aos-offset='200'
                data-aos-delay='400'
              >
                For general questions visit our
                <a
                  href="#QuoteForm"

                  rel="noopener noreferrer"
                  className="text-blue-600 ms-2 underline">
                  Help Center
                </a>
              </p>
            </div>
            {/* Business Ethics Image */}
            <div className="pb-0 sm:hidden tab:hidden" id="businessEthicsImg"
              data-aos='zoom-in'
              data-aos-duration='1000'
              data-aos-offset='400'
              data-aos-delay='400'
            >
              <img src={businessImg} alt="businessEthicsImg" loading='lazy' />
            </div>
            {/* Business Ethics Image ended */}
          </div>
          {/* Text Container ended*/}
          {/* Contact Form Component */}
          <GetQuoteForm />
          {/* Contact Form Component Ended */}

          {/* small device container */}

          <div className="h-auto sm:flex items-center flex-col sm:w-11/12 sm:m-auto hidden sm:mt-10 tab:flex tab:m-auto tab:w-11/12">
            <div className="w-11/12 m-auto   text-gray-800 flex flex-col text-sm text-center">
              <p className="border-gray-300 text-center sm:my-1 border-b-1  sm:flex flex-col items-center sm:leading-7"
                data-aos='fade-up'
                data-aos-duration='1000'
                data-aos-offset='300'
              >
                <hr className="w-1/3 m-auto" />
                Empowering Your Success: Discover How Our Solutions Will
                Transform Your Business
              </p>
              <p className=" border-gray-300  sm:text-center sm:my-2 border-b-1  sm:flex sm:flex-col sm:items-center sm:leading-7"
                data-aos='fade-up'
                data-aos-duration='1000'
                data-aos-offset='300'
              >
                <hr className="w-1/3 m-auto" />
                Empowering Your Success:Experience Our Expertise and Innovative
                Strategies for Unprecedented Growth.
              </p>
              <p className=" border-gray-300  sm:text-center  border-b-1  sm:flex sm:flex-col sm:items-center sm:my-2 sm:leading-7"
                data-aos='fade-up'
                data-aos-duration='1000'
                data-aos-offset='300'
              >
                <hr className="w-1/3 m-auto" />
                Customer-Centric Approach: Partner with Us for Personalized
                Solutions and Exceptional Service Experience
              </p>
              <p className="sm:text-center">
                For general questions visit our
                <a
                  href="#QuoteForm"

                  rel="noopener noreferrer"
                  className="text-blue-600 ms-2 underline">
                  Help Center
                </a>
              </p>
            </div>
          </div>
          {/* small device container ended */}
        </div>
      </div>
    </div>
  );
};

export default GetQuote;
