import React, { useState, useEffect } from "react";

import DataBackUpImg from '../../../../Assets/CardImages/Xpan/Data Backup.png';
import GoogleWebSearchImg from '../../../../Assets/CardImages/Xpan/Google Web Search.png';
import LinkImg from '../../../../Assets/CardImages/Xpan/Link.png';
import ManagementImg from '../../../../Assets/CardImages/Xpan/Management.png';
import MySpaceImg from '../../../../Assets/CardImages/Xpan/MySpace.png';
import OmniChannelImg from '../../../../Assets/CardImages/Xpan/Omnichannel.png';

import { Card } from "../../../UniversalComponent/Card/Card";
import style from "./OurExperties.module.css";

const ourExpertiseCardData = [
    {
        title: "Paid Media",
        img: OmniChannelImg,
        description:
            "We leverage our extensive network of publishers and employ programmatic solutions to drive tangible outcomes across the entire marketing funnel, enabling your brand to extend its reach and strengthen its presence",
    },
    {
        title: "Organic Search",
        img: MySpaceImg,
        description:
            "Right from the outset, our central emphasis has been to optimise your ROI. Our PPC experts are committed to establishing and refining campaigns geared toward achieving valuable results.",
    },
    {
        title: "Pay per Click",
        img: GoogleWebSearchImg,
        description:
            "Right from the outset, our central emphasis has been to optimize your ROI. Our PPC experts are committed to establishing and refining campaigns geared toward achieving valuable results.",
    },
    {
        title: "Social Media",
        img: ManagementImg,
        description:
            "We devise and execute social media plans that formulate and communicate an ideal message to your target audience, motivating them to actively interact with your brand.",
    },
    // {
    //     title: "Link building",
    //     img: LinkImg,
    //     description:
    //         "Let us construct the pathway to your digital prominence through strategic link building.",
    // },
    {
        title: "Website and app",
        img: DataBackUpImg,
        description:
            "Our application and website development services are thoughtfully customized to deliver a smooth and engaging experience to your clientele.",
    },
];

export const OurExperties = () => {
    let width = window.innerWidth;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update windowWidth state
        const handleResize = () => setWindowWidth(window.innerWidth);

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div
            style={{
                background:
                    "radial-gradient(at 90% 50%, rgb(255,246,214) 30%, rgba(251,251,251) 80%, rgba(251,251,251) 40%)",
            }}>
            <div className="w-9/12 lg:w-[85%] tab:w-10/12 m-auto pt-16 text-left sm:w-11/12 sm:pt-12">
                <p className="text-5xl tab:text-[44px] font-bold sm:text-3xl">Our <span className="text-gray-400">Expertise</span></p>
                <p className="mt-3 text-2xl tab:text-xl font-medium text-gray-800 sm:text-base ">
                    Our expertise delivers unparalleled success, merging innovation and strategy to propel businesses forward.
                </p>
                <div className={style.cardMainContainer}>
                    {ourExpertiseCardData?.map((el, i) => (
                        <Card
                            title={el.title}
                            img={el.img}
                            desc={el.description}
                            learnMore={el.learnMore}
                            key={i}
                            bg="white"
                            bor="none"
                            shadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
                            titleSize={windowWidth < 600 ? "12px" : "17px"}
                            descSize={windowWidth < 600 ? "10px" : "16px"}
                            descColor="rgba(0, 0, 0, 0.7)"
                            imgWid={windowWidth < 600 ? "44px" : "80px"}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
