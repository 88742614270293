import React, { useState, useEffect } from 'react'
import FileBase64 from 'react-file-base64';
import MDEditor from '@uiw/react-md-editor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styles from './BlogForm.module.css'
import supabase from '../../SupabaseConfig/SupabaseClient'
export const BlogForm = () => {
    const [writerName, setWriterName] = useState('GBJ BUZZ')
    const [title, setTitle] = useState('')
    const [introduction, setIntroduction] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [mediumBlogUrl, setMediumBlogUrl] = useState('')
    const [supabaseData, setSupabaseData] = useState([])
    const [markdownData, setMarkdownData] = useState('');

    const getData = async () => {
        try {
            const { data, error } = await supabase
                .from('BlogData')
                .select('*')
            setSupabaseData(data)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getData();
        let element = document.getElementsByClassName("markdownContainer")[0]
        console.log(element)
        element.style.height = "400px"
    }, [])


    const handleSubmitBlog = async (e) => {
        e.preventDefault()


        try {
            const { data, error } = await supabase
                .from('BlogData')
                .insert([{ writerName, title, introduction, imageUrl, mediumBlogUrl, description: markdownData }])
            if (data) {
                console.log('data')
            }
            if (error) {
                console.log("Form not submitted sussifuly", error)
                showToast("warn")
            }
        }
        catch (error) {
            console.log(error)
        }
        setTitle("")
        setIntroduction("")
        setImageUrl("")
        setMediumBlogUrl("")
        setMarkdownData("")
        showToast("success")
    }
    const showToast = (type) => {

        if (type === "warn")
            toast.warn("Error! Data Not Added", {
                position: toast.POSITION.TOP_CENTER
            });
        else if (type === "success") {
            toast.success("Data Successfully Added!", {
                position: toast.POSITION.TOP_CENTER
            });
        }

    };

    return (
        <div className='bg-gray-800 py-10 '>
            <div>
                <form className='flex flex-col gap-6 ' onSubmit={handleSubmitBlog}>
                    <div className='w-[40%] tab:w-[66%] sm:w-[90%] m-auto py-8 bg-white shadow-3xl px-10'>

                        <h1 className='text-center text-3xl font-bold'>Add Blog</h1>

                        <div className={styles.filedContainers}>
                            <label htmlFor="">Writer Name</label>
                            <input type="text" value={writerName} required className={styles.blogFiled}
                                onChange={(e) => setWriterName(e.target.value)} />
                        </div>
                        <div className={styles.filedContainers}>
                            <label htmlFor="">Title</label>
                            <input type="text" value={title} required className={styles.blogFiled}
                                onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <div className={styles.filedContainers}>
                            <label htmlFor="">Intriduction</label>
                            <textarea value={introduction} id="" cols="1" rows="2" className={styles.blogFiled} required onChange={(e) => setIntroduction(e.target.value)} />
                        </div>
                        <div className={styles.filedContainers}>
                            <label htmlFor="">Image Url</label>
                            <input type="url" value={imageUrl} className={styles.blogFiled} required onChange={(e) => setImageUrl(e.target.value)} />
                        </div>
                        <div className={styles.filedContainers}>
                            <label htmlFor="">Medium Blog Url</label>
                            <input type="url" value={mediumBlogUrl} className={styles.blogFiled} required onChange={(e) => setMediumBlogUrl(e.target.value)} />
                        </div>

                        {/* <div className={styles.filedContainers}> */}

                        {/* <input type="file" value={image} placeholder='Select Image' className={styles.blogFiled} onChange={(e) => setImage(e.target.value)} /> */}
                        {/* <FileBase64 type="file" value={image} multiple={false} onDone={({ base64 }) => setImage(base64)} required />
                    </div> */}
                    </div>
                    <div className='h-full  bg-slate-400'>
                        <MDEditor
                            className='markdownContainer'
                            value={markdownData}
                            onChange={setMarkdownData}
                        />
                    </div>
                    <button type='submit' className={`py-2 bg-yellow-400 font-semibold border-none`}>Submit</button>
                </form>
            </div>
            <ToastContainer autoClose={2500} />

        </div >

    )
}
