import React, { useRef } from "react";
import { BsArrowUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import Aos from 'aos';
import 'aos/dist/aos.css';

import styles from "./styles.module.css";
import computer from "../../../Assets/HeroSection/Service/Compuer.svg";
import floor from "../../../Assets/HeroSection/Service/Floor.svg";
import groupIcons from "../../../Assets/HeroSection/Service/groupIcons.svg";
import bulletLine from "../../../Assets/SVG_Images/Service/bulletLine.svg";
import illustratorRing from "../../../Assets/Illustration/IllustratorRing.svg";
import smallLogo1 from "../../../Assets/Illustration/gbj-buzz-ai-service.svg";
import smallLogo2 from "../../../Assets/Illustration/gbjbuzz-education-service.svg";
import smallLogo3 from "../../../Assets/Illustration/gbj-buzz-blockchain-service.svg";
import smallLogo4 from "../../../Assets/Illustration/gbjbuzz-marketing-service.svg";
import smallLogo5 from "../../../Assets/Illustration/gbjbuzz-cybersecurity-service.svg";

Aos.init()

const Introduction = () => {
  const element = useRef();

  const style = {
    background:
      "linear-gradient(to bottom left,rgb(254, 236,156) 10%,#fff 30%)",
    position: "relative",
    bottom: "-1px",
    // boxShadow: '3px 3px 3px rgba(0, 0, 0,1)'
  };
  return (
    <div style={style} className=""
    >
      <div className={`${styles.container} w-10/12 m-auto sm:w-full`}
        data-aos='fade-up'
        data-aos-duration='500'
        data-aos-offset='100'
      >
        {/* Introduction contianer text and image */}
        <div className="relative pt-16 sm:pt-12 sm:w-10/12 sm:m-auto">
          <div id="textDiv" className="flex justify-between">
            <div
              className="text-6xl sm:text-2xl tab:text-4xl font-bold pt-12 sm:pt-0 tab:pt-0"
              style={{ color: "rgba(0,0,0,0.5)" }}>
              <h1 className=" font-bold flex flex-col text-black">
                <span>Grow Your </span>
                <span>Business With</span>
              </h1>
              <h1 className="my-4 sm:my-0 tab:my-2">Cutting-Edge</h1>
              <h1>Technology</h1>
            </div>
            {/* this div contain image */}
            <div className="tab:w-1/2" id="imageDiv">
              <img src={computer} alt="HeroComputerImg" />
              <img src={floor} alt="HeroFloorImg" />
              <img src={groupIcons} alt="HeroGroupIcons" />
            </div>
            {/*  image div ended */}
          </div>

          <div
            className="flex shrink-0 justify-start relative mt-2 w-[230px] h-[50px] sm:w-[133px] sm:h-[30px] bg-[#FFC900] rounded-lg sm:rounded-lg shadow-2xl overflow-hidden sm:mt-4 tab:w-[160px] tab:h-[35px]"
            onMouseEnter={() => {
              element.current.classList.add("-translate-x-0");
              element.current.classList.remove("-translate-x-full");
            }}
            onMouseLeave={() => {
              element.current.classList.remove("-translate-x-0");
              element.current.classList.add("-translate-x-full");
            }}>
            <span
              ref={element}
              className={`${styles.buttons} absolute w-[16rem] tab:w-[11rem] sm:w-[9rem] h-[48rem] -translate-x-full transition-all duration-300 ease-in`}></span>

            <Link
              to={"/contact"}
              className={` flex justify-around items-center  sm:text-sm rounded-lg font-semibold w-full h-full text-2xl  sm:px-4 sm:py-1 py-3 px-5 shadow-custom2 bg-transparent z-10 sm:rounded-lg tab:text-lg`}>
              Get Quote <BsArrowUpRight />
            </Link>
          </div>
        </div>
        {/* Introduction container text and image ended*/}
        <div className="relative pt-20 sm:pt-16 ">
          <div
            className="text-3xl font-semibold sm:w-10/12 m-auto sm:text-xl tab:text-3xl" data-aos='fade-up'
            data-aos-duration='300'
            data-aos-delay="100"

          >
            <h2>Unleashing</h2>
            <h2>Your Business Potential</h2>
            <h2>Our Dynamic Services</h2>
          </div>
          {/* description container started with images */}
          <div
            id="descriptiveContainer"
            className="flex justify-between flex-row sm:flex-col sm:mt-6 tab:flex-col">
            {/* descriptive Text started */}
            <div
              className="flex justify-start w-2/3  relative sm:w-10/12 m-auto tab:w-full"
              id="servicesTextContainer"
            >
              <img src={bulletLine} alt="" />
              <div
                className="flex align-middle flex-col justify-evenly  text-xl sm:text-lg font-medium text-gray-500 tab:text-xl"


              >
                <p className=""
                  data-aos='fade-up'
                  data-aos-duration='500'
                  data-aos-offset='200'
                >
                  Your ultimate digital solution! Combining Xpan Buzz, 7i Buzz, Libex Buzz, Block X Buzz, and Cyber X Buzz for exclusive services. Unleash the power!
                </p>
                <p
                  data-aos='fade-up'
                  data-aos-duration='500'
                  data-aos-offset='200'
                >
                  Empowering growth through dynamic digital solutions. Marketing, data insights, blockchain, cybersecurity - we've got you covered!.
                </p>
                <p
                  data-aos='fade-up'
                  data-aos-duration='500'
                  data-aos-offset='200'
                >
                  Unleash potential, drive success: Our integrated approach delivers unparalleled digital results. GBJBUZZ at your service!
                </p>
              </div>
            </div>
            {/* descriptive Text ended */}
            {/* descriptive container image container started */}
            <div
              className="relative flex justify-center items-center overflow-hidden sm:w-full sm:overflow-visible"
              id="imageContainerForServices">
              <img src={illustratorRing} alt="illustratorRing img" className="sm:w-80 sm:h-80 w-11/12 h-11/12" loading='lazy' />
              <div className="absolute rounded-full animate-circle ">
                <img src={smallLogo1} alt="logo1" className="w-28 sm:w-16" loading='lazy' />
              </div>
              <div className="absolute rounded-full animate-circle2 ">
                <img src={smallLogo2} alt="logo2" className="w-28 sm:w-16" loading='lazy' />
              </div>
              <div className="absolute rounded-full animate-circle3  ">
                <img src={smallLogo3} alt="logo3" className="w-28 sm:w-16" loading='lazy' />
              </div>
              <div className="absolute rounded-full animate-circle4  ">
                <img src={smallLogo4} alt="logo4" className="w-28 sm:w-16" loading='lazy' />
              </div>
              <div className="absolute rounded-full animate-circle5 ">
                <img src={smallLogo5} alt="logo5" className="w-28 sm:w-16" loading='lazy' />
              </div>
            </div>

            {/* descriptive container image container end */}
          </div>
          {/* description container with images End */}
        </div>
      </div>
      <div className="relative mb-2 shadow-md p-4"></div>
    </div>
  );
};

export default Introduction;
