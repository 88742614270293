import React, { useRef } from "react";
import { BsArrowUpRight } from "react-icons/bs";

import styles from "./styles.module.css";
import groupImg from "../../../Assets/HeroSection/aboutus/AboutUsHero.svg";
import { Link } from "react-router-dom";
import Aos from 'aos';
import 'aos/dist/aos.css';

Aos.init()

const Introduction = () => {
  const element = useRef();
  return (
    // Introduction Container
    <section
      className={`flex justify-center items-center ${styles.section} sm:w-full w-full shrink overflow-hidden m-auto shadow-md`}
      id="introduction">
      <div className={`mx-auto sm:w-11/12 w-10/12 tab:w-11/12`}

      >
        <div className="flex justify-between items-center sm:items-start  sm:flex-col-reverse flex-row"
          data-aos='fade-up'
          data-aos-duration='300'
          data-aos-offset='100'
        >
          {/* Text Container */}
          <div className="mt-5 tab:text-2xl">
            <div className={`${styles.mainText}`}>
              <h1 className="sm:text-2xl text-5xl tab:text-3xl sm:text-left my-3 sm:my-0">
                We Build Bridges
              </h1>
              <h1 className="sm:text-2xl text-5xl tab:text-3xl my-3 sm:my-0">Between</h1>
            </div>
            <div className={styles.secondaryText}>
              <h1 className="sm:text-2xl text-5xl tab:text-3xl my-3 sm:my-0">Companies</h1>
              <h1 className="sm:text-2xl text-5xl tab:text-3xl sm:my-0 my-3">
                And Customers
              </h1>
            </div>
          </div>
          {/* Text Container Ended */}

          {/* Image Container */}
          <div className={`${styles.Img} tab:w-2/4`}>
            <img src={groupImg} alt="heroImgAboutUs" width="500" loading="lazy" />
          </div>
          {/* Image Container Ended */}
        </div>
        {/* Button Container */}
        <div
          className="flex shrink-0 justify-start  relative  w-[230px] h-[50px] sm:w-[133px] sm:h-[30px] bg-[#FFC900] rounded-lg sm:rounded-lg shadow-2xl overflow-hidden sm:mt-4 tab:w-[160px] tab:h-[35px]"
          onMouseEnter={() => {
            element.current.classList.add("-translate-x-0");
            element.current.classList.remove("-translate-x-full");
          }}
          onMouseLeave={() => {
            element.current.classList.remove("-translate-x-0");
            element.current.classList.add("-translate-x-full");
          }}>
          <span
            ref={element}
            className={`${styles.buttons} absolute w-[16rem] tab:w-[11rem] sm:w-[9rem] h-[48rem] -translate-x-full transition-all duration-300 ease-in`}></span>

          <Link
            to={"/contact"}
            className={` flex justify-around items-center  sm:text-sm font-semibold w-full h-full text-2xl  sm:px-4 sm:py-1 py-1 px-5 shadow-custom2 bg-transparent z-10 rounded-lg tab:text-lg`}>
            Contact Us <BsArrowUpRight />
          </Link>
        </div>
        {/* Button Container Ended */}

        {/* About US text Started */}
        <div className={`flex justify-center ${styles.aboutText} my-10 sm:my-2`}
          data-aos='fade-down'
          data-aos-duration='300'
          data-aos-offset='100'
        >
          <h1>ABOUT US</h1>
        </div>
        <div className={`${styles.descText} my-10 sm:my-4 text-lg sm:text-[0.9rem] sm:leading-6`}
          data-aos='fade-up'
          data-aos-duration='300'
        >
          Elevating Success, Igniting Growth. Since September 2018, GBJ Buzz has driven business triumph. From sculpting compelling brands to serving 90+ dynamic clients across sectors, we ascend relentlessly to greater heights. Your success propels our purpose, at GBJ Buzz, you're the priority. With fervent zeal, we make your accomplishment the heartbeat of GBJ Buzz, where innovation meets passion
        </div>
        {/* About us text Ended */}
      </div>
    </section>
    // Introduction Container Ended
  );
};

export default Introduction;


