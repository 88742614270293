
import React, { useEffect, useState } from 'react'
import { client } from '../../sanity'
import PortableText from "react-portable-text";
import imageUrlBuilder from '@sanity/image-url';
import { createClient } from '@sanity/client'
import Moment from 'react-moment';
import { useParams } from 'react-router-dom';

const BlogDetail = () => {

    const params = useParams()
    console.log("PARRRRR",params)
    const {id} = params;

    const [blogData, setBlogData] = useState('')

    const builder = imageUrlBuilder(client)
    // function urlFor(source) {
    //     return builder.image(source)
    // }
    // console.log("SANITYYYYYY", blogData)

    // const fetchData = async () => {
    //     const query = `*[_type == "blog"]`;
    //     const blogs = await client.fetch(query);
    //     console.log("SANITY QUERY", blogs)
    // }
    const fetchData1 = async () => {
        const query = `*[_id == "${id}" ]`;
        const blogs = await client.fetch(query);
        setBlogData(blogs[0])
        // console.log("SANITY DATA AAAAAAAAAAAAAAAAAAA", blogs)
        // setBlogData(blogs)
    }
    useEffect(() => {
        // fetchData()
        fetchData1()

    }, [])

    // console.log("BUILDER IMAGE",builder?.image(blogData[0].blogimage).width(500).url())
    console.log("BLOGDAAAAA",blogData)
    return (
        <div className='w-7/12 tab:w-10/12 sm:w-11/12 m-auto my-16'>
            {blogData._id &&
                <>
                    <div>
                        <h1 className='text-[44px] leading-[2.6rem] tab:text-4xl sm:text-3xl font-bold '>{blogData.title}</h1>
                    </div>
                    <div className='flex gap-x-2 mt-6'>
                        <img src='/logo512.png' alt="gbj-buzz-logo" className='w-11 h-11 rounded-full' />
                        <div>
                            <p className='font-semibold'>GBJ_BUZZ</p>
                            <div className='text-[14px]'>
                                <Moment format="D MMM YYYY" withTitle>
                                    {blogData[0]?.createdAt}
                                </Moment>
                            </div>
                        </div>

                    </div>
                    <img src={`${builder.image(blogData?.blogimage).url()}`} alt='blog-image' className='mt-8' />
                    {/* <h3 className='md:text-2xl text-xl font-medium mt-4 mb-6'>{blogData.metadesc}</h3> */}

                    <PortableText
                        // Pass in block content straight from Sanity.io
                        content={blogData.content || []}
                        projectId={process.env.REACT_APP_SANITY_PROJECT_ID}
                        dataset={process.env.REACT_APP_SANITY_DATASET}
                        // Optionally override marks, decorators, blocks, etc. in a flat
                        // structure without doing any gymnastics
                        serializers={{
                            h1: (props) => <h1 style={{}} className='font-bold text-4xl sm:text-3xl mt-4' {...props} />,
                            h2: (props) => <h2 style={{}} className='font-bold text-[38px] sm:text-2xl mt-4' {...props} />,
                            h3: (props) => <h3 style={{}} className='font-bold text-3xl sm:text-[30px]  mt-4' {...props} />,
                            h3: (props) => <h3 style={{}} className='font-bold text-3xl sm:text-[26px] mt-4' {...props} />,
                            h3: (props) => <h3 style={{}} className='font-bold text-3xl sm:text-[24px]  mt-4' {...props} />,
                            h4: (props) => <h4 style={{}} className='font-bold text-2xl sm:text-xl mt-4' {...props} />,
                            h5: (props) => <h5 style={{}} className='font-bold text-xl mt-4' {...props} />,
                            h6: (props) => <h6 style={{}} className='font-bold text-lg mt-4' {...props} />,
                            normal: (props) => <p style={{}} className='mt-4 text-lg sm:text-base' {...props} />, 
                            ol: ({ children }) => <ol className="special-list-item list-decimal mt-2 text-lg sm:text-base">{children}</ol>,
                            ul: ({ children }) => <ul className="special-list-item list-disc mt-2  text-lg sm:text-base">{children}</ul>,
                            li: ({ children }) => <li className="special-list-item ml-4 mt-2  text-lg sm:text-base">{children}</li>,
                        }}
                    />
                </>
            }
        </div>
    )
}
export default BlogDetail;

