import React from 'react'
import XpanImg from '../../../../Assets/CardImages/Xpan/WeAreHerePromote.svg'
import { Protect } from '../../../UniversalComponent/Protect/Protect'

export const WhyWeArePromote = () => {
  return (
    <div className='mt-16 mb-10 sm:mt-10 sm:mb-6'>
      <Protect
        img={XpanImg}
        title="We are Here Promote "
        topDescription="Our committed experts excel in creative approaches that stimulate expansion and enhance prominence. Collaborate with us to metamorphose your brand, product, or concept into a captivating initiative that strikes a chord and achieves outcomes."
        bottomDescription=" Let us be your strategic partner in crafting a journey of excellence that captivates your target audience and leaves a lasting impact."
        projectDone="91+"
        yearExp="06+"
        projectDoneTitle="Projects Done"
        yearExpTitle="Years of Experience"
        projectDescription="Explore our accomplished cybersecurity initiatives, demonstrating our proficiency and customer contentment."
        experecneDescription="Established credibility forged over years of experience in the cybersecurity sector."
      />
    </div>
  )
}
