import React, { useState, useRef } from "react";

import IndiaFlag from "../../../../Assets/SVG_Images/ContactUsPage/India.svg";
import supabase from "../../../../SupabaseConfig/SupabaseClient";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import linebg2 from '../../../../Assets/Images/LandingPage/buttonline.png'
import styles from '../../../Services/Cards/styles.module.css'

const GetQuoteForm = () => {
  const [alert, setAlert] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    workEmail: "",
    companyName: "",
    companySize: "",
    companyWebsite: "",
    phoneNumber: "",
    job: "",
    jobDescription: "",
    jobTitle: "",
    targetAudience: "",
  });
  const element = useRef()

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    if ({ ...formData }) {
      try {
        const { data, error } = await supabase
          .from('GetQuoteForm')
          .insert({ ...formData })
        if (error) {
          toast.error("Your data not submitted!")
        }
        else {
          toast.success("Data Successfully Added!")
        }
      } catch (error) {
        console.log(error)
        toast.error("Server Error!")
      }
    }
    setFormData({
      firstName: "",
      lastName: "",
      workEmail: "",
      companyName: "",
      companySize: "",
      companyWebsite: "",
      phoneNumber: "",
      job: "",
      jobDescription: "",
      jobTitle: "",
      targetAudience: "",
    })
  };

  return (
    <div className="w-[43%] sm:w-full rounded-xl shadow-2xl bg-transparent tab:w-10/12 tab:m-auto">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <div
        className="font-[Poppins] bg-white focus:outline-yellow-300 shadow-[-14px_-12px_5px_-3px]  shadow-yellow-400 p-8 rounded-xl mt-4 z-10 border border-gray-100 relative sm:pt-4 sm:shadow-[-12px_-1px_0_-5px] sm:shadow-yellow-200 tab:px-4 sm:px-4 "
        id="QuoteForm">
        <h1 className="text-2xl sm:text-xl mb-6 font-light sm:text-gray-700 ">
          Contact our Sales team
        </h1>
        <form method="post" onSubmit={handleSubmit}>
          <div className="grid gap-6 mb-2 grid-cols-2 sm:grid-cols-1 tab:gap-2">
            <div>
              <label
                htmlFor="first_name"
                className="block mb-2 text-sm font-medium tex2/4t-gray-900 ">
                First name
              </label>

              <input
                type="text"
                id="first_name"
                className="bg-white focus:outline-yellow-300 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-yellow-300 block w-full p-2.5  "
                placeholder="Enter First Name"
                required
                value={formData.firstName}
                onChange={handleChange}
                name="firstName"
              />
            </div>
            <div>
              <label
                htmlFor="last_name"
                className="block mb-2 text-sm font-medium text-gray-900 ">
                Last name
              </label>

              <input
                type="text"
                id="last_name"
                className="bg-white focus:outline-yellow-300 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-yellow-300 block w-full p-2.5"
                placeholder="Enter Last Name"
                required
                value={formData.lastName}
                onChange={handleChange}
                name="lastName"
              />
            </div>
          </div>
          <div>
            <div className="my-2">
              <label
                htmlFor="companyName"
                className="block mb-2 text-sm font-medium text-gray-900 ">
                Company Name
              </label>

              <input
                type="text"
                id="companyName"
                className="bg-white focus:outline-yellow-300 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-yellow-300 block w-full p-2.5"
                placeholder="Enter Company Name"
                required
                value={formData.companyName}
                onChange={handleChange}
                name="companyName"
              />
            </div>
            <div>
              <div className="my-2">
                <label
                  htmlFor="companyWebsite"
                  className="block mb-2 text-sm font-medium text-gray-900 ">
                  Company Website
                </label>

                <input
                  type="url"
                  id="companyWebsite"
                  className="bg-white focus:outline-yellow-300 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-yellow-300 block w-full p-2.5"
                  placeholder="Enter Company Webiste https://"
                  required
                  value={formData.companyWebsite}
                  onChange={handleChange}
                  name="companyWebsite"
                />
              </div>
            </div>
          </div>
          <div className="grid gap-6 mb-2 grid-cols-2 sm:grid-cols-1 tab:gap-2 tab:mb-2 ">
            <div>
              <label
                htmlFor="workEmail"
                className="block mb-2 text-sm font-medium text-gray-900 ">
                Work Email
              </label>

              <input
                type="email"
                id="workEmail"
                className="bg-white focus:outline-yellow-300 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-yellow-300 block w-full p-2.5"
                placeholder="Enter Your Email"
                required
                value={formData.workEmail}
                onChange={handleChange}
                name="workEmail"
              />
            </div>

            <div>
              <label
                htmlFor="jobTitle"
                className="block mb-2 text-sm font-medium text-gray-900 ">
                Job Title
              </label>

              <input
                type="text"
                id="jobTitle"
                className="bg-white focus:outline-yellow-300 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-yellow-300 block w-full p-2.5"
                placeholder="Enter title here"
                required
                value={formData.jobTitle}
                onChange={handleChange}
                name="jobTitle"
              />
            </div>
          </div>

          <div className="grid gap-2 mb-2 grid-cols-5 sm:grid-cols-1">
            <div className="col-span-3">
              <label
                htmlFor="phone"
                className="block mb-2 text-sm font-medium text-gray-900 ">
                Phone number
              </label>

              <div className="flex">
                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md ">
                  <img
                    src={IndiaFlag}
                    alt="indiaFlag"
                    className=" text-gray-500 "
                    aria-hidden="true"
                  />
                </span>
                <input
                  type="tel"
                  id="phoneNumber"
                  className="rounded-none rounded-r-lg bg-white focus:outline-yellow-300 border text-gray-900 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 "
                  placeholder="1234-5678-90"
                  pattern="[0-9]{10}"
                  required
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  name="phoneNumber"
                />
              </div>
            </div>
            <div className="col-span-2">
              <label
                htmlFor="companySize"
                className="block mb-2 text-sm font-medium text-gray-900 ">
                Company Size
              </label>

              <input
                type="number"
                id="companySize"
                className="bg-white focus:outline-yellow-300 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-yellow-300 block w-full p-2.5"
                placeholder="Number"
                required
                value={formData.companySize}
                onChange={handleChange}
                name="companySize"
              />
            </div>
          </div>

          <div className="grid gap-6 mb-2 grid-cols-2 mt-2 sm:grid-cols-1 tab:gap-2 tab:mb-2 ">
            <div className="">
              <label
                htmlFor="job"
                className="block mb-2 text-sm font-medium text-gray-900 ">
                Job
              </label>

              <input
                type="text"
                id="job"
                className="bg-white focus:outline-yellow-300 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-yellow-300 block w-full p-2.5"
                placeholder="Job"
                required
                value={formData.job}
                onChange={handleChange}
                name="job"
              />
            </div>
            <div className="">
              <label
                htmlFor="targetAudience"
                className="block mb-2 text-sm font-medium text-gray-900 ">
                Target Audience{" "}
              </label>

              <input
                type="number"
                id="targetAudience"
                className="bg-white focus:outline-yellow-300 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-yellow-300 block w-full p-2.5"
                placeholder="target Audience"
                required
                value={formData.targetAudience}
                onChange={handleChange}
                name="targetAudience"
              />
            </div>
          </div>
          <div className="grid gap-2 mb-6  mt-2">
            <label
              htmlFor="jobDescription"
              className="block mb-2 text-sm font-medium text-gray-900">
              Job Description
            </label>
            <textarea
              id="jobDescription"
              rows="4"
              className="block p-2.5 w-full text-sm text-gray-900 bg-white focus:outline-yellow-300 rounded-lg border border-gray-300 focus:ring-yellow-400 focus:border-yellow-400"
              placeholder="Write your thoughts here..."
              value={formData.jobDescription}
              onChange={handleChange}
              name="jobDescription"></textarea>

          </div>
          {/* Button Div */}
          <div className="flex justify-center flex-col items-center">
            <div
              className="flex shrink-0 justify-start relative mt-2  bg-[#FFC900] rounded-xl sm:rounded-lg shadow-2xl overflow-hidden w-[185px] sm:w-[137px] sm:text-xs text-lg font-semibold "
              onMouseEnter={() => {
                element.current.classList.add("translate-x-20");
                element.current.classList.remove("-translate-x-40");
                element.current.classList.remove("hidden");
              }}
              onMouseLeave={() => {
                element.current.classList.remove("translate-x-20");
                element.current.classList.add("-translate-x-40");
                element.current.classList.add("hidden");
              }}>
              <span
                ref={element}
                className={`${styles.buttons} absolute  -translate-x-40 sm:w-28 sm:h-10 h-14 -top-1 transition-all duration-300 ease-linear delay-300 `}>
                <img src={linebg2} alt={`linedBg `} className="-mr-5 " />
                <img src={linebg2} alt={`linedBg `} />
              </span>

              <button
                type="submit"
                className={` flex justify-evenly items-center  sm:text-sm rounded-lg font-semibold w-full h-full text-xl  sm:px-4 sm:py-1 shadow-custom2 bg-transparent p-2 z-10 sm:rounded-lg`}>
                Submit
              </button>
            </div>
          </div>
          {/* Button Div Ended */}
        </form>
      </div>
    </div>
  );
};

export default GetQuoteForm;
