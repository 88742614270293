import React, { useEffect } from 'react'

import HeroSection from '../Components/ServicesPages/BlockXBuzz/HeroSection/HeroSection'
import AboutService from '../Components/ServicesPages/BlockXBuzz/AboutService/AboutService'
import BlockChainProcess from '../Components/ServicesPages/BlockXBuzz/BlockChainProcess/BlockChainProcess'
import Platform from '../Components/ServicesPages/BlockXBuzz/BlockchainPlatform/Platform'
import { OurServices } from '../Components/ServicesPages/BlockXBuzz/OurServices/OurServices'
import { WeAreHereToAmplify } from '../Components/ServicesPages/BlockXBuzz/WeAreHereToAmplify/WeAreHereToAmplify'
import { GetReady } from '../Components/GetReady/GetReady'
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet'
ReactGA.initialize(process.env.REACT_APP_GA_KEY);

const BlockX = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "BlockX Page" });
        window.scroll(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                <title>Block X - Pioneering Blockchain Solutions for Tomorrow</title>
                <meta name="description" content="Your gateway to cutting-edge blockchain solutions. Discover the transformative power of blockchain technology, our team of experts is committed to delivering comprehensive blockchain services tailored to your unique business needs." />
            </Helmet>
            <div className="max-w-[1700px] m-auto">
                <HeroSection />
                <OurServices />
                <WeAreHereToAmplify />
                <AboutService />
                <BlockChainProcess />
                <Platform />
            </div>
            <GetReady />
        </>
    )
}

export default BlockX