import React, { useEffect, useRef, useState } from 'react'
import BookImg from '../../../../Assets/CardImages/Libex/Book.png';
import WindowsImg from '../../../../Assets/CardImages/Libex/Windows8 Tablet.png';
import BrainImg from '../../../../Assets/CardImages/Libex/Willingness To Learn.png';
import CommunityImg from '../../../../Assets/CardImages/Libex/fluent_people-community-32-filled.png';
import ClassroomImg from '../../../../Assets/CardImages/Libex/Classroom.png';
import SentImg from '../../../../Assets/CardImages/Libex/Sent.png';
import buttonLine from "../../../../Assets/Images/CyberSecurity/buttonline.png";
import { Card } from '../../../UniversalComponent/Card/Card';
import style from "./OurExperties.module.css";
import { BsArrowUpRight } from 'react-icons/bs'
import { CardComponent } from '../../CardComponent/CardComponent';
import { useNavigate } from 'react-router-dom';

// bg color gradient = linear-gradient(to right, #FFEFB1 55%, #FFFDEF


const cardData = [
    {
        title: "Discover Your Potential",
        img: BookImg,
        description:
            "Discover your inherent capabilities and interests by means of customised evaluations.",
    },
    {
        title: "Chart Your Course",
        img: WindowsImg,
        description:
            "Unearth your innate potential and passions through tailored assessments.",
    },
    {
        title: "Master the Craf",
        img: BrainImg,
        description:
            "Acquire hands-on proficiency by engaging in practical projects and tackling real-world obstacles.",
    },
    {
        title: "Elevate with Excellence",
        img: SentImg,
        description:
            "Acquire industry-acknowledged certifications to establish unparalleled credibility.",
    },
    {
        title: "Forge Connections",
        img: CommunityImg,
        description:
            "Become part of an active community, connect with like-minded individuals, and engage in collaborative efforts with fellow members.",
    },
    {
        title: "Unleash Your Success",
        img: ClassroomImg,
        description:
            "Embark on a journey towards success, armed with freshly acquired skills, self-assurance, and boundless opportunities.",
    },
];
export const GrowUpSkills = () => {
    const navigate = useNavigate()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const SubscribeButton = useRef();

    useEffect(() => {
        // Function to update windowWidth state
        const handleResize = () => setWindowWidth(window.innerWidth);

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <div className=' shadow-md' style={{
            background: 'radial-gradient(at -5% 50%, rgba(255, 209, 35, 0.20), rgba(255, 255,255)'
        }}>
            <div className='w-10/12 sm:w-11/12 m-auto py-8 sm:py-4'>
                <div className="w-48 m-auto mb-8 border-[3px] border-yellow-400 border-solid sm:w-24 sm:border-2 sm:my-6"></div>
                <div className='text-center mb-10 sm:mb-6'>
                    <p className='text-[44px]  tab:text-3xl sm:text-3xl font-bold text-gray-400 leading-[70px] lg:leading-[50px]'><span className='text-black'>Grow up your</span> skills <span className='text-black'>With</span> Libex BUZZ</p>
                </div>
                <div className={style.cardMainContainer}>
                    {cardData?.map((el, i) => (
                        <CardComponent
                            title={el.title}
                            img={el.img}
                            desc={el.description}
                            learnMore={el.learnMore}
                            key={i}
                            bg="#ffc900"
                            bor="none"
                            titleSize={windowWidth < 600 ? "12px" : "18px"}
                            descSize={windowWidth < 600 ? "10px" : "17px"}
                            descColor="rgba(0, 0, 0, 0.7)"
                            imgWid={windowWidth < 600 ? "44px" : "90px"}
                            id={i}
                        />
                    ))}
                </div>
                <div className='flex justify-center pt-4 pb-3'>
                    <button
                        className=" w-56 h-[50px] sm:w-44  sm:h-[40px] px-4 flex justify-around items-center text-base tab:text-[14px] sm:text-[14px] font-bold rounded-lg bg-yellow-400 relative overflow-hidden
              "
                        onMouseEnter={() => {
                            SubscribeButton.current.classList.remove("-translate-x-full");
                            SubscribeButton.current.classList.remove("left-1");
                            SubscribeButton.current.classList.add("right-2");
                        }}
                        onMouseLeave={() => {
                            SubscribeButton.current.classList.add("-translate-x-full");
                            SubscribeButton.current.classList.add("left-1");
                            SubscribeButton.current.classList.remove("right-2");
                        }}>
                        <div
                            ref={SubscribeButton}
                            className="flex absolute left-1 -translate-x-full transition-all duration-150 ease-in">
                            <img
                                className="w-12 -mr-6 "
                                src={buttonLine}
                                alt="buttonline"
                            />
                            <img className="w-12 " src={buttonLine} alt="buttonline" />
                        </div>
                        <div onClick={() => navigate("/page-not-found")} className="flex absolute gap-3 items-center text-xl sm:text-base font-bold">
                            <span>Start Learning</span>
                            <BsArrowUpRight />
                        </div>
                    </button>
                </div>
            </div>
        </div>

    )
}
