import React, { useRef, useState } from 'react'
import { RxCross2 } from 'react-icons/rx'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import IndiaFlag from "./../../Assets/SVG_Images/ContactUsPage/India.svg";

const PopUpForm = ({ hide, setHide, setFormData, formData, handleSubmit }) => {
    const element = useRef()

    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };



    return (
        <div className='w-screen h-screen fixed z-[200] top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] p-4 bg-black bg-opacity-70 backdrop-blur-[2px]'>

            <div ref={element} className={`fixed z-[200] top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] border-t-[10px] border-[#FFC900] w-[30rem] bg-white  px-4 sm:w-11/12 sm:m-auto blur-none sm:-translate-x-[50%] ${hide ? '' : 'hidden'} rounded-lg`}>
                <div
                    className=" font-[Poppins focus:outline-yellow-300 p-2 py-2 rounded-xl z-10  relative sm:pt-4 sm:mt-0 sm:px-4"
                    id="salesContact">
                    <div className='flex justify-end py-'>
                        <RxCross2 fontSize={30} fontWeight={600} className='cursor-pointer' onClick={() => {
                            element.current.classList.add('hidden')
                            setHide(!hide)
                        }
                        } />

                    </div>
                    {/* <h1 className="text-2xl font-semibold sm:text-xl mb-4 sm:text-gray-700 sm:mb-2 tab:text-xl">
                        Contact our Sales team
                    </h1>
                    <form method="post" onSubmit={handleSubmit}>
                        <div className="grid gap-2 mb-2 sm:mb-2 grid-cols-2 tab:mb-2">
                            <div className="col-span-2">
                                <label
                                    htmlFor="first_name"
                                    className="block mb-1 text-sm font-medium tex2/4t-gray-900 ">
                                    Full name
                                </label>

                                <input
                                    type="text"
                                    id="fullName"
                                    className="bg-white focus:outline-yellow-300 border border-gray-300 text-gray-900 text-sm  focus:border-yellow-300 block w-full p-2.5 rounded-lg "
                                    placeholder="Enter First Name"
                                    required
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    name="fullName"
                                />
                            </div>

                            <div className="col-span-2">
                                <label
                                    htmlFor="workEmail"
                                    className="block mb-1 text-sm font-medium text-gray-900 ">
                                    Work Email
                                </label>

                                <input
                                    type="email"
                                    id="workEmail"
                                    className="bg-white focus:outline-yellow-300 border border-gray-300 text-gray-900 text-sm  focus:border-yellow-300 block w-full p-2.5 rounded-lg"
                                    placeholder="Enter Your Email"
                                    required
                                    value={formData.workEmail}
                                    onChange={handleChange}
                                    name="workEmail"
                                />
                            </div>
                        </div>
                        <div className="">
                            <label
                                htmlFor="phone"
                                className="block mb-1 text-sm font-medium text-gray-900 ">
                                Phone number
                            </label>

                            <div className="flex">
                                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-lg ">
                                    <img
                                        src={IndiaFlag}
                                        alt="indiaFlag"
                                        className=" text-gray-500 "
                                        aria-hidden="true"
                                    />
                                </span>
                                <input
                                    type="tel"
                                    id="phoneNumber"
                                    className="bg-white focus:outline-yellow-300 border text-gray-900 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 rounded-r-lg"
                                    placeholder="1234-5678-90"
                                    pattern="[0-9]{10}"
                                    required
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    name="phoneNumber"
                                />
                            </div>
                        </div>
                        <div className="flex justify-center flex-col items-center text-center my-2 sm:text-sm tab:text-sm">
                            <button
                                type="submit"
                                className="text-white bg-black focus:ring-4  text-lg font-semibold w-full px-5 py-2 text-center my-2  tab:text-base rounded-lg">
                                Submit
                            </button>
                        </div>
                    </form> */}
                </div>
                <iframe frameborder="0" style={{
                    width: "100%",
                    border: "none"
                }}
                    className='h-[501px] sm:h-[560px]'
                    src='https://forms.zohopublic.in/gbjbuzz/form/SalesForm/formperma/ccxG6iJy_8f9bwGUw12TG1hOIbiDqXvIdL9eurPOLMA'></iframe>
            </div>
        </div>
    )
}

export default PopUpForm;