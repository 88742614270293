import React from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai'

import HealthCare from '../../../../Assets/CardImages/7iBuzz/health-book-fill-1.png.svg'
import LifeScience from '../../../../Assets/CardImages/7iBuzz/microscope-fill.png.svg'
import Banking from '../../../../Assets/CardImages/7iBuzz/bank-fill.png.svg'
import Retail from '../../../../Assets/CardImages/7iBuzz/shopping-bag-3-fill.png.svg'
import SupplyChain from '../../../../Assets/CardImages/7iBuzz/global-fill.png.svg'
import Pencil from '../../../../Assets/CardImages/7iBuzz/pencil-fill.png.svg'
import { useNavigate } from 'react-router-dom'

const array = [
    {
        img: HealthCare,
        heading: 'Healthcare & Biotech',
        desc: "Leverage AI-powered healthcare solutions to enhance diagnostics and care delivery, improve patient-doctor communication, and optimise both hospital operations and population health management."
    },
    {
        img: LifeScience,
        heading: 'Life Sciences',
        desc: "Leverage our potent and user-friendly AI solutions to streamline the creation of novel pharmaceuticals and repurpose existing drugs, gain valuable insights into potential drug candidates, targets, and biomarkers, and enhance the efficiency of clinical trials."
    },
    {
        img: Banking,
        heading: 'Finance & Banking',
        desc: "Artificial intelligence plays a pivotal role in the finance and banking sectors by bolstering risk assessment, fraud detection, customer service, trading, insights, compliance, data analytics, cybersecurity, and various other aspects. This contributes to heightened efficiency and enhanced accuracy within the industry."
    },
    {
        img: Retail,
        heading: 'Retail & E-commerce',
        desc: "Leverage AI in retail to expedite decision-making, enhance the precision of demand forecasting, streamline inventory management, optimise the flow of goods, and gain deeper insights into your customers"
    },
    {
        img: SupplyChain,
        heading: 'Supply Chain & Logistics',
        desc: "Leverage AI-powered software to gain complete oversight of shipments, streamline paperwork, and simplify communication with vendors, while optimising delivery and warehouse operations."
    },
    {
        img: Pencil,
        heading: 'Education & eLearning',
        desc: "Leverage artificial intelligence in education to tailor learning programs, generate intelligent content, streamline administrative tasks, and ensure inclusive educational opportunities for students with special requirements."
    },
]

const AiConsulting = () => {
    const navigate = useNavigate()
    return (
        <div className='py-8 mb-2 shadow-md' style={{
            background:
                "radial-gradient(at 90% 50%, rgba(255,246,214,0.3), rgba(255,255,255) 80%, rgba(255,255,255) 40%)",
        }}>
            <div className='w-10/12 m-auto  tab:w-11/12 sm:w-11/12'>
                <h2 className='w-8/12 m-auto tab:w-full sm:w-full text-4xl text-center font-bold   tab:text-3xl sm:text-2xl'>
                    AI consulting and development services <span className='text-gray-400'>for your industry</span>
                </h2>
                <div className='flex flex-wrap justify-between gap-6 mt-10 sm:flex-col sm:gap-2'>
                    {
                        array?.map((ele, i) => {
                            return <Card key={i} {...ele} />
                        })
                    }
                </div>
                <div>
                    <button className='bg-[#FFC900] px-4 py-2 flex gap-2 items-center rounded-full mt-8 font-medium sm:text-sm'
                        onClick={() => navigate('/contact')}
                    >
                        Let’s solve your challenges with AI <AiOutlineArrowRight />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AiConsulting

const Card = ({ img, heading, desc }) => {
    return (
        <div className='w-[30%] sm:w-full sm:my-2 flex flex-col '>
            <div>
                <img src={img} alt={heading} loading='lazy' />
            </div>
            <div>
                <h4 className='text-xl font-semibold tab:text-lg'>
                    {heading}
                </h4>
                <p className='text-gray-600 tab:text-sm'>
                    {desc}
                </p>
            </div>
        </div>
    )
}