import React, { useRef } from "react";
import { BsArrowUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import Aos from 'aos';
import 'aos/dist/aos.css';

import styles from "../styles.module.css";
import linebg2 from '../../../../Assets/linedbg2.png'

Aos.init()

const Card = ({ heading, subHeading, desc, img, id, link }) => {
  const element = useRef();

  if (id === 0 || id % 2 === 0) {
    return (
      <div className="flex justify-between gap-6 w-10/12  border shadow-xl p-12 rounded-xl sm:w-full sm:p-3 relative sm:my-2 tab:w-full"
        data-aos='fade-up'
        data-aos-duration='500'
        data-aos-offset='200'
      >
        {/* text container */}
        <div className="flex justify-evenly flex-col w-2/3 sm:w-2/4 ">
          <div className="font-bold text-5xl sm:text-lg sm:font-semibold relative mb-4 tab:text-4xl">
            <h1>{heading}</h1>
          </div>
          <div className="font-bold text-2xl sm:text-xs sm:font-medium mb-3">
            <h3>{subHeading}</h3>
          </div>
          <div className="sm:text-xxs text-lg sm:font-light mb-6 text-justify">
            <p>{desc}</p>
          </div>
          {/* Button learn More */}

          <div
            className="flex shrink-0 justify-start relative mt-2  bg-[#FFC900] rounded-xl sm:rounded-lg shadow-2xl overflow-hidden w-[185px] sm:w-[137px] sm:text-xs text-lg font-semibold "
            onMouseEnter={() => {
              element.current.classList.add("-translate-x-0");
              element.current.classList.remove("-translate-x-full");
              element.current.classList.remove("hidden");
            }}
            onMouseLeave={() => {
              element.current.classList.remove("-translate-x-0");
              element.current.classList.add("-translate-x-full");
              element.current.classList.add("hidden");
            }}>
            <span
              ref={element}
              className={`${styles.buttons} absolute  -translate-x-full sm:w-28 sm:h-10 h-14 -top-1 transition-all duration-300 ease-linear delay-300`}>
              <img src={linebg2} alt={`linedBg for ${heading}`} />
            </span>

            <Link
              to={link}
              className={` flex justify-evenly items-center  sm:text-sm rounded-lg font-semibold w-full h-full text-xl  sm:px-4 sm:py-1 shadow-custom2 bg-transparent p-2 z-10 sm:rounded-lg`}>
              Learn More <BsArrowUpRight />
            </Link>
          </div>

          {/* button ended */}
        </div>
        {/* Card Image Container */}
        <div
          className="w-2/3 sm:w-2/4 shrink relative top-3 flex items-center "
          id="cardImageDiv">
          <img src={img} alt={`${heading} Image`} />
        </div>
        {/* Card Image Container end */}
      </div>
    );
  } else {
    return (
      <div className="flex justify-between gap-6 w-10/12 lg:w-10/12 border shadow-xl p-12 rounded-xl sm:w-full h-1/3 sm:p-3 my-5 sm:my-3 tab:w-full"
        data-aos='fade-up'
        data-aos-duration='500'
        data-aos-offset='200'
      >
        {/* Card Image Container */}
        <div
          className="w-2/3 sm:w-2/4 shrink relative top-3 flex items-center "
          id="cardImageDiv">
          <img src={img} alt={`${heading} Image`} />
        </div>
        {/* Card Image Container end */}

        {/* text container */}
        <div className="flex justify-evenly flex-col w-2/3 sm:w-2/4">
          <div className="font-bold tab:text-4xl text-5xl sm:text-lg sm:font-semibold relative mb-4 sm:mb-0">
            <h1>{heading}</h1>
          </div>
          <div className="font-bold text-2xl sm:text-xs sm:font-medium mb-3 sm:mb-0">
            <h3>{subHeading}</h3>
          </div>
          <div className="sm:text-xxs text-xl sm:font-light mb-6 text-justify sm:mb-0">
            <p>{desc}</p>
          </div>
          {/* Button learn More */}

          <div
            className="flex shrink-0 justify-evenly relative mt-2  bg-[#FFC900] rounded-xl sm:rounded-lg shadow-2xl overflow-hidden w-[185px] sm:w-[137px] sm:text-xs text-xl font-semibold "
            onMouseEnter={() => {
              element.current.classList.add("-translate-x-0");
              element.current.classList.remove("-translate-x-full");
              element.current.classList.remove("hidden");
            }}
            onMouseLeave={() => {
              element.current.classList.remove("-translate-x-0");
              element.current.classList.add("-translate-x-full");
              element.current.classList.add("hidden");
            }}>
            <span
              ref={element}
              className={`${styles.buttons} absolute sm:w-28 sm:h-10 h-14 -top-1 -translate-x-full transition-all duration-300 ease-linear delay-300`}>
              <img src={linebg2} alt={`linedBg for ${heading}`} loading='lazy' />
            </span>

            <Link
              to={link}
              className={` flex justify-evenly items-center  sm:text-sm rounded-xl font-semibold w-full h-full text-xl  sm:px-4 sm:py-1 shadow-custom2 bg-transparent sm:rounded-lg p-2 z-10 `}>
              Learn More <BsArrowUpRight />
            </Link>
          </div>

          {/* button ended */}
        </div>
      </div>
    );
  }
};

export default Card;
