import React, { useEffect } from 'react'
import Introduction from '../Components/ServicesPages/XpanBuzz/Introduction/Introduction'
import WhyChoose from '../Components/ServicesPages/XpanBuzz/WhyChoose/WhyChoose'
import Testimonials from '../Components/ServicesPages/XpanBuzz/Testimonials/Testimonials'
import { OurServices } from '../Components/ServicesPages/XpanBuzz/OurServices/OurServices'
import { OurExperties } from '../Components/ServicesPages/XpanBuzz/OurExperties/OurExperties'
import { WhyWeArePromote } from '../Components/ServicesPages/XpanBuzz/WhyWeArePromote/WhyWeArePromote'
import { GetReady } from '../Components/GetReady/GetReady'
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet'
ReactGA.initialize(process.env.REACT_APP_GA_KEY);
const XpanBuzz = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Xpan Buxx Page" });
        window.scroll(0, 0)
    }, [])
    return (
        <>
            <Helmet>
                <title>Xpan Buzz - 360 Performance Marketing Services for Diverse Business Growth</title>
                <meta name="description" content="We provide marketing services suitable for a diverse range of businesses, from small enterprises to expanding corporations. Explore our experience tailored marketing strategies crafted to meet your specific needs and embark on a professional journey to achieve remarkable business expansion." />
            </Helmet>
            <div className="max-w-[1700px] m-auto">
                <Introduction />
                <OurServices />
                <WhyWeArePromote />
                <WhyChoose />
                <OurExperties />
                <Testimonials />
            </div>
            <GetReady />
        </>
    )
}

export default XpanBuzz