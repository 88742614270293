// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyARomearzId9wCgvNmROXlpqXeANZA_Qnw",
    authDomain: "gbjbuzz-website.firebaseapp.com",
    projectId: "gbjbuzz-website",
    storageBucket: "gbjbuzz-website.appspot.com",
    messagingSenderId: "585633381696",
    appId: "1:585633381696:web:b977f31fe36399f6468ea5",
    measurementId: "G-Z1YJ7C0FLQ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
