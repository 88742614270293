import React, { useEffect } from "react";
import { CyberSec } from "../Components/CyberSecurtyPage/CyberSec/CyberSec";
import { OurExpertise } from "../Components/CyberSecurtyPage/OurExpertise/OurExpertise";
import { Services } from "../Components/CyberSecurtyPage/Services/Services";
import { News } from "../Components/CyberSecurtyPage/News/News";
import { WhyWeAreProtect } from "../Components/CyberSecurtyPage/WhyWeAreProtect/WhyWeAreProtect";
import { GetReady } from "../Components/GetReady/GetReady";
import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet";
ReactGA.initialize(process.env.REACT_APP_GA_KEY);
export const CyberSecurity = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Cyber Security Page" });
    window.scroll(0, 0)
  }, [])
  return (
    <>
      <Helmet>
        <title>CyberX -  Defending Your Digital Frontier with Expert Cybersecurity</title>
        <meta name="description" content="We offer an array of comprehensive cybersecurity solutions and strategic guidance. Entrust your digital world to the protection and resilience of Cyberx Buzz, your partner in digital security excellence." />
      </Helmet>
      <div className="max-w-[1700px] m-auto">
        <CyberSec />
        <WhyWeAreProtect />
        <OurExpertise />
        <Services />
        <News />
      </div>
      <GetReady />
    </>
  );
};
