import React from "react";
import { Route, Routes } from "react-router-dom";
import { CyberSecurity } from "../Pages/CyberSecurity";
import Aboutus from "../Pages/Aboutus";
import Services from "../Pages/Services";
import ContactUs from "../Pages/ContactUs";
import { Blog } from "../Pages/Blog";
import { Career } from "../Pages/Career";
import { BlogForm } from "../Components/BlogForm/BlogForm";
import { LibexService } from "../Pages/LibexService";
import XpanBuzz from "../Pages/XpanBuzz";
import BlockX from "../Pages/BlockX";
import Buzz7i from "../Pages/Buzz7i";
import ErrorPage from "../Components/ErrorPage/ErrorPage";
import ComingSoonProduct from "../Pages/ComingSoonProduct";
import NewLandingPage from "../Pages/NewLandingPage";
import BlogPage from "../Components/SingleBlog/BlogPage";
import BlogDetail from "../Components/BlogDetail/BlogDetail";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import TermCondition from "../Pages/TermCondition";
import CancellationRefundPolicy from "../Pages/CancellationRefundPolicy";


export const AllRoutes = () => {
  return (
    <Routes>
      <Route path={"/"} element={<NewLandingPage />} />
      <Route path={"/services/cyber-security"} element={<CyberSecurity />} />
      <Route path={"/about-us"} element={<Aboutus />} />
      <Route path={"/all-services"} element={<Services />} />
      <Route path={"/contact"} element={<ContactUs />} />
      <Route path={"/blogs"} element={<Blog />} />
      <Route path={"/career"} element={<Career />} />
      <Route path={'/blog-form'} element={<BlogForm />} />
      <Route path={'/services/libex'} element={<LibexService />} />
      <Route path={'/services/xpan'} element={<XpanBuzz />} />
      <Route path={'/services/blockx'} element={<BlockX />} />
      <Route path={'/services/7i'} element={<Buzz7i />} />
      <Route path={'/product'} element={<ComingSoonProduct />} />
      <Route path={'/blog/:id'} element={<BlogPage />} />
      <Route path={'/blog-detail/:id'} element={<BlogDetail/>} />
      <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
      <Route path="/term&conditions" element={<TermCondition/>} />
      <Route path="/cancellaition-refund-policy" element={<CancellationRefundPolicy/>} />

      <Route path={'*'} element={<ErrorPage />} />

    </Routes>
  );
};
