import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import styles from "./NewNavbar.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { RequestButton } from "../RequestButton/RequestButton";
import { ServicesLink } from "../ServicesLink/ServicesLink";
import { GbjLogo } from "../../GbjLogo/GbjLogo";
import { BurgerIcon } from "../../BurgerIcon/BurgerIcon";
import BellButton from "../BellButton/BellButton";
import NotificatonBox from "../../NotificationBox/NotificatonBox";
export const NewNavbar = ({ isOpen, setIsOpen }) => {
  const [isToggle, setToggle] = useState(true);
  const [checked, setChecked] = useState(false)
  const navigate = useNavigate();
  const path = useLocation()

  useEffect(() => {
    if (isToggle) {
      document.body.classList.remove("overflow-y-hidden")
    } else {
      document.body.classList.add("overflow-y-hidden")
    }
  }, [isToggle])

  return (
    <div className="shadow-custom">
      <header className={`max-w-[1700px] m-auto font-['Lato'] ${!isToggle ? "" : "lg:overflow-hidden tab:overflow-hidden sm:overflow-hidden "}  
      ${!isToggle ? "backdrop-blur-sm " : "backdrop-blur-xl"}`}>
        <nav id="NavBar" className={` 
         ${path.pathname === "/contact" ? '' : "mb-1"}`}>
          <div className={`${styles.heading}`} >
            <label
              className={`${styles.logo} flex items-center gap-10 sm:gap-10`}
              htmlFor="check"
            >
              <div className="hidden lg:block tab:block sm:block">
                {isToggle && (<RequestButton setToggle={setToggle} />)}
              </div>
              <div onClick={() => setToggle(!isToggle)}>
                {isToggle ? (
                  <BurgerIcon toggle={isToggle} setToggle={setToggle} setChecked={setChecked} checked={checked} />
                ) : (
                  <AiOutlineClose size={30} className="text-black " />
                )}
              </div>
            </label>

            <div className={styles.gbjLogoDiv} onClick={() => navigate('/')}>
              <GbjLogo setToggle={setToggle} />
            </div>
          </div>
          <div className="hidden w-[77%] xl:flex 2xl:flex gap-16 justify-between">
            <ul className={`${isToggle ? "" : styles.toggle}`}>
              {/* <li >
                <a onClick={() => {
                  setToggle(true)
                  navigate("/")
                }}>Home</a>
              </li> */}
              <li className="mr-9">
                <ServicesLink setToggle={setToggle} />
              </li>
              <li className="mr-9">
                <a onClick={() => {
                  setToggle(true)
                  navigate("/contact")
                }}>Contact</a>
              </li>
              <li className="mr-9">
                <a onClick={() => {
                  setToggle(true)
                  navigate("/about-us")
                }}>About</a>
              </li>
              <li className="mr-9">
                <a onClick={() => {
                  setToggle(true)
                  navigate("/career")
                }}>Career</a>
              </li>
              <li className="mr-9">
                <a onClick={() => {
                  setToggle(true)
                  navigate('/blogs')
                }}>Blog</a>
              </li>

            </ul >
            <div className={`${isToggle ? "" : styles.toggle} flex items-center gap-x-2`}>

              <>
                {!isToggle ? (<RequestButton setToggle={setToggle} />) :
                  (<RequestButton setToggle={setToggle} />)}
              </>
              <div className="lg:hidden sm:hidden tab:hidden">
                {/* <BellButton /> */}
                <NotificatonBox isOpen={isOpen} setIsOpen={setIsOpen} />
              </div>
            </ div>
          </div>
          <ul className={`${isToggle ? "" : styles.toggle} xl:hidden 2xl:hidden`}>
            {/* <li >
              <a onClick={() => {
                setToggle(true)
                setChecked(false)
                navigate("/")
              }}>Home</a>
            </li> */}
            <li >
              <ServicesLink setToggle={setToggle} />
            </li>
            <li >
              <a onClick={() => {
                setToggle(true)
                setChecked(false)
                navigate("/contact")
              }}>Contact</a>
            </li>
            <li >
              <a onClick={() => {
                setToggle(true)
                setChecked(false)
                navigate("/about-us")
              }}>About</a>
            </li>
            <li >
              <a onClick={() => {
                setToggle(true)
                setChecked(false)
                navigate("/career")
              }}>Career</a>
            </li>
            <li >
              <a onClick={() => {
                setToggle(true)
                setChecked(false)
                navigate('/blogs')
              }}>Blog</a>
            </li>
            <li className="">
              {!isToggle ? (<RequestButton setToggle={setToggle} />) :
                (<RequestButton setToggle={setToggle} />)}
            </li>
            <li className="lg:hidden sm:hidden tab:hidden">
              <BellButton />
            </li>
          </ul >
        </nav >
      </header>
    </div>

  );
};
