import React from 'react'
import TechnologyImg1 from './ab.png.png'
import TechnologyImg2 from './2.png.png'
import TechnologyImg3 from './3.png.png'
import TechnologyImg4 from './4.png.png'
import TechnologyImg5 from './5.png.png'
import TechnologyImg6 from './6.png.png'
import TechnologyImg7 from './7.png.png'
import TechnologyImg8 from './8.png.png'
import TechnologyImg9 from './9.png.png'
import TechnologyImg10 from './10.png.png'
import TechnologyImg11 from './11.png.png'
import TechnologyImg12 from './12.png.png'
import TechnologyImg13 from './13.png.png'
import TechnologyImg14 from './14.png.png'
import TechnologyImg15 from './15.png.png'
import style from './TechnologyStack.module.css'

const techStack = [TechnologyImg1, TechnologyImg2, TechnologyImg3, TechnologyImg4, TechnologyImg5, TechnologyImg6, TechnologyImg7, TechnologyImg8, TechnologyImg9, TechnologyImg10, TechnologyImg11, TechnologyImg12, TechnologyImg13, TechnologyImg14, TechnologyImg15]

export const TechnologyStack = () => {

  return (
    <div className='w-10/12 m-auto py-10'>
      <p className='text-center text-lg font-medium mb-8' >Technology Stack</p>
      <div className={style.cardContainer}>
        {
          techStack?.map((el, i) => (
            <div key={i} className='w-32 justify-center flex items-center sm:w-auto'>
              <img className='h-7' src={el} alt={`Technology${i}`} />
            </div>
          ))
        }
      </div>
    </div>
  )
}
