import React from 'react'

import Service1 from '../../../../Assets/Illustration/BlockXService1.svg'
import Service2 from '../../../../Assets/Illustration/BlockXService2.svg'
import Service3 from '../../../../Assets/Illustration/BlockXService.svg'
import { title } from '@uiw/react-md-editor'

const array = [
    {
        img: Service1,
        heading: 'Learning Management System',
        desc: "BlockX ensures transparent evidence of course advancement, accomplishment, grading, ongoing educational credits, and certification, all securely recorded on the blockchain."
    },
    {
        img: Service2,
        heading: 'Decentralized Identity',
        desc: "Factor is a multifunctional identity management platform with GDPR and CCPA compatibility, designed to enhance security, minimise the potential exposure of customer PII, and streamline the authentication process."
    },
    {
        img: Service3,
        heading: 'Anti-Fraud and Compliance',
        desc: "A fintech suite ensures regulatory adherence, maintains data logs, and offers controlled visibility to in-house staff, external third parties, regulatory bodies, and consumers."
    },
]


const AboutService = () => {
    return (
        <div className='' style={{
            background:
                "radial-gradient(at 90% 50%, rgba(255,246,214,0.3), rgba(255,255,255) 80%, rgba(255,255,255) 40%)",
        }}>
            <div className='w-10/12 m-auto tab:w-11/12 sm:w-11/12 py-6'>
                <div className='text-4xl text-center font-bold flex flex-col gap-3 tab:text-3xl sm:text-2xl'>
                    <h2 className='uppercase text-gray-400 '>
                        ABOUT OUR SERVICE
                    </h2>
                    <h3 className=''>
                        We provide various solutions <span className='text-gray-400'>to</span>
                    </h3>
                    <h3 className='text-gray-400'>
                        help you business
                    </h3>
                    <p className='text-base font-normal w-10/12 sm:w-full tab:w-full m-auto  tab:text-sm sm:text-sm'>
                        Discover customized solutions for your business success with Block X Services, leveraging blockchain, secure ledgers, and smart contracts.
                    </p>
                </div>
                <div className='flex flex-wrap justify-evenly mt-16 sm:flex-col'>
                    {
                        array?.map((ele, i) => {
                            return <Card key={i} {...ele} />
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default AboutService


const Card = ({ img, heading, desc }) => {
    return (
        <div className='w-3/12 sm:w-full sm:my-4'>
            <div>
                <img src={img} alt={heading} loading='lazy' />
            </div>
            <div>
                <h4 className='text-xl font-semibold tab:text-lg'>
                    {heading}
                </h4>
                <p className='text-gray-600 tab:text-sm'>
                    {desc}
                </p>
            </div>
        </div>
    )
}