import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import './styles.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import ProductLogo1 from '../../../Assets/Images/LandingPage/hoora 1.png'
import ProductLogo2 from '../../../Assets/Images/LandingPage/GB 1.png'
import ProductLogo3 from '../../../Assets/Images/LandingPage/P1 1 (1).png'
import ProductLogo4 from '../../../Assets/Images/LandingPage/bb 1.png';
import traserImg from '../../../Assets/Teaser.png';

const DiscoverExclusiveDeals = () => {
  const [trigger, setTrigger] = useState(false);

  const corouselNum = [1]
  const projectCompleted = [
    {
      img: ProductLogo1,
      industryType: "Automobile Industry"
    },
    {
      img: ProductLogo2,
      industryType: "Real Estate Industry"
    }, {
      img: ProductLogo3,
      industryType: "Cloud-Kitchen"
    }, {
      img: ProductLogo4,
      industryType: "Food Industry"
    },
  ];

  return (
    <ScrollTrigger
      onEnter={() => setTrigger(true)}
    >
      <div className='bg-[#f7f7f7]'>
        <div className='max-w-[1600px] m-auto'>
          <div className='w-11/12 m-auto pt-12 pb-8 sm:py-8'>
            <div className='grid grid-cols-2 sm:grid-cols-1 tab:grid-cols-1 gap-6 sm:gap-4 lg:gap-4 justify-between'>
              <div className=''>
                <h2 data-aos-duration="1000" data-aos-delay="500" className='text-5xl lg:text-4xl tab:text-4xl sm:text-2xl leading-[3.7rem] font-semibold sm:text-center'>
                  Discover exclusive deals and seize lucrative opportunities.
                </h2>
                {/* <h1 className='text-5xl sm:text-[22px] font-semibold mt-3'> */}
                {/* </h1> */}
              </div>
              <div>
                <h5 data-aos-duration="1000" data-aos-delay="500" className='pl-12 lg:pl-8 sm:pl-0 sm:hidden tab:hidden text-[#8B8B8B] text-lg lg:text-base'>
                  GBJ Buzz’s commitment lies in propelling enterprises forward in the digital landscape. We distinguish ourselves from the market by delivering cutting-edge IT services & consistently staying abreast of industry advancements.
                </h5>
              </div>
            </div>
            <div className='flex gap-8 mt-16 sm:mt-2 tab:mt-8'>
              <div
                // data-aos="zoom-out" 
                data-aos-duration="1000" className='w-[65%] sm:w-full tab:w-full h-[26rem] tab:h-full sm:h-full '>
                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  // navigation={true}
                  modules={[Autoplay, Navigation]}
                  className="mySwiper"
                >
                  {
                    corouselNum?.map((el, i) => (
                      <SwiperSlide key={i}>
                        <div className=' h-[24rem] tab:h-full sm:h-full bg-black rounded-3xl'>
                          <img src={traserImg} alt="" className='rounded-3xl' />
                        </div>
                      </SwiperSlide>
                    ))
                  }
                </Swiper>
              </div>
              <div data-aos-duration="1000" className='sm:hidden tab:hidden w-[33%]  h-[24rem] bg-black px-10 py-12 flex flex-col justify-between items-center text-white rounded-3xl'>
                <div>
                  <h1 className='text-8xl lg:text-[90px] font-bold'>
                    {
                      trigger &&
                      <CountUp start={1} end={920} duration={3} delay={1} />
                    }
                    <span className='text-[#FFC900]'>+</span></h1>
                </div>
                <h4 data-aos-duration="1000" data-aos-delay="100" className='text-3xl lg:text-2xl text-center text-[#B8B8B8] font-semibold'>Project finished till now</h4>

                <div className='flex gap-x-3 justify-center items-center lg:gap-x-1 mb-4'>
                  {
                    projectCompleted?.map((el, i) => (
                      <div
                        key={i}
                        data-aos="fade-right"
                        data-aos-duration="1000"
                        data-aos-delay={50 * (projectCompleted.length + 1) - i}
                        className='relative group w-16 h-16 lg:w-12 lg:h-12 bg-slate-500 rounded-full'>
                        <img src={el.img} alt='product-image' className='group-hover:opacity-50 transition-opacity' />
                        <div className="absolute hidden  w-44 bg-white text-black py-2 px-3 rounded shadow-lg group-hover:block">
                          <p>{el.industryType}</p>
                        </div>
                      </div>
                    ))
                  }

                  {/* <div

                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="800"
                    className='w-16 h-16 lg:w-12 lg:h-12 bg-slate-500 rounded-full'>
                    <img src={ProductLogo} alt='product-image' />

                  </div>
                  <div

                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="600"
                    className='w-16 h-16 lg:w-12 lg:h-12 bg-slate-500 rounded-full'>
                    <img src={ProductLogo} alt='product-image' />

                  </div>
                  <div

                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="400"
                    className='w-16 h-16 lg:w-12 lg:h-12 bg-slate-500 rounded-full'></div> */}
                  <div

                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                    className=' h-16 lg:h-12 lg:ml-0 flex items-center justify-center text-6xl font-bold'><p className='mb-[3px] lg:mb-[1px]'>+</p></div>

                </div>

              </div>
            </div>
            {/* -----------For Small and Tablet screen ------------ */}
            <div className='hidden tab:grid sm:grid tab:grid-cols-2 grid-cols-1 gap-3 tab:gap-6 mt-5 sm:mt-3 '>
              <div className='h-40 tab:h-60 sm:h-36 bg-black pl-5 py-5 tab:p-5 flex items-center gap-2 justify-center text-white rounded-2xl'>
                <div className=''>
                  <h1 className='text-[66px] leading-[3rem] font-bold'>
                    {
                      trigger &&
                      <CountUp start={1} end={920} duration={4} delay={0} />
                    }
                    <span className='text-[#FFC900] ml-[2px]'>+</span></h1>
                  {/* <h4 className=' sm:text-[15px] text-[#B8B8B8] font-semibold sm:mt-[2px]'>Project finished till now</h4> */}
                  <div className='flex self-center gap-1 tab:gap-2 mt-4'>
                    {
                      projectCompleted?.map((el, i) => (
                        <div
                          key={i}
                          data-aos="fade-right"
                          data-aos-duration="1000"
                          data-aos-delay={100 * (projectCompleted.length + 1) - i}
                          className='relative group w-7 h-7 tab:w-10 tab:h-10 bg-slate-500 rounded-full'>
                          <img src={el.img} alt='product-image' className='group-hover:opacity-50 transition-opacity' />
                          <div className="absolute hidden  w-44 bg-white text-black py-2 px-3 rounded shadow-lg group-hover:block ">
                            <p>{el.industryType}</p>
                          </div>
                        </div>
                      ))
                    }

                    {/* <div
                      data-aos="fade-right"
                      data-aos-duration="1300"
                      data-aos-delay="600"
                      className='w-7 h-7 tab:w-10 tab:h-10 bg-slate-500 rounded-full'></div>
                    <div
                      data-aos="fade-right"
                      data-aos-duration="1300"
                      data-aos-delay="400"
                      className='w-7 h-7 tab:w-10 tab:h-10 bg-slate-500 rounded-full'></div>
                    <div
                      data-aos="fade-right"
                      data-aos-duration="1300"
                      data-aos-delay="200"
                      className='w-7 h-7 tab:w-10 tab:h-10 bg-slate-500 rounded-full'></div> */}
                  </div>
                </div>
                <h4
                  data-aos-duration="1000"
                  className='w-32 sm:text-[28px] text-[#B8B8B8] font-semibold sm:mt-[2px]'>Project Till now</h4>

              </div>
              <div>
                <h5
                  data-aos-duration="1000"
                  className=' text-[#8B8B8B] sm:text-xs mt-4 text-base font-semibold text-justify !leading-[18px]'>
                  We are a passionate team of digital marketing enthusiasts dedicated to helping businesses succeed in the digital world. With years of experience and a deep understanding of the ever-evolving online landscape, we stay at the forefront of industry trends and technologies.
                </h5>
              </div>

            </div>

          </div>
        </div>
      </div>
    </ScrollTrigger>
  )
}

export default DiscoverExclusiveDeals;
