import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import WhiteStrip from "../../../Assets/SVG_Images/AboutUs/WhiteStrip.svg";
import styles from "./styles.module.css";

Aos.init();
const Projects = () => {
  const number = {
    projectCount: 96,
    onGoingProjects: 15,
    clientCount: 111,
    servedIndustryCount: 6,
  };

  return (
    <div
      className={`${styles.container} sm:mb-44 mb-80 sm:w-11/12 w-10/12 m-auto relative`}
      id="ProjectInformation">
      <div className="fixed top-0 left-0 h-full w-full z-[-1]">
        <img
          src={WhiteStrip}
          alt="WhiteStrip"
          className="object-cover w-full h-full"
          loading="lazy"
        />
      </div>
      <div className="flex justify-center  gap-4 relative sm:gap-0">
        <div className="w-5/12">
          <div
            className="my-5 w-10/12"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-offset="100">
            <Card
              bg={"#000"}
              number={number.projectCount}
              desc={"Completed Projects"}
              color={"#FFD01F"}
            />
          </div>
          <div
            className="w-10/12"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-offset="100"
            data-aos-delay="100">
            <Card
              bg={"#FFD01F"}
              number={number.clientCount}
              desc={"Happy Clients"}
              color={"#000"}
            />
          </div>
        </div>
        <div className="w-5/12">
          <div
            className="my-5 w-10/12"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-offset="100"
            data-aos-delay="100">
            <Card
              bg={"#FFD01F"}
              number={number.onGoingProjects}
              desc={"OnGoing Projects"}
              color={"#000"}
            />
          </div>
          <div
            className="w-10/12"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-offset="100"
            data-aos-delay="100">
            <Card
              bg={"#000000CC"}
              number={number.servedIndustryCount}
              desc={"Served Industry "}
              color={"#FFD01F"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;

const Card = ({ bg, number, desc, color }) => {
  const [trigger, setTrigger] = useState(false);
  return (
    <ScrollTrigger onEnter={() => setTrigger(true)}>
      <div
        className="w-10/12 m-auto h-80 flex flex-col justify-center items-center rounded-2xl tab:w-52 tab:h-52 sm:w-[7rem] sm:h-[7rem]"
        style={{ background: bg, color: color }}>
        <div>
          <p className="text-[7rem] font-bold text-center tab:text-6xl sm:text-3xl">
            {trigger && (
              <CountUp start={0} end={number} duration={4} delay={0} />
            )}
            <sup>+</sup>{" "}
          </p>
        </div>
        <div>
          <p className="text-center text-2xl font-semibold tab:text-xl sm:text-sm">
            {desc}
          </p>
        </div>
      </div>
    </ScrollTrigger>
  );
};
