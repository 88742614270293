import React from 'react'
import Illustration from '../../Assets/Illustration/NotAvailable.svg'
import { useEffect } from 'react'

const ErrorPage = () => {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <div className='relative flex justify-center items-center h-[96vh] py-10'>
            <div className=''>
                <img src={Illustration} alt="ErrorIllustration" className='w-[40%] m-auto tab:w-96 sm:w-72' loading='lazy' />
            </div>
            <div className='absolute'>
                <p className='text-center relative text-9xl -z-10 font-semibold sm:text-7xl'>
                    404
                </p>
                <p className='text-center font-bold text-4xl sm:text-2xl'>
                    Page Not Available
                </p>
            </div>
        </div>
    )
}

export default ErrorPage