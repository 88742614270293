import React from 'react'
import { Typewriter } from 'react-simple-typewriter'

import GetQuoteButton from '../../GetQuoteButton/GetQuoteButton'
import XpanHeroImg from '../../../../Assets/HeroSection/xpanHero.svg'

const Introduction = () => {
  return (
    <div className='mb-2 shadow-md py-8' style={{
      background:
        "radial-gradient(at 90% 6%, rgba(255,246,214) 20%, rgba(255,255,255) 56%, rgba(255,255,255) 60%)",
    }}>
      <div className='w-10/12 m-auto flex justify-evenly tab:w-11/12 sm:w-11/12  sm:flex-col-reverse '
        data-aos='fade-up'
        data-aos-duration='500'
        data-aos-offset='100'
      >
        <div className='flex flex-col gap-2 text-5xl font-bold tab:text-3xl sm:w-full sm:text-2xl sm:gap-0' id='XpanHeading'>
          <p className=''>
            <Typewriter
              words={['Xpan Buzz', 'Digital Marketing']}
              loop={false}
              cursor
              cursorStyle='_'
              typeSpeed={120}
              deleteSpeed={80}
            />
          </p>
          <p>
            Ignite Your <span className='text-gray-400'> Business</span>
          </p>
          <p>
            Growth with Digital
          </p>
          <p className='text-gray-400'>
            Marketing
          </p>
          <p className='text-lg font-normal my-5 tab:text-base sm:text-sm sm:mt-1 sm:my-0'>
            Reach New Heights with Xpan Buzz's Tailored
            Digital Marketing Services
          </p>
          <GetQuoteButton text={'Get Quote'} />
        </div>
        <div className='w-5/12 sm:w-11/12 sm:mb-5'>
          <img src={XpanHeroImg} alt="XpanHeroImg" loading='lazy' />
        </div>
      </div>
    </div>
  )
}

export default Introduction