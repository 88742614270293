import React from "react";
import supabase from "../../../SupabaseConfig/SupabaseClient";
import { useState } from "react";
import { useEffect } from "react";

export const RecentPost = () => {
  const [supabaseData, setSupabaseData] = useState([])

  const getData = async () => {
    try {
      const { data, error } = await supabase
        .from('BlogData')
        .select('*')
      setSupabaseData(data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getData();
  }, [])
  // console.log(supabaseData[0])

  let recentPostData=[]
  let length = supabaseData.length
  for(let a=length-1; a>=0; a--){
     if(recentPostData.length < 3){
      recentPostData.push(supabaseData[a])
     }   
  }

  return (
    <div className="w-9/12 m-auto mt-6 tab:w-10/12 sm:w-11/12">
      <p className="text-3xl font-bold my-10 mb-10 sm:text-2xl sm:mt-0 sm:mb-8">
        Most Recent Posts
      </p>
      <div className="flex justify-between tab:flex-col sm:flex-col sm:gap-4" >
        {/* <a href={supabaseData[0]?.mediumBlogUrl} target="_blank" rel="noopener noreferrer"> */}
        <a className="w-7/12 tab:w-full sm:w-full flex flex-col gap-2 sm:gap-1" href={recentPostData[0]?.mediumBlogUrl} target="_blank">
          <img className="aspect-[1.5] sm:aspect-[2] object-cover" src={recentPostData[0]?.imageUrl} alt={recentPostData[0]?.title} />
          <p className="text-sm">GROWTH & CULTURE</p>
          <p className="text-lg font-semibold tab:text-base sm:text-sm">
            {recentPostData[0]?.title}
          </p>
          <p className="font-medium sm:hidden">
            {recentPostData[0]?.introduction}
          </p>
        </a>
        {/* </a> */}
        <div className="w-4/12 tab:w-full tab:flex-row tab:mt-10 tab:gap-6 sm:w-full flex flex-col gap-4 ">
          <a className="tab:w-[50%] flex flex-col gap-1" href={recentPostData[1]?.mediumBlogUrl} target="_blank">
            <img className="aspect-[2] object-cover" src={recentPostData[1]?.imageUrl} alt={recentPostData[1]?.title} />
            <p className="text-sm">GROWTH & CULTURE</p>
            <p className="text-lg font-semibold tab:text-base sm:text-sm">
            {recentPostData[1]?.title}
            </p>
          </a>
          <a className="tab:w-[50%] flex flex-col gap-1" href={recentPostData[2]?.mediumBlogUrl} target="_blank">
            <img className="aspect-[2] object-cover" src={recentPostData[2]?.imageUrl} alt={recentPostData[2]?.title} />
            <p className="text-sm">GROWTH & CULTURE</p>
            <p className="text-lg font-semibold tab:text-base sm:text-sm">
            {recentPostData[2]?.title}
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};
