import React from 'react'
import style from "./OurServices.module.css";
import StrategicImg from '../../../../Assets/CardImages/Xpan/strategicPlanning.png';
import SeoImg from '../../../../Assets/CardImages/Xpan/seo.png';
import PpcImg from '../../../../Assets/CardImages/Xpan/ppc.png';
import AppDeveplomentImg from '../../../../Assets/CardImages/Xpan/appDevelopment.png';
import WebsiteDeveplomentImg from '../../../../Assets/CardImages/Xpan/websiteDev.png';
import EmailMarketingImg from '../../../../Assets/CardImages/Xpan/emailMarketing.png';
import ContentMarketingImg from '../../../../Assets/CardImages/Xpan/contentMarketing.png';
import SocialMediaMarketingImg from '../../../../Assets/CardImages/Xpan/socialmediaMarketing.png';
import { CardComponent } from '../../CardComponent/CardComponent';

const cardData = [
    {
        title: "Strategic Planning",
        img: StrategicImg,
        description:
            "We initiate the process by comprehending your distinct business objectives and the specific audience you aim to reach, crafting a tailor-made digital marketing plan that optimises your return on investment.",
    },
    {
        title: "Search Engine Optimization",
        img: SeoImg,
        description:
            "Elevate your online presence and boost your website's search engine rankings using our effective SEO strategies, capturing the attention of a larger audience of potential customers.",
    },
    {
        title: "Pay-Per-Click (PPC) Advertising",
        img: PpcImg,
        description:
            "Instantly connect with your desired audience using precisely targeted PPC campaigns, effectively driving high-quality traffic and elevating conversion rates.",
    },
    {
        title: "App Development:",
        img: AppDeveplomentImg,
        description:
            "Custom mobile applications are created for iOS and Android platforms, aiming to ensure a smooth user experience.",
    },
    {
        title: "Website Design and Development",
        img: WebsiteDeveplomentImg,
        description:
            "We Leave a lasting impression on your website visitors by offering them a visually attractive and user-friendly online platform that seamlessly turns potential leads into loyal customers.",
    },
    {
        title: "Email Marketing",
        img: EmailMarketingImg,
        description:
            "Foster enduring connections with your clientele by delivering personalized and influential email marketing initiatives.",
    },
    {
        title: "Content Marketing",
        img: ContentMarketingImg,
        description:
            "Captivate your audience by delivering valuable content that not only solidifies your industry expertise but also fuels natural expansion.",
    },
    {
        title: "Social Media Marketing",
        img: SocialMediaMarketingImg,
        description:
            "Captivate your audience by delivering valuable content that not only solidifies your industry expertise but also fuels natural expansion.",
    },
];

export const OurServices = () => {

    return (
        <div className=' shadow-md' style={{
            background: 'radial-gradient(at -5% 50%, rgba(255, 209, 35, 0.20), rgba(255, 255,255)'
        }}>
            <div className='w-10/12 sm:w-11/12 m-auto py-8 sm:py-4'>
                <div className="w-48 m-auto mb-4 border-[3px] border-yellow-400 border-solid sm:w-24 sm:border-2 sm:my-6"></div>
                <div className='text-center mb-8 sm:mb-6'>
                    <p className='text-[44px]  tab:text-3xl sm:text-3xl font-bold text-gray-400 leading-[70px] lg:leading-[50px]'><span className='text-black'>Our</span> Services</p>
                    <p className='w-7/12 m-auto sm:w-full'>
                        Fuel the expansion of your business within the digital domain by leveraging the game-changing digital marketing solutions offered by Xpan Buzz.

                    </p>
                </div>
                <div className={style.cardMainContainer}>
                    {cardData?.map((el, i) => (
                        <CardComponent
                            title={el.title}
                            img={el.img}
                            desc={el.description}
                            learnMore={el.learnMore}
                            key={i}
                            bg="#fff5cc"
                            bor="none"
                            descColor="rgba(0, 0, 0, 0.7)"
                            id={i}
                        />
                    ))}
                </div>
            </div>
        </div>

    )
}
