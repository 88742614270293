import React, { useRef } from "react";
import linebg2 from "../../../Assets/linedbg2.png";
import { BsArrowUpRight } from "react-icons/bs";
import cyberSecurityImg from "../../../Assets/HeroSection/cybersecurity.png";
import SafeBoxImg from "../../../Assets/CardImages/CyberSecurity/Safe-Box.png";
import FrameImg from "../../../Assets/CardImages/CyberSecurity/Frame.png";
import ComputerImg from "../../../Assets/CardImages/CyberSecurity/Computer.png";
import { Card } from "../../UniversalComponent/Card/Card";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

const cardData = [
  {
    title: "Detecting Incident",
    img: SafeBoxImg,
    description:
      "Rapidly address security breaches with our state-of-the-art detection solution, guaranteeing a proactive safeguarding approach",
  },
  {
    title: "Preventing Incident",
    img: FrameImg,
    description:
      "Employing preventive strategies, such as cybersecurity practices and robust security measures, is essential to shield your business from potential threats and vulnerabilities.",
  },
  {
    title: "Responding Incident",
    img: ComputerImg,
    description:
      "Ensuring a prompt and skilled reaction to security incidents, with the goal of minimizing harm and efficiently reinstating normalcy.",
  },
];

export const CyberSec = () => {
  const element = useRef();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update windowWidth state
    const handleResize = () => setWindowWidth(window.innerWidth);

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className=" shadow-md"
        style={{
          // height: "580px",
          background:
            "radial-gradient(at 90% 40%, rgb(255,246,214) 30%, rgba(251,251,251) 80%, rgba(251,251,251) 40%)",
        }}

      >
        <div
          className="w-10/12 lg:w-[85%] m-auto pt-6 flex gap-10 tab:gap-0 sm:w-11/12 justify-between sm:gap-0 "
          // style={{ height: "580px" }}
          data-aos='fade-up'
          data-aos-duration='500'
          data-aos-offset='100'
        >
          <div className="mt-10 mb-20 flex flex-col justify-between  sm:mt-0 sm:mb-6"

          >
            <div className="text-5xl font-bold text-left leading-[70px] lg:text-4xl lg:leading-[64px] tab:text-3xl tab:leading-[50px] sm:leading-[auto] sm:text-xl sm:font-bold">
              <p>We have the Capability</p>
              <p> To Partner with You in</p>
              <p>
                Handling &<span className="text-gray-400"> Mitigating your</span>
              </p>
              <p className="text-gray-400">Cybersecurity Risk</p>
            </div>
            {/* button component */}

            <div
              className="flex shrink-0 justify-start mt-4 relative sm:mt-2 w-[230px] h-[50px] sm:w-[133px] sm:h-[30px] bg-[#FFc900] rounded-lg  shadow-2xl overflow-hidden"
              onMouseEnter={() => {
                element.current.classList.add("-translate-x-0");
                element.current.classList.remove("-translate-x-full");
              }}
              onMouseLeave={() => {
                element.current.classList.remove("-translate-x-0");
                element.current.classList.add("-translate-x-full");
              }}>
              <span
                ref={element}
                className={` absolute w-[15rem] sm:w-[9rem] h-full -translate-x-full  transition-all duration-300 ease-in`}
                style={{
                  background: `url(${linebg2})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#fff",
                }}></span>

              <Link
                to={"/contact"}
                className={` flex justify-around items-center  sm:text-sm rounded-lg font-semibold w-full h-full text-2xl  sm:px-4 sm:py-1 py-3 px-5 shadow-custom2 bg-transparent z-10 `}>
                Get Quote <BsArrowUpRight />
              </Link>
            </div>
            {/* button component ended */}
          </div>
          <div className="tab:w-[30%] h-80 mt-6 flex items-center sm:h-36 sm:-mt-4 ">
            <img
              className="w-[330px] lg:w-[276px] tab:w-full m-auto sm:w-28"
              // style={{ width: "300px" }}
              src={cyberSecurityImg}
              alt="cybersecurityimage"
              loading='lazy'
            />
          </div>
        </div>
      </div>
      <div className="w-48 m-auto mt-16 mb-10 border-[3px] border-yellow-400 border-solid sm:w-24 sm:border-2 sm:mt-8 sm:mb-8"></div>
      <div className="w-10/12 m-auto flex gap-10 justify-between tab:w-10/12 tab:flex-wrap tab:justify-evenly  sm:w-11/12 sm:flex-wrap sm:justify-center sm:gap-4">
        {cardData?.map((el, i) => (
          <Card
            title={el.title}
            img={el.img}
            desc={el.description}
            key={i}
            bg="linear-gradient(to right, #FFFBC4 55%, #FFFDEF"
            bor="1px solid #DBDBDBA8"
            shadow="none"
            titleSize={windowWidth < 599 ? "12px" : "23px"}
            descSize={windowWidth < 599 ? "11px" : "17px"}
            descColor="rgba(0, 0, 0, 0.6)"
            imgWid={windowWidth < 600 ? "55px" : "110px"}
          />
        ))}
      </div>
    </>
  );
};
